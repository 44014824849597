import React from 'react'
import "./HistoryOrderBarSceletor.css"

const HistoryOrderBarSceletor = () => {


    return (
        <>
            <div className="component-history-order-bar-sceletor">
                <div className="component-history-order-bar-inner">
                </div>
            </div >
        </>
    )
}

export default HistoryOrderBarSceletor
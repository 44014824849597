import React, { useContext, useState, useEffect } from 'react'
import supabase, { auth } from '../data/supabase'
import authEmailsTranslations from '../data/authEmailsTranslations'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState(true)

    async function signup(email, password, captchaToken, language) {

        const langMetaData = authEmailsTranslations[language]

        try {
            const { data, error } = await auth.signUp({
                email: email,
                password: password,
                options: {
                    data: {
                        language: language,
                        langMetaData,
                    },
                    captchaToken: captchaToken
                },
            })

            if (error) {
                throw error;
            }
        } catch (error) {
            return error;
        }


    }

    async function login(email, password, captchaToken) {
        try {
            const { data, error } = await auth.signInWithPassword({
                email: email,
                password: password,
                options: { captchaToken },
            })

            if (error) throw error;
        } catch (error) {
            return error;
        }

    }

    async function logout() {
        try {
            const { error } = await auth.signOut()
            if (error) {
                throw error;
            }
        } catch (error) {
            return error
        }
    }

    useEffect(() => {
        supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_IN') {
                supabase.auth.getUser().then(async ({ data: { user } }) => {
                    try {
                        const { data, error } = await supabase
                            .from('users')
                            .select()
                            .eq('id', user?.id)
                            .single()

                        if (error) throw error;
                        if (data) {
                            setCurrentUser(data)
                        }
                    } catch (error) {
                    }

                })
            } else if (event === 'SIGNED_OUT') {
                setCurrentUser(false)
            }
            else if (event === 'INITIAL_SESSION') {
                if (session && session?.user) {
                    setCurrentUser(session?.user)
                }
                else {
                    setCurrentUser()
                }

                // może tutaj zrobić loading state ze spinnerem i sprawdzać, czy jest użytkownik?
            }
            else if (event === 'USER_UPDATED') {
            }

            else if (event === 'TOKEN_REFRESHED') {
            }
            else if (event === 'PASSWORD_RECOVERY') {
            }
        })
    }, [])


    //////////////////////

    const value = {
        currentUser,
        signup,
        login,
        logout,
    }

    return (

        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )

}
import allProductsEN from "../Purchase/allProductsEN";

export const allProductsENBoxes = [
    {
        id: allProductsEN[0].id,
        name: allProductsEN[0].name,
        price: allProductsEN[0].price,
        description: allProductsEN[0].description,
        short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>Recommended</span> for amateurs</>,
        promo_price: allProductsEN[0].promo_price,
        duration: 60,
        time_description: '60 minutes',
        mode: 'remote',
        benefits_list: allProductsEN[0].benefits_list,
        support_days: allProductsEN[0].support_days,
        service_name: allProductsEN[0].service_name,
        users_number: allProductsEN[0].users_number,
        status: allProductsEN[0].status,
        type: allProductsEN[0].type,
        advantages_array: [
            "Gaming Mode Application 1.7",
            "More frames per second (FPS)",
            "Reduced Input Lag",
            "Maximum smooth screen",
            "Limited FPS drops",
            "Lower ping",
            "Programs run faster",
            "A refreshed and more efficient Windows system",
            "Technical support for 30 days",
           
        ]
    },
    {
        id: allProductsEN[1].id,
        name: allProductsEN[1].name,
        price: allProductsEN[1].price,
        description: allProductsEN[1].description,
        short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>Recommended</span> for professionals</>,
        promo_price: allProductsEN[1].promo_price,
        duration: 180,
        time_description: '180 minutes',
        mode: 'remote',
        benefits_list: allProductsEN[1].benefits_list,
        support_days: allProductsEN[1].support_days,
        service_name: allProductsEN[1].service_name,
        users_number: allProductsEN[1].users_number,
        status: allProductsEN[1].status,
        type: allProductsEN[1].type,
        advantages_array: [
            "Gaming Mode Application 1.7",
            "Maximum frames per second (FPS)",
            "Zero Input Lag",
            "1:1 responsiveness",
            "Stuttering eliminated",
            "Minimum ping",
            "Diagnostics and improvement of CPU, GPU and RAM performance",
            "Ultra-fast program operation",
            "A refreshed and more efficient Windows system",
            "Technical support for 60 days"
        ]
    },
    {
        id: allProductsEN[2].id,
        name: allProductsEN[2].name,
        price: allProductsEN[2].price,
        description: allProductsEN[2].description,
        short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>Recommended</span> for enthusiasts</>,
        duration: 420,
        time_description: '7 hours',
        mode: 'coming to the client - ONLY IN PL',
        promo_price: allProductsEN[2].promo_price,
        button: allProductsEN[2].button,
        benefits_list: allProductsEN[2].benefits_list,
        support_days: allProductsEN[2].support_days,
        service_name: allProductsEN[2].service_name,
        users_number: allProductsEN[2].users_number,
        status: allProductsEN[2].status,
        type: allProductsEN[2].type,
        advantages_array: [
            <>Everything included in <span style={{ color: 'rgb(0,224,255)' }}>PRO</span></>,
            "Highest efficiency",
            "Cleaning your computer from the inside",
            "Replacing thermal pastes (CPU and GPU)",
            "Overclocking CPU, GPU and RAM",
            "Diagnostics and extended stress tests",
            "Preparing a station for streaming at the client's request",
            "Replacement of parts at the customer's request",
            "Assembling a computer at the customer's request",
            "Technical support for 90 days"
        ]
    }
]

export default allProductsENBoxes;
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import "./MobileMenu.css";
import Hamburger from 'hamburger-react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import LogoutButton from '../LogoutButton/LogoutButton';
import { useAuth } from '../../context/AuthContext';
import SettingsButton from '../SettingsButton/SettingsButton';
import homeIcon from '../../assets/img/icons/home_48dp.svg'
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import instagramIcon from '../../assets/img/socials/instagram-icon.png'
import { navReviewsClickedEvent } from '../../data/pixel-events/navReviewsClickedEvent';
import { hashData } from '../../utils/hashData';
import { instagramButtonClickedEvent } from '../../data/pixel-events/instagramButtonClickedEvent';

const MobileMenu = () => {

    const { t, i18n } = useTranslation('translation')
    const { currentUser } = useAuth()
    const [isOpen, setOpen] = useState(true)

    function toggleOpen() {
        setOpen(!isOpen)
    }

    return (
        <>
            <div className="menu-wrapper">
                <Menu customBurgerIcon={<Hamburger toggled={isOpen} toggle={setOpen} />} onStateChange={toggleOpen} burgerButtonClassName={'bm-burger-button-custom'}>
                    <Link className="menu-item-white" to="/zakup">{t("purchase-buttons.buy-now")}</Link>
                    {currentUser ? <Link className="menu-item" to="/konto">{t("panel").toUpperCase()}</Link> : <Link className="menu-item" to="/logowanie">{t("components.mobile-menu.login")}</Link>}
                    <Link className="menu-item" to="/gaming-mode">{t("gaming-mode")}</Link>
                    <Link className="menu-item" to="/uslugi">{t("package-description").toUpperCase()}</Link>
                    <Link className="menu-item" to="/proserwis">{t("pro-serwis")}</Link>
                    <HashLink className="menu-item" onClick={() => navReviewsClickedEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id))} to='/#section-reviews'>{t("reviews").toUpperCase()}</HashLink>
                    <Link className="menu-item" to="/kontakt">{t("contact").toUpperCase()}</Link>
                    <div className='social-info-container'>
                        <p>{t("components.social-panel.text-upper")}</p>
                        <p style={{ color: "#e0447c" }}>{t("components.social-panel.text-bottom")}</p>
                        <a href='https://instagram.com/gainfps' onClick={() => { instagramButtonClickedEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id)) }} style={{ width: "36px" }}><img src={instagramIcon} style={{ maxWidth: "100%" }} alt='Instagram' /></a>
                    </div>
                    <div className="menu-buttons-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <LanguageSelector />
                        <Link className="menu-item" to="/"><img src={homeIcon} alt='home' /></Link>
                        {currentUser ? <Link className='menu-item-settings' to='/konto/ustawienia'><SettingsButton /></Link> : ''}
                        {currentUser ? <Link className="menu-item-logout"><LogoutButton /></Link> : ''}
                    </div>

                </Menu >
            </div>
        </>
    )
}

export default MobileMenu;
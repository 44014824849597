import { sendEventToServer } from "../pixelEventsAPI";

// Kliknięcie w przycisk Opinie (może dodać do lejka?)
export const navReviewsClickedEvent = (language, hashed_email, hashed_user_id) => {

    const event_id = `navReviewsClicked${Date.now()}`;

    // Meta (FB, IG)
    if (typeof window.fbq !== 'undefined') {
        window.fbq('trackCustom', 'NavReviewsClicked', {
            content_ids: 'nav-reviews-link',
            content_name: 'Navigation Reviews Link',
            language: language
        }, {
            eventID: event_id
        });
    }

    // TikTok
    if (typeof window.ttq !== 'undefined') {

        window.ttq.identify({
            "email": hashed_email, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
            "external_id": hashed_user_id // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
        });

        window.ttq.track('ClickButton', {
            contents: [
                {
                    content_id: 'nav-reviews-link',
                    content_name: 'Navigation Reviews Link'
                }
            ],
            locale: language
        }, { event_id: event_id });
    }

    // Wyślij zdarzenie do serwera
    const eventData = {
        context: {
            user: {
                external_id: hashed_user_id,
                email: hashed_email,
                client_user_agent: navigator.userAgent
            },
            page: {
                url: window.location.href
            }
        },
        properties: {
            contents: [
                {
                    content_id: 'nav-reviews-link',
                    content_name: 'Navigation Reviews Link'
                }
            ],
            event_id: event_id,
            locale: language
        }
    };

    sendEventToServer('https://gainfps.com/api/m-event', 'NavReviewsClicked', eventData);
    sendEventToServer('https://gainfps.com/api/t-event', 'ClickButton', eventData);
    // sendEventToServer('http://localhost:4747/api/m-event', 'NavReviewsClicked', eventData);
    // sendEventToServer('http://localhost:4747/api/t-event', 'ClickButton', eventData);
}
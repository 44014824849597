import React from 'react'
import ReactDOM from 'react-dom';
import Button from '../Button/Button';
import tooltipIcon from '../../assets/img/icons/tooltip_36dp.svg'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import "./ShopProduct.css"
import { useDatabasePageContext } from '../../context/DatabasePageContext';
import { useTranslation } from 'react-i18next';
import { addToCartEvent } from '../../data/pixel-events/addToCartEvent';
import { useAuth } from '../../context/AuthContext';
import { hashData } from '../../utils/hashData';

const ShopProduct = ({ id, name, price, promo_price, description, bestseller, readMore = true, nohover, users_number, button, support_days, service_name, type, benefits_list, duration, status = 'active', special }) => {

    const rootContainer = document.getElementById('root');
    const { currency, exchangeRates, currencyDisplay } = useDatabasePageContext()
    const { t, i18n } = useTranslation('translation')
    const finalPrice = promo_price ? (promo_price !== price ? promo_price : price) : price
    const { currentUser } = useAuth()
    const convertToTargetCurrency = (amountInPLN, targetCurrency) => {
        if (!exchangeRates || !exchangeRates[targetCurrency]) {
            // Błąd: nieznany kurs waluty lub kursy walut nie zostały jeszcze pobrane
            return 0;
        }
        return Math.round(amountInPLN / exchangeRates[targetCurrency]);
    }

    return (
        <>
            <div className={`component-shop-product ${type} ${bestseller ? 'bestseller' : ''} ${nohover ? 'nohover' : ''} ${status === 'disabled' ? 'disabled' : ''} ${special ? "special" : ""}`} id={`product-id-${id}`}>
                <div className="component-shop-product-inner">
                    <div className="product-name-container">
                        <h4>
                            {name}
                        </h4>
                    </div>
                    <div className="product-price-container">
                        {
                            promo_price ?
                                <>
                                    <div className="product-promo-price-wrapper">
                                        <h3>{`${convertToTargetCurrency(promo_price, currency)} ${currencyDisplay}`}</h3>
                                    </div>
                                    {
                                        price !== promo_price &&
                                        <div className="product-old-price-wrapper">
                                            <p>{`${convertToTargetCurrency(price, currency)} ${currencyDisplay}`}</p>
                                        </div>
                                    }

                                </>
                                :
                                <h3>{`${convertToTargetCurrency(price, currency)} ${currencyDisplay}`}</h3>
                        }

                    </div>
                    <div className="product-desc-container">
                        <p>
                            <>{description}</>
                        </p>
                    </div>
                    <div className="product-button-buy-container">
                        {button === 'contact' ? <Button className={`try purchase`} local href={`/kontakt#contact-form`} id={id} price={price} name={name} promo_price={promo_price} users_number={users_number} from={'moje-uslugi'}>
                            {t("action-buttons.contact-us")}
                        </Button>
                            :
                            <Button className={`try purchase`} href={`/zakup/personalizacja`} onClick={ () => addToCartEvent(i18n.language, id, service_name, finalPrice, currency, hashData(currentUser?.email), hashData(currentUser?.id))} id={id} price={promo_price ? promo_price : price} name={name} promo_price={promo_price} users_number={users_number} support_days={support_days} type={type} service_name={service_name} duration={duration} from={'moje-uslugi'}>
                                {t("purchase-buttons.order-now")}
                            </Button>
                        }
                    </div>
                    <div className="product-benefits-list-container">
                        <h3>{t("components.shop-product.contains")}</h3>
                        <ul>
                            {
                                benefits_list?.map((benefit, index) =>
                                    <li key={index}>
                                        <span>{benefit.main_text}</span>
                                        <OverlayTrigger trigger={['hover', 'focus']} placement='top' container={() => ReactDOM.findDOMNode(rootContainer)} overlay={<Popover style={{ backgroundColor: 'rgb(0, 224, 255, 0.2)', borderRadius: '7px' }} className='popover' id={`popover-benefit-${id}`}>
                                            <Popover.Header as="h3">{benefit.popover.title}</Popover.Header>
                                            <Popover.Body>
                                                {benefit.popover.text}
                                            </Popover.Body>
                                        </Popover>}><img src={tooltipIcon} width={'20px'} alt='tooltip' />
                                        </OverlayTrigger>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                    <div className="product-button-read-container">
                        {
                            readMore &&
                            <Button className='try black' local href={`/uslugi/#${`box-${name}`}`}>
                                {t("action-buttons.read-more")}
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}

export default ShopProduct;
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from "../../context/AuthContext"
import Login from '../../pages/Login/Login'
import Profile from '../../pages/Profile/Profile'


const PrivateRoute = () => {

    const { currentUser } = useAuth()

    return currentUser ? <><Profile/></> : <><Login /><Navigate to="/logowanie" /></>

}

export default PrivateRoute


import React from 'react'
import Footer from '../../components/Footer/Footer'
import TopNavigation from '../../components/TopNavigation/TopNavigation'
import "./PasswordReset.css"
import { Outlet } from 'react-router-dom'
import { NewOrderContextProvider } from '../../context/NewOrderContext'
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel'

const PasswordReset = () => {
    return (
        <>
            <TopNavigation />
            <main className="page password-reset">
                <SocialMediaPanel />
                <NewOrderContextProvider>
                    <Outlet />
                </NewOrderContextProvider>
            </main>
            <Footer />
        </>
    )
}

export default PasswordReset
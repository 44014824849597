import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Button from '../../../Button/Button';
import '../../../Button/Button.css'
import './StepTwo.css'
import SimpleContainer from '../../../SimpleContainer/SimpleContainer';
import StatuteParagraph from '../../../StatuteParagraph/StatuteParagraph';
import rufusInstructionPL from '../../../../assets/img/instructions/rufus-instruction-pl.webp'
import bootInstructionPL from '../../../../assets/img/instructions/boot-priority-instruction-pl.webp'
import anydeskInstallInstructionPL from '../../../../assets/img/instructions/anydesk-instruction-pl.webp'
import anydeskPwInstructionPL from '../../../../assets/img/instructions/anydesk-pw-instruction-pl.webp'
import partitionsInstructionPL from '../../../../assets/img/instructions/partitions-instruction-en.webp'
import rufusInstructionEN from '../../../../assets/img/instructions/rufus-instruction-en.webp'
import bootInstructionEN from '../../../../assets/img/instructions/boot-priority-instruction-en.webp'
import partitionsInstructionEN from '../../../../assets/img/instructions/partitions-instruction-en.webp'
import anydeskInstallInstructionEN from '../../../../assets/img/instructions/anydesk-instruction-en.webp'
import anydeskPwInstructionEN from '../../../../assets/img/instructions/anydesk-pw-instruction-en.webp'
import { Trans, useTranslation } from 'react-i18next';


const StepTwo = ({ setNextDisabled, setPrepareClientOption }) => {

  const [showBase, setShowBase] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false)
  const { t } = useTranslation('translation')

  const instructions = {
    rufusInstructionPL,
    bootInstructionPL,
    anydeskInstallInstructionPL,
    anydeskPwInstructionPL,
    partitionsInstructionPL,
    rufusInstructionEN,
    bootInstructionEN,
    anydeskInstallInstructionEN,
    anydeskPwInstructionEN,
    partitionsInstructionEN
  }
  const handleClickBase = () => {
    setShowBase(!showBase)
  }

  const handleClickAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }

  function handleChange(name) {
    setPrepareClientOption(name)
    setNextDisabled(false)
  }

  useEffect(() => {
    setNextDisabled(true)
  }, [])

  return (
    <div className="component-step step-two">
      <div className="component-step-inner">
        <p><Trans i18nKey={"page-profile.view-prepare.prepare-step-two.options-desc"}>Wybierz jedną z dwóch opcji przygotowania systemu przed optymalizacją – pełną lub podstawową.
          W celu osiągnięcia najlepszych wyników <span style={{ textDecoration: 'underline' }}>zalecany jest bardziej rozbudowany wariant.</span> Instrukcja zostanie wysłana na Twój e-mail.</Trans></p>
        <div className="options-brief-container">
          <SimpleContainer>
            <div className="option-wrapper advanced">
              <p style={{ color: 'rgb(0, 224, 255)' }}>{t("page-profile.view-prepare.prepare-step-two.full-label")}</p>
              <ul>
                <li>{t("page-profile.view-prepare.prepare-step-two.full-require-one")}</li>
                <li>{t("page-profile.view-prepare.prepare-step-two.full-require-two")}</li>
                <li style={{ marginBottom: '0.5rem' }}>{t("page-profile.view-prepare.prepare-step-two.full-require-three")}</li>
                <Button onClick={handleClickAdvanced} className='button try black small' >{t("page-profile.view-prepare.prepare-step-two.show-instruction-button")}</Button>
              </ul>
            </div>
          </SimpleContainer>
          <SimpleContainer>
            <div className="option-wrapper base">
              <p style={{ color: 'rgb(150 151 151)' }}>{t("page-profile.view-prepare.prepare-step-two.base-label")}</p>
              <ul>
                <li style={{ marginBottom: '0.5rem' }}>{t("page-profile.view-prepare.prepare-step-two.base-require-one")}</li>
                <li style={{ marginBottom: '0.5rem' }}>{t("page-profile.view-prepare.prepare-step-two.base-require-two")}</li>
                <Button onClick={handleClickBase} className='button try gray small'>{t("page-profile.view-prepare.prepare-step-two.show-instruction-button")}</Button>
              </ul>
            </div>
          </SimpleContainer>
        </div>
        <div className="choose-option-container">
          <p style={{ color: 'white', marginBottom: '1', display: 'inline-block' }}>{t("page-profile.view-prepare.prepare-step-two.chosing-prepare-label")}</p>
          <div className="options-container">
            <div className="option-wrapper">
              <input type="radio" name="select-prepare" id="option-1" onChange={() => {
                handleChange('rozszerzone')
              }} />
              <label htmlFor="option-1" className="option option-1 preferable">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-two.advanced")}</span>
              </label>
            </div>
            <div className="option-wrapper">
              <input type="radio" name="select-prepare" id="option-2" onChange={() => {
                handleChange('podstawowe')
              }} />
              <label htmlFor="option-2" className="option option-2">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-two.base")}</span>
              </label>
            </div>
          </div>
        </div>

        <Modal show={showAdvanced} onHide={handleClickAdvanced}>
          <Modal.Header closeButton>
            <Modal.Title>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.full-system-preparation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.preparation-time')}<br /><br /></strong>
            <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.preparation-description'></Trans>
            <br /><br />
            <strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.contents')}</strong><br />
            <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.preparation-steps'></Trans>
            <StatuteParagraph className={'modal-window'}>
              <ol>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.preparation-before-format')}</h1>
                  <ol>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.save-important-files')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.backup-projects')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.make-program-list')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.disconnect-devices')}</li>
                    <li><strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.prepare-pendrive')}</strong></li>
                  </ol>
                </li>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.installation-preparation')}</h1>
                  <ol>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.choose-windows-version'></Trans><br /><br />
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.custom-version-description'></Trans>
                    </li>
                    <p><strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.choose-option')}</strong></p>
                    <ol>
                      <p><strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.custom-windows-10-pro')}</strong></p>
                      <li>
                        <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.download-system-image'>
                          <a href='https://pixeldrain.com/api/file/kJW8WnL9?download' target="_blank" rel="noopener noreferrer"></a>
                          <a href='https://pixeldrain.com/api/file/eACz8edS?download' target="_blank" rel="noopener noreferrer"></a>
                        </Trans><br />
                      </li>
                      <li>
                        <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.download-and-install-7zip'>
                          <a href='https://www.7-zip.org/a/7z2301-x64.exe' target="_blank" rel="noopener noreferrer"></a>
                        </Trans>
                      </li>
                      <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.extract-system-image')}<br /> <br/><strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.password-for-extraction')}</strong><br/><strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.password-for-extraction11')}</strong></li>
                      <li>
                        <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.download-rufus'>
                          <a href='https://github.com/pbatard/rufus/releases/download/v4.4/rufus-4.4.exe' target="_blank" rel="noopener noreferrer"></a>
                        </Trans>
                      </li>
                      <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.use-rufus-for-usb')}<br /><br />{t('page-profile.view-prepare.prepare-step-two.modal-advanced.rufus-illustrative')}<br /><br />
                        <img src={instructions[t('page-profile.view-prepare.prepare-step-two.modal-advanced.rufus-instruction')]} alt="rufus instruction" />
                      </li>

                      <li><Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.wait-until-booting-completes'></Trans></li>

                      <p><strong>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.original-windows-10-11')}</strong></p>
                      <li>
                        <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.download-booting-tool'>
                          <a href='https://go.microsoft.com/fwlink/?LinkId=691209' target="_blank" rel="noopener noreferrer">WIN10 - narzędzie do bootowania pendrive</a>
                          lub
                          <a href='https://go.microsoft.com/fwlink/?linkid=2156295' target="_blank" rel="noopener noreferrer">WIN11 - narzędzie do bootowania pendrive</a>
                        </Trans>
                      </li>
                      <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.insert-usb-8gb')}</li>
                      <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.accept-agreement-proceed')}</li>
                      <li><Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.choose-media-creation-option'></Trans></li>
                      <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.customize-language-edition')}</li>
                      <li><Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.choose-usb-flash-drive'></Trans></li>
                      <li><Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.wait-for-process-completion'></Trans></li>
                    </ol>
                  </ol>
                </li>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.system-installation')}</h1>
                  <ol>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.restart-enter-bios' components={[<i />, <i />, <i />, <i />]}></Trans>
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.set-boot-priority' components={[<i />, <i />]}></Trans><br /><br />
                      <img src={instructions[t('page-profile.view-prepare.prepare-step-two.modal-advanced.boot-priority-instruction')]} alt="boot priority instruction" />
                    </li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.save-exit-bios')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.installation-screen')}</li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.follow-installation-steps' components={[<i />]}></Trans>
                    </li>
                    <li><Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.choose-installation-type' components={[<strong />]}></Trans></li>
                    <li><Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.delete-partitions' /></li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.keep-partitions-if-needed')}</li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.unallocated-space' components={[<i />]}></Trans><br /><br />
                      <img src={instructions[t('page-profile.view-prepare.prepare-step-two.modal-advanced.partitions-instruction')]} alt="partitions instruction" />
                    </li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.select-fastest-disk')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.installation-process')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.choose-language')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.skip-microsoft')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.decline-data-collection')}</li>
                    <li><Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.post-installation-window' /></li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.proceed-to-updates' components={[<strong />]}></Trans>
                    </li>
                  </ol>
                </li>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.full-updates')}</h1>
                  <ol>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.navigate-to-updates' components={[<i><a href='ms-settings:windowsupdate?activationSource=SMC-IA-4027667' target="_blank" rel="noopener noreferrer"></a></i>]}></Trans>
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.ensure-latest-version' components={[<a href='ms-settings:windowsupdate?activationSource=SMC-IA-4027667' target="_blank" rel="noopener noreferrer"></a>]}></Trans>
                    </li>
                  </ol>
                </li>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.anydesk-installation')}</h1>
                  <ol>
                    <p>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.anydesk-description' components={[<strong />]}></Trans>
                    </p>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.download-anydesk' components={[<a href='https://anydesk.com/pl/downloads/windows?dv=win_exe' target="_blank" rel="noopener noreferrer"></a>]}></Trans>
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.install-anydesk-instructions' components={[<i />]}></Trans><br /><br />
                      <img src={instructions[t('page-profile.view-prepare.prepare-step-two.modal-advanced.anydesk-install-instruction')]} alt='anydesk installation' />
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.set-anydesk-password' components={[<strong />]}></Trans><br /><br />
                      <img src={instructions[t('page-profile.view-prepare.prepare-step-two.modal-advanced.anydesk-pw-instruction')]} alt='anydesk setting up the password' />
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.service-start-requirement' components={[<strong />]}></Trans><br /><br />
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.sample-access-data' />
                    </li>
                  </ol>
                </li>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.game-and-software-installation')}</h1>
                  <ol>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.download-install-games')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-advanced.use-ie-for-new-browser')}</li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-advanced.use-computer-as-you-like' components={[<strong />]}></Trans>
                    </li>
                  </ol>
                </li>
              </ol>
            </StatuteParagraph>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClickAdvanced}>
              {t("nav-buttons.close")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showBase} onHide={handleClickBase}>
          <Modal.Header closeButton>
            <Modal.Title>{t('page-profile.view-prepare.prepare-step-two.modal-base.basic-system-preparation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>{t('page-profile.view-prepare.prepare-step-two.modal-base.preparation-time-short')}<br /><br /></strong>
            <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.preparation-intro' components={[<strong />]}></Trans>
            <br /><br />
            <strong>{t('page-profile.view-prepare.prepare-step-two.modal-base.contents-short')}</strong><br />
            {t('page-profile.view-prepare.prepare-step-two.modal-base.step-full-updates')}<br />
            {t('page-profile.view-prepare.prepare-step-two.modal-base.step-anydesk-installation')}<br />
            {t('page-profile.view-prepare.prepare-step-two.modal-base.step-remove-unneeded')}<br /><br />
            <StatuteParagraph className={'modal-window'}>
              <ol>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-base.full-updates')}</h1>
                  <ol>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.navigate-to-updates' components={[<a href='ms-settings:windowsupdate?activationSource=SMC-IA-4027667' target="_blank" rel="noopener noreferrer"></a>]}></Trans>
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.ensure-latest-version' components={[<a href='ms-settings:windowsupdate?activationSource=SMC-IA-4027667' target="_blank" rel="noopener noreferrer"></a>]}></Trans>
                    </li>
                  </ol>
                </li>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-base.anydesk-installation')}</h1>
                  <ol>
                    <p>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.anydesk-description' components={[<strong />]}></Trans>
                    </p>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.download-anydesk' components={[<a href='https://anydesk.com/pl/downloads/windows?dv=win_exe' target="_blank" rel="noopener noreferrer"></a>]}></Trans>
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.install-anydesk-instructions' components={[<i />]}></Trans><br /><br />
                      <img src={instructions[t('page-profile.view-prepare.prepare-step-two.modal-base.anydesk-install-instruction')]} alt='anydesk installation' />
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.set-anydesk-password' components={[<strong />]}></Trans><br /><br />
                      <img src={instructions[t('page-profile.view-prepare.prepare-step-two.modal-base.anydesk-pw-instruction')]} alt='anydesk setting up the password' />
                    </li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.service-start-requirement' components={[<strong />]}></Trans><br /><br />
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.sample-access-data' />
                    </li>
                  </ol>
                </li>
                <li className='headline'>
                  <h1>{t('page-profile.view-prepare.prepare-step-two.modal-base.remove-unneeded-games')}</h1>
                  <ol>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.navigate-to-add-remove-programs' components={[<a href='ms-settings:appsfeatures' target="_blank" rel="noopener noreferrer"></a>]}></Trans>
                    </li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-base.consider-removing-unused')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-base.delete-unused-games')}</li>
                    <li>{t('page-profile.view-prepare.prepare-step-two.modal-base.remember-you-can-reinstall')}</li>
                    <li>
                      <Trans i18nKey='page-profile.view-prepare.prepare-step-two.modal-base.use-computer-freely' components={[<strong />]}></Trans>
                    </li>
                  </ol>
                </li>
              </ol>
            </StatuteParagraph>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClickBase}>
              {t("nav-buttons.close")}
            </Button>
          </Modal.Footer>
        </Modal>

      </div >
    </div >
  )
}

export default StepTwo
import React from 'react';
import SectionHero from './Sections/SectionHero/SectionHero'
import SectionWhatWeImprove from './Sections/SectionWhatWeImprove/SectionWhatWeImprove'
import SectionStarsAndTeams from './Sections/SectionStarsAndTeams/SectionStarsAndTeams'
import SectionReviews from './Sections/SectionReviews/SectionReviews'
import SectionUsePotential from './Sections/SectionUsePotential/SectionUsePotential';
import Footer from '../../components/Footer/Footer'
import "./Homepage.css"
import SectionTryNow from './Sections/SectionTryNow/SectionTryNow';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel';

const Homepage = () => {

    return (
        <>
            <TopNavigation className='absolute' />
            <main className='page homepage'>
                <SocialMediaPanel />
                <SectionHero />
                <SectionUsePotential />
                <SectionWhatWeImprove />
                <SectionStarsAndTeams />
                <SectionReviews />
                <SectionTryNow />
            </main>
            <Footer />
        </>
    )

}

export default Homepage;
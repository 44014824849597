import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import SimpleContainer from '../../SimpleContainer/SimpleContainer'

const ViewHelp = () => {
  return (
    <>
      <div className="view-help">
        <SimpleContainer>
          <ContactForm />
        </SimpleContainer>
      </div>
    </>
  )
}

export default ViewHelp
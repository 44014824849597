import React, { useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './SectionFullProfile.css'
import FragmentTopBanner from '../../../../components/components-profile/FragmentTopBanner/FragmentTopBanner'
import FragmentSideNav from '../../../../components/components-profile/FragmentSideNav/FragmentSideNav'
import FragmentMain from '../../../../components/components-profile/FragmentMain/FragmentMain'
import { useDatabaseUserContext } from '../../../../context/DatabaseUserContext'
import { useAuth } from '../../../../context/AuthContext';
import { useTranslation } from 'react-i18next'
import { panelVisitedEvent } from '../../../../data/pixel-events/panelVisitedEvent'
import { hashData } from '../../../../utils/hashData'

const SectionFullProfile = () => {

    const { useReadUserData } = useDatabaseUserContext()
    const { currentUser } = useAuth()
    const { i18n } = useTranslation('translation')
    const hasMounted = useRef(false)

    useEffect(() => {
        if (!hasMounted.current) {
            panelVisitedEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id))
            hasMounted.current = true
        }
    }, [])

    useReadUserData(currentUser?.id)

    return (
        <>
            <section id="section-full-profile" className='section-full-profile'>
                <Container>
                    <Row >
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <div className="profile-container">
                                <div className="profile-wrapper">
                                    <div className="top-banner-container">
                                        <FragmentTopBanner />
                                    </div>
                                    <div className="profile-main-container">
                                        <div className="profile-side-block">
                                            <div className="side-nav-wrapper">
                                                <FragmentSideNav />
                                            </div>
                                        </div>
                                        <div className="profile-main-block">
                                            <FragmentMain fragmentBar={false} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SectionFullProfile
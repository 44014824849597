export const allProductsPL = [

    {
        id: 1,
        name: "STANDARD",
        price: "199",
        description: <>Podstawowy pakiet dla amatorów</>,
        promo_price: null,
        bestseller: false,
        type: 'normal',
        users_number: 1,
        support_days: 30,
        service_name: 'STANDARD',
        duration: 60,
        status: 'active',
        special: false,
        benefits_list: [
            {
                main_text: 'PRO SERWIS',
                popover: {
                    title: 'PRO SERWIS',
                    text: <><strong>Zwiększa FPS, minimalizuje opóźnienia sprzętowe, eliminuje przycinanie obrazu oraz redukuje PING.</strong><br /><br />PRO SERWIS to pełna optymalizacja systemu Windows, która znacząco zwiększa wydajność gier, programów oraz całego systemu. Zawiera optymalizację ustawień: systemowych, graficznych, internetowych, zasilania, gier, audio, klawiatury, myszy oraz oprogramowania, a także aktualizacje wszystkich sterowników. </>
                }
            },
            {
                main_text: 'Podstawowe ustawienia BIOS',
                popover: {
                    title: 'BIOS',
                    text: <><strong>BIOS (Basic Input/Output System) </strong>- wbudowane oprogramowanie znajdujące się na płycie głównej komputera. BIOS odgrywa znaczącą rolę przy uruchamianiu komponentów komputera, diagnostyce i obsłudze błędów oraz umożliwia zaawansowaną konfigurację podzespołów. <br /><br />Dzisiaj BIOS jest często zastępowany przez UEFI, bardziej nowoczesny i rozbudowany system, jednak potocznie nadal nazywamy te oprogramowanie BIOSEM.</>
                }
            },
            {
                main_text: '30 dni opieki technicznej',
                popover: {
                    title: 'Opieka techniczna',
                    text: <>W przypadku pytań lub problemów technicznych po przeprowadzonej usłudze, nasz specjalista będzie do dyspozycji przez wskazany okres. <br /><br /><strong>Chętnie pomożemy i doradzimy</strong>, a jeśli będzie taka potrzeba, połączymy się z Tobą ponownie!</>
                }
            },
        ]

    },

    {
        id: 2,
        name: "PRO",
        price: "370",
        description: <>Kompleksowy zestaw dla profesjonalistów</>,
        promo_price: null,
        bestseller: true,
        type: 'normal',
        users_number: 1,
        support_days: 60,
        service_name: 'PRO',
        duration: 180,
        status: 'active',
        special: false,
        benefits_list: [
            {
                main_text: 'PRO SERWIS',
                popover: {
                    title: 'PRO SERWIS',
                    text: <><strong>Zwiększa FPS, minimalizuje opóźnienia sprzętowe, eliminuje przycinanie obrazu oraz redukuje PING.</strong><br /><br />PRO SERWIS to pełna optymalizacja systemu Windows, która znacząco zwiększa wydajność gier, programów oraz całego systemu. Zawiera optymalizację ustawień: systemowych, graficznych, internetowych, zasilania, gier, audio, klawiatury, myszy oraz oprogramowania. </>
                }
            },
            {
                main_text: 'Zaawansowane ustawienia BIOS',
                popover: {
                    title: 'BIOS',
                    text: <><strong>BIOS (Basic Input/Output System) </strong>- wbudowane oprogramowanie znajdujące się na płycie głównej komputera. BIOS odgrywa znaczącą rolę przy uruchamianiu komponentów komputera, diagnostyce i obsłudze błędów oraz umożliwia zaawansowaną konfigurację podzespołów. <br /><br />Dzisiaj BIOS jest często zastępowany przez UEFI, bardziej nowoczesny i rozbudowany system, jednak potocznie nadal nazywamy te oprogramowanie BIOSEM.</>
                }
            },
            {
                main_text: 'Overclocking',
                popover: {
                    title: 'Overclocking',
                    text: <><strong>Overclocking </strong> - zwiększanie wydajności podzespołów komputera, takich jak karta graficzna, pamięć RAM czy procesor, poprzez podnoszenie częstotliwości zegarów taktujących i dostosywanie innych ustawień. <br /><br />To zaawansowany proces, wymagający dużej wiedzy i cierpliwości. <strong>Specjaliści GainFPS unikają i odradzają przeprowadzania nadmiernego overclockingu, jednocześnie oferując bezpieczne i rozsądne „podkręcanie” podzespołów</strong>, które w połączeniu z darmową opieką techniczną gwarantuje długotrwałą <strong>wydajność i stabilność.</strong> </>
                }
            },
            {
                main_text: 'Ustawienie pamięci RAM',
                popover: {
                    title: 'Praca pamięci RAM',
                    text: <>Pamięć RAM jest niezwykle ważnym komponentem każdego komputera. Współpracuje ściśle z procesorem, przechowując i przekazując dane potrzebne do wykonywania operacji.<br /><br /><strong>Dobrze skonfigurowana pamięć RAM pracuje znacznie szybciej</strong> niż ta wyjęta prosto z pudełka, co przekłada się na <strong>mniejsze opóźnienia</strong> i <strong>większą liczbę FPS w grach</strong> oraz <strong>wydajniejsze działanie programów</strong>. </>
                }
            },
            {
                main_text: 'Optymalizacja procesów',
                popover: {
                    title: 'Działanie procesów w systemie',
                    text: <>Procesami można nazwać wystąpienia programów komputerowych oraz wszelkich aplikacji, które otwierasz i włączasz na swoim komputerze. W jednym momencie na systemie może być uruchomionych nawet kilkaset procesów. System operacyjny zarządza nimi w sposób ustrukturyzowany, przydzielając im odpowiednie zasoby. <br /><br /><strong>Skuteczna priorytetyzacja zadań i trafne rozdzielenie zasobów pamięci RAM i procesora jest kluczowe do uzyskania płynnego obrazu i maksymalnej wydajności.</strong></>
                }
            },
            {
                main_text: '60 dni opieki technicznej',
                popover: {
                    title: 'Opieka techniczna',
                    text: <>W przypadku pytań lub problemów technicznych po przeprowadzonej usłudze, nasz specjalista będzie do dyspozycji przez wskazany okres. <br /><br /><strong>Chętnie pomożemy i doradzimy</strong>, a jeśli będzie taka potrzeba, połączymy się z Tobą ponownie!</>
                }
            },
        ]
    },

    {
        id: 3,
        name: "PREMIUM",
        price: "699",
        description: <>Specjalistyczna usługa <strong>stacjonarna</strong> dla entuzjastów</>,
        promo_price: null,
        bestseller: false,
        type: 'normal',
        users_number: 1,
        button: 'contact',
        support_days: 90,
        service_name: 'PREMIUM',
        duration: 420,
        status: 'active',
        special: false,
        benefits_list: [
            {
                main_text: 'PRO SERWIS',
                popover: {
                    title: 'PRO SERWIS',
                    text: <><strong>Zwiększa FPS, minimalizuje opóźnienia sprzętowe, eliminuje przycinanie obrazu oraz redukuje PING.</strong><br /><br />PRO SERWIS to pełna optymalizacja systemu Windows, która znacząco zwiększa wydajność gier, programów oraz całego systemu. Zawiera optymalizację ustawień: systemowych, graficznych, internetowych, zasilania, gier, audio, klawiatury, myszy oraz oprogramowania. </>
                }
            },
            {
                main_text: 'Zaawansowane ustawienia BIOS',
                popover: {
                    title: 'BIOS',
                    text: <><strong>BIOS (Basic Input/Output System) </strong>- wbudowane oprogramowanie znajdujące się na płycie głównej komputera. BIOS odgrywa znaczącą rolę przy uruchamianiu komponentów komputera, diagnostyce i obsłudze błędów oraz umożliwia zaawansowaną konfigurację podzespołów. <br /><br />Dzisiaj BIOS jest często zastępowany przez UEFI, bardziej nowoczesny i rozbudowany system, jednak potocznie nadal nazywamy te oprogramowanie BIOSEM.</>
                }
            },
            {
                main_text: 'Precyzyjny overclocking',
                popover: {
                    title: 'Overclocking',
                    text: <><strong>Overclocking </strong> - zwiększanie wydajności podzespołów komputera, takich jak karta graficzna, pamięć RAM czy procesor, poprzez podnoszenie częstotliwości zegarów taktujących i dostosywanie innych ustawień. <br /><br />To zaawansowany proces, wymagający dużej wiedzy i cierpliwości. <strong>Specjaliści GainFPS unikają i odradzają przeprowadzania nadmiernego overclockingu, jednocześnie oferując bezpieczne i rozsądne „podkręcanie” podzespołów</strong>, które w połączeniu z darmową opieką techniczną gwarantuje długotrwałą <strong>wydajność i stabilność.</strong> </>
                }
            },
            {
                main_text: 'Manualne ustawienie wszystkich timingów pamięci RAM',
                popover: {
                    title: 'Praca pamięci RAM',
                    text: <>Pamięć RAM jest niezwykle ważnym komponentem każdego komputera. Współpracuje ściśle z procesorem, przechowując i przekazując dane potrzebne do wykonywania operacji.<br /><br /><strong>Dobrze skonfigurowana pamięć RAM pracuje znacznie szybciej</strong> niż ta wyjęta prosto z pudełka, co przekłada się na <strong>mniejsze opóźnienia</strong> i <strong>większą liczbę FPS w grach</strong> oraz <strong>wydajniejsze działanie programów</strong>. </>
                }
            },
            {
                main_text: 'Zaawansowana optymalizacja procesów i działania sterowników',
                popover: {
                    title: 'Działanie procesów w systemie',
                    text: <>Procesami można nazwać wystąpienia programów komputerowych oraz wszelkich aplikacji, które otwierasz i włączasz na swoim komputerze. W jednym momencie na systemie może być uruchomionych nawet kilkaset procesów. System operacyjny zarządza nimi w sposób ustrukturyzowany, przydzielając im odpowiednie zasoby. <br /><br /><strong>Skuteczna priorytetyzacja zadań i trafne rozdzielenie zasobów pamięci RAM i procesora jest kluczowe do uzyskania płynnego obrazu i maksymalnej wydajności.</strong></>
                }
            },
            {
                main_text: 'Czyszczenie komputera od wewnątrz',
                popover: {
                    title: 'Czyszczenie i pielęgnacja',
                    text: <>Czyszczenie komputera od wewnątrz jest ważne nie tylko dla utrzymania optymalnej wydajności, ale także dla długoterminowego zdrowia i trwałości sprzętu. Do czyszczenia i pielęgnacji sprzętu wykorzystujemy specjalne narzędzia, pozwalające na dokładne pozbycie się kurzu z nawet najtrudniej dostępnych miejsc, takich jak radiatory i wentylatory.</>
                }
            },
            {
                main_text: 'Wymiana past termalnych',
                popover: {
                    title: 'Pasta termoprzewodząca',
                    text: <>Pasta termoprzewodząca (termalna), to substancja stosowana między powierzchnią procesora (CPU) lub karty graficznej (GPU) a chłodzeniem. <br /><br />Głównym celem pasty termalnej jest poprawa przewodzenia ciepła i efektywniejsze chłodzenie urządzeń, co przekłada się na dłuższą żywotność podzespołów i lepszą wydajność.</>
                }
            },
            {
                main_text: 'Diagnostyka i wydłużone testy obciążeniowe',
                popover: {
                    title: 'Diagnostyka i testy',
                    text: <>Sprawdzamy Twój sprzęt pod kątem potencjonalnych usterek i niesprawnego działania komponentów.</>
                }
            },
            {
                main_text: 'Możliwość personalizacji pakietu',
                popover: {
                    title: 'Spełnimy Twoją prośbę',
                    text: <>Rozpoczynasz karierę streamera i chcesz ustawić OBS? Może wymieniasz podzespoły lub dopiero co kupujesz komputer w osobnych częściach i potrzebujesz pomocy? <br /><br /><strong>Z przyjemnością Ci pomożemy, tylko daj nam znać!</strong></>
                }
            },
            {
                main_text: '90 dni opieki technicznej',
                popover: {
                    title: 'Opieka techniczna',
                    text: <>W przypadku pytań lub problemów technicznych po przeprowadzonej usłudze, nasz specjalista będzie do dyspozycji przez wskazany okres. <br /><br /><strong>Chętnie pomożemy i doradzimy</strong>, a jeśli będzie taka potrzeba, połączymy się z Tobą ponownie!</>
                }
            },
        ]
    },

];



export default allProductsPL;
async function sendMail(author, sender, receiver, subject, full_name, phone, message, template, service_name, client_details_email, booked_date, booked_time,
    user_email, order_number, package_name, price, booked_service_number, client_details_name, client_details_surname, client_details_phone, service_status, currency, timezone, language) {
    try {
        const response =
            // await fetch('http://localhost:4747/api/send_mail', {
            await fetch('https://gainfps.com/api/send_mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(author, sender, receiver, subject, full_name, phone, message, template, service_name, client_details_email, booked_date, booked_time,
                    user_email, order_number, package_name, price, booked_service_number, client_details_name, client_details_surname, client_details_phone, service_status, currency, timezone, language)
            })

        if (!response.ok) {
            throw new Error('Wystąpił błąd podczas wysyłania wiadomości e-mail.');
        }

        const data = await response.json();

        return { data: data, error: null };

    } catch (error) {
        return { data: null, error: error }
    }
}

export default sendMail
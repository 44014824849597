import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Text from "../../../../components/Text/Text"
import './SectionThemes.css'
import { useTranslation, Trans } from "react-i18next"

const SectionThemes = () => {

    const { t } = useTranslation('translation')
    return (
        <>
            <section id="section-themes" className="section-themes">
                <Container>
                    <Row>
                        <Col xl={2} lg={2} md={1} sm={0} />
                        <Col xl={8} lg={8} md={10} sm={12}>

                            <Text
                                className={'center small'}
                                id={'genesis'}
                                h5={t("page-proserwis.section-themes.header")}
                                p={
                                    <>
                                        <Trans i18nKey="page-proserwis.section-themes.genesis-description" />
                                        <br /><br />
                                        <span style={{ fontStyle: "Italic", fontWeight: "bold" }}>{t("page-proserwis.section-themes.author-signature")}</span>
                                        <br />
                                        <span style={{ fontStyle: "Italic", fontWeight: "bold", fontSize: '1rem' }}>{t("page-proserwis.section-themes.company-signature")}</span>
                                    </>
                                }
                            />

                            <Text
                                className={'center small'}
                                id={'methods'}
                                h5={<>{t("page-proserwis.section-themes.who-and-why-header")}</>}
                                p={
                                    <>
                                        <Trans i18nKey="page-proserwis.section-themes.who-and-why-description" />
                                    </>
                                }
                            />
                            <Text
                                className={'center small'}
                                id={'security'}
                                h5={<>{t("page-proserwis.section-themes.security-header")}</>}
                                p={
                                    <>
                                        <Trans i18nKey="page-proserwis.section-themes.security-description" />
                                    </>
                                }
                            />
                        </Col>
                        <Col xl={2} lg={2} md={1} sm={0} />
                    </Row>
                </Container>
            </section>
        </>
    )

}

export default SectionThemes
import React, { useState, useEffect } from 'react'
import './AdminViewsHolder.css'
import AdminViewFunctions from '../AdminViewFunctions/AdminViewFunctions'
import AdminViewSvcStatus from '../AdminViewSvcStatus/AdminViewSvcStatus'
import AdminViewSvcAdd from '../AdminViewSvcAdd/AdminViewSvcAdd'
import { useNavigate } from 'react-router-dom'
import { useDatabaseUserContext } from '../../../../../context/DatabaseUserContext'
import AdminViewMailInvite from '../AdminViewMailInvite/AdminViewMailInvite'
import AdminViewMailMsg from '../AdminViewMailMsg/AdminViewMailMsg'

const AdminViewsHolder = () => {
    const [currentView, setCurrentView] = useState()
    let viewComponent;

    const { userData } = useDatabaseUserContext()
    const navigate = useNavigate();

    useEffect(() => {
        if (userData?.role !== 'administrator') {
            navigate('/')
        }
    }, [userData])

    switch (currentView) {
        case 'functions':
            viewComponent = <AdminViewFunctions setCurrentView={setCurrentView} />
            break;
        case 'svc-add':
            viewComponent = <AdminViewSvcAdd setCurrentView={setCurrentView} />
            break;
        case 'svc-status-change':
            viewComponent = <AdminViewSvcStatus setCurrentView={setCurrentView} />
            break;
        case 'mail-msg-send':
            viewComponent = <AdminViewMailMsg setCurrentView={setCurrentView} />
            break;
        case 'mail-invite':
            viewComponent = <AdminViewMailInvite setCurrentView={setCurrentView} />
            break;
        default:
            viewComponent = <AdminViewFunctions setCurrentView={setCurrentView} />
            break;
    }

    return (
        <>
            {
                userData?.role === 'administrator' &&
                <div className="admin-views-holder-container">
                    <div className="admin-views-holder-wrapper">
                        {viewComponent}
                    </div>
                </div>
            }
        </>
    )
}

export default AdminViewsHolder
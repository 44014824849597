import React, { useState, useEffect } from 'react';
import './Payment.css';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import Footer from '../../components/Footer/Footer';
import DatabaseUserProvider from '../../context/DatabaseUserContext';
import supabase from '../../data/supabase';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment';
import 'moment/locale/pl'
import OrderStep from '../Purchase/Sections/SectionNewOrder/new-order-steps/OrderStep/OrderStep';
import errorIcon from '../../assets/img/icons/error_48dp.svg'
import doneIcon from '../../assets/img/icons/done_48dp.svg'
import Button from '../../components/Button/Button';
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from 'react-i18next'
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel';
import { useDatabasePageContext } from '../../context/DatabasePageContext';
import { packagePurchasedEvent } from '../../data/pixel-events/packagePurchasedEvent';
import { hashData } from '../../utils/hashData';
import { useAuth } from '../../context/AuthContext';

const Payment = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const variant = searchParams.get('variant');
    const redirectStatus = searchParams.get('redirect_status');
    const paymentIntentId = searchParams.get('payment_intent');
    const paymentClientSecret = searchParams.get('payment_intent_client_secret');
    const [orderId, setOrderId] = useState('')
    const [productName, setProductName] = useState('')
    const [productPrice, setProductPrice] = useState('')
    const [serviceClient1, setServiceClient1] = useState('')
    const [serviceClient2, setServiceClient2] = useState('')
    const [currency, setCurrency] = useState('PLN')
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation('translation')
    const { timezone } = useDatabasePageContext()
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    const currencySymbols = {
        GBP: '£',
        USD: '$',
        EUR: '€',
        PLN: 'zł'
    };

    // wysylamy zapytanie na backend, przesylajac payment_intent
    // backend sprawdza nam, czy payment intent jest zaplacony
    // jesli jest zaplacony, to zwraca dane z metadata:
    // orderId, productName, productPrice, serviceClient1, serviceClient2

    useEffect(() => {
        setLoading(true)
        if (paymentIntentId && paymentClientSecret) {
            // fetch("http://localhost:4747/api/retrieve-payment-data",
                fetch("https://gainfps.com/api/retrieve-payment-data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ paymentId: paymentIntentId }),
                }).then(async (res) => {

                    try {
                        const { data, error } = await supabase
                            .from('transactions')
                            .select('*')
                            .eq('payment_intent', paymentIntentId)

                        if (error) { throw error }
                        else {
                            // jeśli jest to pierwsza transakcja z danym payment_intent, wykonaj kroki
                            if (data.length === 0 || data.length === 1) {
                                const { status, paymentIntentMetadata } = await res.json();
                                if ((status === 'success' && paymentIntentMetadata) || (status === 'failure' && paymentIntentMetadata)) {
                                    const storedEvents = JSON.parse(localStorage.getItem('purchasedEvents')) || {};
                                    // platnosc pomyslna, update interfejsu
                                    if (!storedEvents[paymentIntentMetadata.order_id]) {
                                        packagePurchasedEvent(
                                            paymentIntentMetadata?.product_id,
                                            paymentIntentMetadata?.product_name,
                                            paymentIntentMetadata?.product_price,
                                            paymentIntentMetadata?.currency,
                                            i18n.language,
                                            hashData(paymentIntentMetadata?.user_email),
                                            hashData(paymentIntentMetadata?.user_id)
                                        );
                                        storedEvents[paymentIntentMetadata.order_id] = true;
                                        localStorage.setItem('purchasedEvents', JSON.stringify(storedEvents));
                                    }

                                    setOrderId(paymentIntentMetadata?.order_id)
                                    setProductName(paymentIntentMetadata?.product_name)
                                    setProductPrice(paymentIntentMetadata?.product_price)
                                    setServiceClient1(paymentIntentMetadata?.client_one)
                                    setServiceClient2(paymentIntentMetadata?.client_two)
                                    setCurrency(paymentIntentMetadata?.currency)
                                }
                            } else {
                                throw new Error("Transaction already exists!")
                            }
                        }
                    } catch (error) {
                    }

                }).catch((error) => {
                }).finally(() => { setLoading(false) })
        } else {
            const storedEvents = JSON.parse(localStorage.getItem('purchasedEvents')) || {};
            if ((variant === 'success' || redirectStatus === 'succeeded') && !storedEvents[searchParams.get('id')]) {
                storedEvents[searchParams.get('id')] = true;
                localStorage.setItem('purchasedEvents', JSON.stringify(storedEvents));

                async function fetchData() {
                    // You can await here
                    try {
                        const { data, error } = await supabase
                            .from('orders')
                            .select(`package_id,
                            transactions(currency)`)
                            .eq('id', searchParams.get('id'))
                            .single()

                        if (error) throw error
                        if (data) {
                            packagePurchasedEvent(
                                `${data.package_id}`,
                                searchParams.get('name'),
                                searchParams.get('price'),
                                data.transactions[0].currency,
                                i18n.language,
                                hashData(currentUser?.email),
                                hashData(currentUser?.id)
                            );
                            setCurrency(data.transactions[0].currency)
                        }

                    } catch (error) {

                    }
                }
                fetchData();
            }
            setOrderId(searchParams.get('id'));
            setProductName(searchParams.get('name'));
            setProductPrice(searchParams.get('price'));
            setServiceClient1(searchParams.get('client'));
            setServiceClient2(searchParams.get('client_two'));
            setLoading(false)
        }
    }, [paymentIntentId]);

    return (
        <>
            <TopNavigation />
            <main className="page payment">
                <SocialMediaPanel />
                <DatabaseUserProvider>
                    <section className="section-payment-summary">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12}>
                                    <OrderStep className={loading ? `payment-summary center` : 'payment-summary'}>
                                        {
                                            loading ? <div className="spinner-container">
                                                <Spinner animation="border" className='loading-spinner' />
                                            </div>
                                                :
                                                (variant === 'success' || redirectStatus === 'succeeded') ? <>
                                                    <div className="payment-success-container">
                                                        <h1>{t("page-payment.section-payment-summary.order-success")}</h1>
                                                        <h3>{t("page-payment.section-payment-summary.services-added")}</h3>
                                                        <p>{t("page-payment.section-payment-summary.date")} <span style={{ color: 'rgb(0,224,255)' }}>{moment.tz(timezone).format('DD.MM.YYYY, HH:mm')}</span></p>
                                                        <p>{t("page-payment.section-payment-summary.order-id")} <span style={{ color: 'rgb(0,224,255)' }}>{orderId ? `#${orderId}` : '-'}</span></p>
                                                        <p>{t("page-payment.section-payment-summary.package")} <span style={{ color: 'rgb(0,224,255)' }}>{productName ? productName : '-'}</span></p>
                                                        <p>{t("page-payment.section-payment-summary.price")} <span style={{ color: 'rgb(0,224,255)' }}>{productPrice ? productPrice : '-'} {currencySymbols[currency]} <img src={doneIcon} alt='error icon'></img></span></p>
                                                        <p>
                                                            {serviceClient2 && serviceClient2 !== 'null' && serviceClient2 !== 'undefined' ? (
                                                                <>
                                                                    {t("page-payment.section-payment-summary.users")} <span style={{ color: 'rgb(0,224,255)' }}>{serviceClient1}</span>,{' '}
                                                                    <span style={{ color: 'rgb(0,224,255)' }}>{serviceClient2}</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {t("page-payment.section-payment-summary.user")} <span style={{ color: 'rgb(0,224,255)' }}>{serviceClient1 ? serviceClient1 : 'brak'}</span>
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <Button className='try medium' onClick={() => {
                                                        navigate('/konto/')
                                                    }}>{t("page-payment.section-payment-summary.user-panel")}</Button>

                                                </> :
                                                    <>
                                                        <div className="payment-failure-container">
                                                            <h1>{t("page-payment.section-payment-summary.order-failure")}</h1>
                                                            <h3>{t("page-payment.section-payment-summary.order-try-again")}</h3>
                                                            <p>{t("page-payment.section-payment-summary.date")} <span style={{ color: '#ea868f' }}>{moment.tz(timezone).format('DD.MM.YYYY, HH:mm')}</span></p>
                                                            <p>{t("page-payment.section-payment-summary.order-id")} <span style={{ color: '#ea868f' }}>#{orderId}</span></p>
                                                            <p>{t("page-payment.section-payment-summary.package")} <span style={{ color: '#ea868f' }}>{productName}</span></p>
                                                            <p>{t("page-payment.section-payment-summary.price")} <span style={{ color: '#ea868f' }}>{productPrice} {currencySymbols[currency]} <img src={errorIcon} alt='error icon'></img></span></p>
                                                            <p>
                                                                {serviceClient2 && serviceClient2 !== 'null' && serviceClient2 !== 'undefined' ? (
                                                                    <>
                                                                        {t("page-payment.section-payment-summary.users")} <span style={{ color: '#ea868f' }}>{serviceClient1}</span>,{' '}
                                                                        <span style={{ color: '#ea868f' }}>{serviceClient2}</span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {t("page-payment.section-payment-summary.user")} <span style={{ color: '#ea868f' }}>{serviceClient1}</span>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                        <Button className='try medium' onClick={() => {
                                                            navigate('/zakup')
                                                        }}>{t("page-payment.section-payment-summary.try-again-button")}</Button>
                                                    </>
                                        }
                                    </OrderStep>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                </DatabaseUserProvider>
            </main>
            <Footer />
        </>
    )

}

export default Payment
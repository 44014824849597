import React, { useState, useEffect } from 'react'
import Button from '../Button/Button';
import './CheckoutForm.css'
import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/pl';
import { useTranslation } from 'react-i18next';
import { paymentStartedEvent } from '../../data/pixel-events/paymentStartedEvent';
import { useAuth } from '../../context/AuthContext';
import { hashData } from '../../utils/hashData';

const CheckoutForm = ({ orderDetails, handleStateChange }) => {
    const { t, i18n } = useTranslation('translation')
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const {currentUser} = useAuth()

    useEffect(() => {

        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );


        if (!clientSecret) {
            return;
        }
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        });

    }, [stripe]);


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Zapobiega domyślnej akcji Enter
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setIsProcessing(false)
            return;
        }

        setIsProcessing(true);
        paymentStartedEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id))
        const stripeRes = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                // return_url: "http://localhost:3000/zakup/finalizacja-zamowienia/",
                return_url: "https://gainfps.com/zakup/finalizacja-zamowienia/",
            },
            redirect: "if_required",
        })

        // tu mam informacje z platnosci
        if (stripeRes.paymentIntent?.status === "succeeded") {
            //PLATNOSC POMYSLNA

            // przekierowanie na stronę z podsumowaniem
            const variant = 'success'
            navigate(`/zakup/finalizacja-zamowienia/?variant=${encodeURIComponent(variant)}&id=${encodeURIComponent(orderDetails.orderId)}&name=${encodeURIComponent(orderDetails.productName)}
            &price=${encodeURIComponent(orderDetails?.discountCode?.promo_price ?? orderDetails.productPrice)}&client=${encodeURIComponent(orderDetails.serviceClient?.email_user1)}&client_two=${encodeURIComponent(orderDetails.serviceClient?.email_user2)}`)

        } else {
            const variant = 'failure'
            navigate(`/zakup/finalizacja-zamowienia/?variant=${encodeURIComponent(variant)}&id=${encodeURIComponent(orderDetails.orderId)}&name=${encodeURIComponent(orderDetails.productName)}
            &price=${encodeURIComponent(orderDetails.productPrice)}&client=${encodeURIComponent(orderDetails.serviceClient?.email_user1)}&client_two=${encodeURIComponent(orderDetails.serviceClient?.email_user2)}`)
        }

        setIsProcessing(false);
    };

    return (
        <div className="component-checkout-form" id='stage-checkout'>
            <div className="component-checkout-form-inner">
                <form id="payment-form" className="w-full" onSubmit={handleSubmit} onKeyDown={handleKeyDown} noValidate>
                    <PaymentElement id="payment-element" options={{
                        layout: {
                            type: 'tabs',
                            defaultCollapsed: false,
                        }
                    }} />
                    <div className="switcher-and-button-container">
                        <div className="currency-switcher">
                            <label htmlFor="currency">{t("components.checkout-form.currency")} </label>
                            <select id="currency" value={orderDetails?.currency || "PLN"} onChange={(e) =>
                                e.target.value &&
                                handleStateChange('currency', e.target.value)}>
                                <option value="PLN">ZŁ</option>
                                <option value="EUR">€</option>
                                <option value="USD">$</option>
                                <option value="GBP">£</option>
                            </select>   
                        </div>
                        <Button type={'submit'} id={'checkout-submit-button'} className='try medium' disabled={isProcessing}>{isProcessing ? t("nav-buttons.processing") : t("nav-buttons.next")}</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CheckoutForm;
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Button from '../../../../components/Button/Button'
import Text from '../../../../components/Text/Text'
import { useTranslation } from 'react-i18next'

const SectionContent = () => {

    const { t } = useTranslation('translation')
    return (
        <section className='section-services-content'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Text
                            h1={t("page-services.section-services-content.header")}
                            className={"subpage center"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="proserwis-navigators-container">
                            <Button href='#services-implementation' local className='try black'>{t("page-services.section-services-content.nav-one")}</Button>
                            <Button href='#services-description' local className='try black'>{t("page-services.section-services-content.nav-two")}</Button>
                            <Button href='#services-description-promo' local className='try black'>{t("page-services.section-services-content.nav-three")}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SectionContent
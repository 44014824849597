import React, { useState, useRef } from 'react';
import { Form, Alert, InputGroup } from 'react-bootstrap';
import './ContactForm.css';
import Button from '../../../components/Button/Button';
import Spinner from "react-bootstrap/Spinner";
import sendMail from '../../../utils/sendMail';
import { Link } from 'react-router-dom';
import { Turnstile } from '@marsidev/react-turnstile'
import phonePrefixOptions from '../../../data/phonePrefixOptions';
import phonePrefixOptionsEN from '../../../data/phonePrefixOptionsEN';
import { Trans, useTranslation } from 'react-i18next'

const ContactForm = () => {
    const emailRef = useRef()
    const phoneRef = useRef()
    const fullNameRef = useRef()
    const subjectRef = useRef()
    const messageRef = useRef()
    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const [statuteAccepted, setStatuteAccepted] = useState(false)
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState('+48');
    const [validEmail, setValidEmail] = useState(true)
    const [validPhone, setValidPhone] = useState(true)
    const [validFullName, setValidFullName] = useState(true)
    const [validMsg, setValidMsg] = useState(true)
    const [validSubject, setValidSubject] = useState(true)
    const [captchaToken, setCaptchaToken] = useState()
    const [lastSent, setLastSent] = useState(
        () => Number(localStorage.getItem('lastSent')) || null
    );
    const { t, i18n } = useTranslation('translation');

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const phoneRegex = /^[\d\s-]{9,}$/;
    const fullNameRegex = /^[^\`<>&"{}|^~\\0-9]{3,}\s+[^\`<>&"{}|^~\\0-9]{3,}$/;

    const handlePhoneChange = () => {
        const phoneNumber = phoneRef.current.value;

        if (phoneRegex.test(phoneNumber)) {
            setValidPhone(true)
        } else {
            setValidPhone(false)
        }
    };

    const handlePhonePrefixChange = (selectedOption) => {
        setSelectedPhonePrefix(selectedOption);
    };
    const handleSubjectChange = () => {
        const subject = subjectRef.current.value;

        if (subject.length < 8) {
            setValidSubject(false)
        } else {
            setValidSubject(true)
        }
    }

    const handleFullNameChange = () => {
        const fullName = fullNameRef.current.value;

        if (fullNameRegex.test(fullName)) {
            setValidFullName(true)
        } else {
            setValidFullName(false)
        }
    }

    const handleMsgChange = () => {
        const msg = messageRef.current.value;

        if (msg.length < 35) {
            setValidMsg(false)
        } else {
            setValidMsg(true)
        }
    }

    const handleEmailChange = () => {
        const email = emailRef.current.value;
        if (emailRegex.test(email)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }
    }
    async function handleSubmit(e) {
        e.preventDefault()
        setError('')
        setErrorSuccess('')
        setLoading(true)

        const now = Date.now();
        if (lastSent && now - lastSent < 5 * 60 * 1000) {
            setLoading(false)
            return setError(t("errors.try-again-later"));
        }

        if (!captchaToken) {
            setLoading(false)
            return setError(t("forms.error.captcha-error"))

        }
        if (!validEmail || !validPhone || !validMsg || !validSubject || !validFullName || !fullNameRef.current.value || !messageRef.current.value || !subjectRef.current.value || !phoneRef.current.value || !emailRef.current.value) {
            setLoading(false)
            return setError(t("forms.error.fill-data-error"))

        }

        let clearPhoneNumber = phoneRef.current.value.trim().replace(/[-\s]/g, '');
        let fullPhoneNumber = `${selectedPhonePrefix}${clearPhoneNumber}`
        const formattedMessage = transformToHTMLParagraphs(messageRef.current.value);

        const dataAdmin = {
            author: 'noreply',
            sender: emailRef.current.value,
            receiver: 'kontakt@gainfps.com',
            subject: `${subjectRef.current.value}`,
            full_name: fullNameRef.current.value,
            phone: fullPhoneNumber,
            message: formattedMessage,
            template: 'a_contactFormTemplate'
        };

        const dataUser = {
            author: 'noreply',
            sender: emailRef.current.value,
            receiver: emailRef.current.value,
            subject: `${subjectRef.current.value}`,
            full_name: fullNameRef.current.value,
            phone: fullPhoneNumber,
            message: formattedMessage,
            template: `${t("page-contact.contact-form.user-email-template")}`,
            language: i18n?.language
        }
        try {
            await sendMail(dataAdmin)
            await sendMail(dataUser)
            setErrorSuccess(t("page-contact.contact-form.message-sent-properly"))
            setLastSent(now);
            localStorage.setItem('lastSent', now.toString());  // zapisujemy czas do localStorage

        } catch (error) {
            setError(t("page-contact.contact-form.message-error"))
        } finally {
            setLoading(false)
        }
    }

    const transformToHTMLParagraphs = (text) => {
        return text.split('\n').map((segment, index) => `<p style="Margin:0;font-family:'lucida sans unicode','lucida grande',sans-serif;line-height:21px;letter-spacing:0;color:#cccccc;font-size:14px!important;margin-bottom: 20px;">${segment}</p>`).join('');
    };

    return (
        <>
            <div className="component-contact-form contact-page">
                <div className="component-contact-form-inner">
                    <Form onSubmit={handleSubmit} className="form contact-form" noValidate >
                        {error && <Alert variant="danger">{error}</Alert>}
                        {errorSuccess && <Alert variant='success'>{errorSuccess}</Alert>}
                        <Form.Group className="form-contact-group" id="form-contact-email">
                            <Form.Label>{t("forms.label.email")}</Form.Label>
                            <Form.Control type='email' ref={emailRef} required placeholder={t("forms.placeholder.email")} maxLength={45} onChange={handleEmailChange} isInvalid={!validEmail} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-email")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-name">
                            <Form.Label>{t("forms.label.full-name")}</Form.Label>
                            <Form.Control type='text' placeholder={t("forms.placeholder.full-name")} required ref={fullNameRef} onChange={handleFullNameChange} isInvalid={!validFullName} maxLength={45} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-full-name")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-phone">
                            <Form.Label>{t("forms.label.phone")}</Form.Label>
                            <InputGroup>
                                <Form.Select className='phone-select' aria-label='select phone direction' onChange={(e) => { handlePhonePrefixChange(e.target.value) }} value={selectedPhonePrefix}>

                                    {
                                        i18n.language === 'pl' ?
                                            phonePrefixOptions.map((option, index) =>
                                                <option key={index} value={option.value} label={option.label}>{option.label}</option>
                                            )
                                            :
                                            phonePrefixOptionsEN.map((option, index) =>
                                                <option key={index} value={option.value} label={option.label}>{option.label}</option>
                                            )
                                    }
                                </Form.Select>
                                <Form.Control type='tel' className='phone-input' placeholder={t("forms.placeholder.phone-number")} required ref={phoneRef} maxLength={16} onChange={handlePhoneChange} isInvalid={!validPhone} />
                                <Form.Control.Feedback type="invalid">
                                    {t("forms.feedback.wrong-phone-format")}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-topic">
                            <Form.Label>{t("forms.label.subject")}</Form.Label>
                            <Form.Control type="text" ref={subjectRef} required placeholder={t("forms.placeholder.subject")} maxLength={45} onChange={handleSubjectChange} isInvalid={!validSubject} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-subject")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-message">
                            <Form.Label>{t("forms.label.message")}</Form.Label>
                            <Form.Control as="textarea" rows={3} required ref={messageRef} placeholder={t("forms.placeholder.message")} minLength={30} maxLength={2000} onChange={handleMsgChange} isInvalid={!validMsg} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-message")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="choose-option-container">
                            <div className="options-container">
                                <div className="option-wrapper">
                                    <input type="checkbox" name="contact-select-accept-privacy" id="contact-select-accept-privacy" onChange={(event) => {
                                        setStatuteAccepted(event.target.checked)
                                    }} />
                                    <label htmlFor="contact-select-accept-privacy" className="option option-3">
                                        <div className="dot-wrapper">
                                            <div className="dot"></div>
                                        </div>
                                        <span>{<Trans i18nKey={"page-contact.contact-form.privacy-accept"}>*Oświadczam, że zapoznałem się z <span style={{ fontFamily: 'Gilroy-bold, sans-serif' }}><Link to='/polityka-prywatnosci'>Polityką prywatności</Link></span> oraz akceptuję jej treść.</Trans>}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Button type='submit' className="try" disabled={!statuteAccepted || loading}>
                            {loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("nav-buttons.send")}
                        </Button>
                        <Turnstile
                            siteKey='0x4AAAAAAAJyAbnGv_4is5AB'
                            onSuccess={(token) => { setCaptchaToken(token) }} />
                    </Form>
                </div>
            </div>

        </>
    )
}

export default ContactForm
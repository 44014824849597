import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Button from '../../../../components/Button/Button'
import { useTranslation } from 'react-i18next'
import './SectionContent.css'

const SectionContent = () => {

    const { t } = useTranslation('translation')
    return (
        <section className='gm-content' id='gm-content'>
            <Container>
                <Row>
                    <Col>
                        <div className="proserwis-navigators-container">
                            <Button href='#gm-how-it-works' local className='try black'>{t("page-gamingmode.section-gm-content.how-it-works")}</Button>
                            <Button href='#gm-instruction' local className='try black'>{t("page-gamingmode.section-gm-content.manual")}</Button>
                            <Button href='#gm-steam-boost' local className='try black'>{t("page-gamingmode.section-gm-content.steam-boost")}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SectionContent
import { sendEventToServer } from "../pixelEventsAPI";

// Dodanie do koszyka - standardowa nazwa 'AddToCart' <
export const addToCartEvent = (language, productSkuId, productName, productPrice, currency, hashed_email, hashed_user_id) => {

    const event_id = `add${productSkuId}ToCart${Date.now()}`;

    // Meta (FB, IG)
    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'AddToCart', {
            content_ids: `${productSkuId}`,
            content_type: "product",
            content_name: productName,
            value: productPrice,
            currency: currency,
            language: language
        }, {
            eventID: event_id
        });
    }
    
    // TikTok
    if (typeof window.ttq !== 'undefined') {
        
        window.ttq.identify({
            "email": hashed_email, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
            "external_id": hashed_user_id // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
        });

        window.ttq.track('AddToCart', {
            contents: [
                {
                    content_id: `${productSkuId}`,
                    content_name: productName,
                    price: productPrice
                }
            ],
            content_type: "product",
            value: productPrice,
            currency: currency,
            locale: language
        },
        {event_id: event_id});
    }

    // Wyślij zdarzenie do serwera
    const eventData = {
        context: {
            user: {
                external_id: hashed_user_id,
                email: hashed_email,
                client_user_agent: navigator.userAgent
            },
            page: {
                url: window.location.href
            }
        },
        properties: {
            contents: [
                {
                    content_id: `${productSkuId}`,
                    content_type: "product",
                    content_name: productName,
                    price: productPrice
                }
            ],
            event_id: event_id,
            value: productPrice,
            currency: currency,
            locale: language
        }
    };

    
    sendEventToServer('https://gainfps.com/api/m-event', 'AddToCart', eventData);
    sendEventToServer('https://gainfps.com/api/t-event', 'AddToCart', eventData);
    // sendEventToServer('http://localhost:4747/api/m-event', 'AddToCart', eventData);
    // sendEventToServer('http://localhost:4747/api/t-event', 'AddToCart', eventData);
}
import React from 'react'
import { Image } from 'react-bootstrap'
import LogoBlack from '../../../assets/img/gainfps/logo-color.svg'
import "./PersonalData.scss"

const PersonalData = ({ customName, email }) => {

    return (

        <div className="component-personal">
            <div className="component-personal-inner">
                <div className="personal-avatar-wrapper">
                    <Image
                        src={LogoBlack}
                        alt="personal avatar"
                        className='circular--landscape'
                    />
                </div>
                <div className="personal-info-wrapper">
                    <h2>{customName}</h2>
                    <h3>{email}</h3>
                </div>
            </div>
        </div>

    )
}

export default PersonalData
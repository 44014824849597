import React from 'react';
import Slider from "react-slick";
import "./ReviewsSlider.css"
import Review from '../Review/Review';
import fusialka from "../../assets/img/reviews/fusialka1-min.webp"
import zony from "../../assets/img/reviews/zony1-min.webp"
import pasha from "../../assets/img/reviews/pasha1-min.webp"
import mork from "../../assets/img/reviews/mork1-min.webp"
import bejott from "../../assets/img/reviews/bejott1-min.webp"
import yaotzin from "../../assets/img/reviews/yaotzin1-min.webp"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

const SampleNextArrow = (props) => {
    const { className2, onClick } = props;
    return (
        <div
            className={className2}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = (props) => {
    const { className2, onClick } = props;
    return (
        <div
            className={className2}
            onClick={onClick}
        />
    );
}

const ReviewsSlider = () => {

    const { t } = useTranslation('translation')
    const xs = useMediaQuery('(max-width:575px)');
    const sm = useMediaQuery('(min-width: 576px) and (max-width: 767px)');
    const md = useMediaQuery('(min-width: 768px) and (max-width: 991px)');
    const lg = useMediaQuery('(min-width: 992px) and (max-width: 1199px)');
    const xl = useMediaQuery('(min-width: 1200px) and (max-width: 1399px)');
    const xxl = useMediaQuery('(min-width: 1400px)');

    let slidesToShowValue = 1.7; // Default value

    if (xs) {
        slidesToShowValue = 1.03; // Modify the value for xs
    } else if (sm) {
        slidesToShowValue = 1.5; // Modify the value for sm
    } else if (md) {
        slidesToShowValue = 1.7; // Modify the value for md
    } else if (lg) {
        slidesToShowValue = 1.05; // Modify the value for lg
    } else if (xl) {
        slidesToShowValue = 1.5; // Modify the value for xl
    } else if (xxl) {
        slidesToShowValue = 1.7; // Modify the value for xl
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShowValue,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow className2="next-button" />,
        prevArrow: <SamplePrevArrow className2="prev-button" />
    };

    return (
        <Slider {...settings}>
            <div className="each-slide-effect">
                <Review
                    className={"long-name"}
                    name={"FUSIALKA"}
                    career={t("components.reviews-slider.influencer")}
                    avatar={fusialka}
                    text={<>
                        {t("components.reviews-slider.fusialka.first-part")}
                        <br /><br />
                        {t("components.reviews-slider.fusialka.second-part")}
                    </>} />

            </div>

            <div className="each-slide-effect">
                <Review
                    name={"ZONY"}
                    career={t("components.reviews-slider.streamer")}
                    avatar={zony}
                    text={<>
                        {t("components.reviews-slider.zony.first-part")}
                        <br /><br />
                        {t("components.reviews-slider.zony.second-part")}
                    </>}
                />
            </div>
            <div className="each-slide-effect">
                <Review
                    name={"PASHA"}
                    career={t("components.reviews-slider.legend")}
                    avatar={pasha}
                    text={<>
                        {t("components.reviews-slider.pasha.first-part")}
                        <br /><br />
                        {t("components.reviews-slider.pasha.second-part")}
                    </>} />
            </div>

            <div className="each-slide-effect">
                <Review
                    name={"MORK"}
                    career={t("components.reviews-slider.streamer")}
                    avatar={mork}
                    text={<>
                        {t("components.reviews-slider.mork.first-part")}
                        <br /><br />
                        {t("components.reviews-slider.mork.second-part")}
                    </>} />
            </div>

            <div className="each-slide-effect">
                <Review
                    name={"BEJOTT"}
                    career={t("components.reviews-slider.fifa-player")}
                    avatar={bejott}
                    text={<>
                        {t("components.reviews-slider.bejott.first-part")}
                        <br /><br />
                        {t("components.reviews-slider.bejott.second-part")}
                    </>} />
            </div>

            <div className="each-slide-effect">
                <Review
                    name={"YAOTZIN"}
                    career={t("components.reviews-slider.valorant-player")}
                    avatar={yaotzin}
                    text={<>
                        {t("components.reviews-slider.yaotzin.first-part")}
                        <br /><br />
                        {t("components.reviews-slider.yaotzin.second-part")}
                    </>} />
            </div>
        </Slider>
    );
};



export default ReviewsSlider;
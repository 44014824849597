import sendMail from "../../../../utils/sendMail";

async function adminMailMsgSend(receiver, message, subject, language) {

    // wysylanie maila z senderem: admin (kontakt@gainfps.com)
    try {
        const { data, error } = await sendMail({ author: 'admin', receiver: receiver, message: message, subject: subject, template: 'u_cleanMessageTemplate', language: language })
        if (error) throw error
    }
    catch (error) {
        return error
        // throw new Error('Wystąpił błąd podczas wysyłania wiadomości e-mail.')
    }
}



export default adminMailMsgSend
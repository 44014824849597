import React from 'react'
import './AdminViewFunctions.css'
import AdminFunctionBar from '../AdminFunctionBar/AdminFunctionBar'
import AdminFunctionsTable from '../AdminFunctionsTable/AdminFunctionsTable'

const AdminViewFunctions = ({ setCurrentView }) => {
    return (
        <div className="admin-view-functions-container">
            <div className="admin-view-functions-wrapper">
                <div className="pane-container">
                    <AdminFunctionsTable title={'Usługi'}>
                        <AdminFunctionBar title={'Dodaj'} onClick={() => { setCurrentView('svc-add') }} />
                        <AdminFunctionBar title={'Zmień status'} onClick={() => { setCurrentView('svc-status-change') }} />
                    </AdminFunctionsTable>
                    <AdminFunctionsTable title={'Lorem ipsum'}>
                        <AdminFunctionBar title={'Lorem ipsum'} />
                        <AdminFunctionBar title={'Lorem ipsum'} />
                        <AdminFunctionBar title={'Lorem ipsum'} />
                        <AdminFunctionBar title={'Lorem ipsum'} />
                    </AdminFunctionsTable>
                </div>
                <div className="pane-container">
                    <AdminFunctionsTable title={'Mail'} >
                        <AdminFunctionBar title={'Wiadomość'} onClick={() => { setCurrentView('mail-msg-send') }} />
                    </AdminFunctionsTable>
                    <AdminFunctionsTable title={'Lorem ipsum'}>
                        <AdminFunctionBar title={'Lorem ipsum'} />
                        <AdminFunctionBar title={'Lorem ipsum'} />
                        <AdminFunctionBar title={'Lorem ipsum'} />
                        <AdminFunctionBar title={'Lorem ipsum'} />
                    </AdminFunctionsTable>
                </div>
            </div>
        </div>


    )
}

export default AdminViewFunctions
import React, { useEffect, useState } from 'react'
import { useDatabasePageContext } from '../../../../context/DatabasePageContext'
import Spinner from 'react-bootstrap/Spinner'
import { Row, Col, Container } from 'react-bootstrap'
import Text from '../../../../components/Text/Text'
import ProductBoxDescription from '../../../../components/ProductBoxDescription/ProductBoxDescription'
import './SectionDescriptionPromo.css'
import { useTranslation, Trans } from 'react-i18next'

const SectionDescriptionPromo = () => {

    const { useReadPackagesDB, promoPackages, isPageLoading } = useDatabasePageContext()
    const [productPromoBoxes, setProductPromoBoxes] = useState([])
    const { t } = useTranslation('translation')
    useReadPackagesDB()

    useEffect(() => {
        setProductPromoBoxes([
            {
                id: promoPackages[0]?.id,
                name: promoPackages[0]?.name,
                price: promoPackages[0]?.price,
                description: promoPackages[0]?.short_desc,
                short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>{promoPackages[0]?.service_name}</span> {t("page-services.section-description-promo.for-two-people")}</>,
                promo_price: promoPackages[0]?.promo_price,
                time_description: promoPackages[0]?.time_description,
                mode: t("page-services.section-description-promo.remote"),
                benefits_list: promoPackages[0]?.benefits_list,
                support_days: promoPackages[0]?.support_days,
                service_name: promoPackages[0]?.service_name,
                users_number: promoPackages[0]?.users_number,
                duration: promoPackages[0]?.duration,
                type: promoPackages[0]?.type,
                status: promoPackages[0]?.status,
                position: promoPackages[0]?.position,
                advantages_array: [
                    <>2x {t("page-services.section-description-promo.package")} <span style={{ color: 'rgb(0,224,255)' }}>{promoPackages[0]?.service_name}</span></>,
                    t("page-services.section-description-promo.schedule-in-panel"),
                    t("page-services.section-description-promo.any-two-users")
                ]
            },
            {
                id: promoPackages[1]?.id,
                name: promoPackages[1]?.name,
                price: promoPackages[1]?.price,
                description: promoPackages[1]?.short_desc,
                short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>{promoPackages[1]?.service_name}</span> {t("page-services.section-description-promo.for-two-people")}</>,
                promo_price: promoPackages[1]?.promo_price,
                time_description: promoPackages[1]?.time_description,
                mode: t("page-services.section-description-promo.remote"),
                benefits_list: promoPackages[1]?.benefits_list,
                support_days: promoPackages[1]?.support_days,
                service_name: promoPackages[1]?.service_name,
                users_number: promoPackages[1]?.users_number,
                duration: promoPackages[1]?.duration,
                type: promoPackages[1]?.type,
                status: promoPackages[1]?.status,
                position: promoPackages[1]?.position,
                advantages_array: [
                    <>2x {t("page-services.section-description-promo.package")} <span style={{ color: 'rgb(0,224,255)' }}>{promoPackages[1]?.service_name}</span></>,
                    t("page-services.section-description-promo.schedule-in-panel"),
                    t("page-services.section-description-promo.any-two-users")
                ]
            },
            {
                id: promoPackages[2]?.id,
                name: promoPackages[2]?.name,
                price: promoPackages[2]?.price,
                description: promoPackages[2]?.short_desc,
                short_desc: <Trans i18nKey={"page-services.section-description-promo.gift-package.title"}><span style={{ color: 'rgb(0,224,255)' }}>DZIKA MOC</span> w prezencie 🐗🎁</Trans>,
                promo_price: promoPackages[2]?.promo_price,
                time_description: promoPackages[2]?.time_description,
                mode: t("page-services.section-description-promo.remote"),
                benefits_list: promoPackages[2]?.benefits_list,
                support_days: promoPackages[2]?.support_days,
                service_name: promoPackages[2]?.service_name,
                users_number: promoPackages[2]?.users_number,
                duration: promoPackages[2]?.duration,
                type: promoPackages[2]?.type,
                status: promoPackages[2]?.status,
                position: promoPackages[2]?.position,
                advantages_array: [
                    <>1x {t("page-services.section-description-promo.package")} <span style={{ color: 'rgb(0,224,255)' }}>{promoPackages[2]?.service_name}</span></>,
                    t("page-services.section-description-promo.gift-package.letter"),
                    t("page-services.section-description-promo.gift-package.interactive-prepare"),
                    t("page-services.section-description-promo.gift-package.choose-gift"),
                    t("page-services.section-description-promo.gift-package.prepare-together"),
                    t("page-services.section-description-promo.gift-package.any-user"),
                    t("page-services.section-description-promo.gift-package.user-schedule")
                ]
            }
        ])

    }, [promoPackages])


    return (
        <section className="section-services-description-promo" id="services-description-promo">
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Text
                            h1={t("page-services.section-description-promo.header")}
                            h3={t("page-services.section-description-promo.sub-header")}
                            className={"subpage center"}
                        />
                    </Col>
                </Row>
                {
                    isPageLoading ?
                        <Row className='d-flex justify-content-center'>
                            <Spinner animation="border" className='loading-spinner' />
                        </Row>
                        :
                        productPromoBoxes.sort((a, b) => a.position - b.position).map((promo, index) =>
                            <ProductBoxDescription key={index} id={promo.id} type={promo.type} time_description={promo.time_description} service_name={promo.service_name} users_number={promo.users_number} duration={promo.duration} support_days={promo.support_days} promo_price={promo.promo_price} mode={promo.mode} time={promo.time} name={promo.name} price={promo.price} description={promo.description} short_desc={promo.short_desc} advantages_array={promo.advantages_array} benefits_list={promo.benefits_list} status={promo.status} />
                        )
                }
            </Container>
        </section>
    )
}

export default SectionDescriptionPromo
import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap';
import HistoryOrderBar from '../HistoryOrderBar/HistoryOrderBar';
import './ViewOrdersHistory.css'
import { useAuth } from '../../../context/AuthContext';
import { useDatabaseUserContext } from '../../../context/DatabaseUserContext';
import Button from '../../Button/Button';
import HistoryOrderBarSceletor from '../HistoryOrderBarSceletor/HistoryOrderBarSceletor';
import { useTranslation } from 'react-i18next';

const ProfileOrdersHistory = () => {

  const { t } = useTranslation('translation')
  const { currentUser } = useAuth()
  const { orders, useReadOrders, userData, useReadUserData } = useDatabaseUserContext()
  const [error, setError] = useState('')
  useReadUserData(currentUser?.id)
  useReadOrders(currentUser?.id)

  useEffect(() => {
    if (userData) {
      if (!userData?.first_name || !userData?.last_name || !userData?.phone_number || !userData?.phone_number_prefix) {
        setError(t("page-profile.view-orders-history.fill-userdata-error"))
      } else {
        setError('')
      }
    }
  }, [userData]

  );
  return (
    <>
      <div className="view-orders-history-container">
        <div className="view-orders-history-wrapper">
          {error && <Alert variant="danger">{error}</Alert>}
          {
            error ? '' :
              orders ? (orders.length !== 0 ?
                orders.map((order) =>
                  <HistoryOrderBar key={order?.order_id} orderId={order?.order_id} date={order?.date_time} packageName={order?.packages.name} status={order?.status} price={order?.packages.price} orderClients={order?.client_emails} amount={order?.amount} invoiceSent={order?.invoice_sent} currency={order?.currency} />
                ) :
                <>
                  <h2>{t("page-profile.view-orders-history.no-orders-error")}</h2>
                  <Button className='try medium' href='/zakup'>{t("page-profile.view-orders-history.buy-now")}</Button>
                </>) : <><HistoryOrderBarSceletor />
                <HistoryOrderBarSceletor /></>
          }
        </div>
      </div>
    </>
  )
}

export default ProfileOrdersHistory
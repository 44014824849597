import React from 'react';
import './Signup.css';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import Footer from '../../components/Footer/Footer';
import SignupForm from './Sections/SignupForm/SignupForm'
import DatabaseUserProvider from '../../context/DatabaseUserContext';
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel';

const Signup = () => {

    return (
        <>
            <TopNavigation />
            <main className="page signup" id='page-signup'>
                <SocialMediaPanel />
                <DatabaseUserProvider>
                    <SignupForm />
                </DatabaseUserProvider>
            </main>
            <Footer />
        </>
    )

}
export default Signup
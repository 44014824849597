import { sendEventToServer } from "../pixelEventsAPI";

// Kliknięcie przycisku Instagram - zdarzenie na meta niestandardowe
export const gmDownloadedEvent = (language, hashed_email, hashed_user_id) => {

    const event_id = `gmDownloaded${Date.now()}`;

    // Meta (FB, IG)
    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'Lead', {
            content_name: 'Gaming Mode',
            content_category: "Download",
            language: language
        }, {
            eventID: event_id
        });
    }

    // TikTok
    if (typeof window.ttq !== 'undefined') {

        window.ttq.identify({
            "email": hashed_email, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
            "external_id": hashed_user_id // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
        });

        window.ttq.track('Download', {
            contents: [
                {
                    content_name: 'Gaming Mode',
                    content_category: 'Download'
                }
            ],
            locale: language
        }, { event_id: event_id });
    }

    // Wyślij zdarzenie do serwera
    const eventData = {
        context: {
            user: {
                external_id: hashed_user_id,
                email: hashed_email,
                client_user_agent: navigator.userAgent
            },
            page: {
                url: window.location.href
            }
        },
        properties: {
            contents: [
                {
                    content_name: 'Gaming Mode',
                    content_category: 'Download'
                }
            ],
            event_id: event_id,
            locale: language
        }
    };

    sendEventToServer('https://gainfps.com/api/m-event', 'Lead', eventData);
    sendEventToServer('https://gainfps.com/api/t-event', 'Download', eventData);
    // sendEventToServer('http://localhost:4747/api/m-event', 'Lead', eventData);
    // sendEventToServer('http://localhost:4747/api/t-event', 'Download', eventData);
}
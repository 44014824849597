import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css"
import { Link } from 'react-router-dom';
import Logotype from '../../assets/img/gainfps/logotype-gray.svg'
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import igIcon from '../../assets/img/socials/icons8-instagram.svg'
import fbIcon from '../../assets/img/socials/icons8-facebook.svg'
import ttIcon from '../../assets/img/socials/icons8-tiktok.svg'

const Footer = () => {

    const { t } = useTranslation('translation')
    return (
        <footer>
            <div className="footer-wrapper">
                <Container>
                    <Row>
                        <Col xl={6} lg={7} md={12} sm={12} className='col left'>
                            <div className="logotype-wrapper">
                                <Link to="/">
                                    <img src={Logotype} alt='logotype' />
                                </Link>
                            </div>
                            <p>{t("components.footer.gainfps-sign")}</p>
                            <Link to="/kontakt">{t("components.footer.contact")}</Link>
                            <Link to="/zakup">{t("components.footer.buy-now")}</Link>
                        </Col>

                        <Col xl={6} lg={5} md={12} sm={12} className='col right'>
                            <HashLink to="/uslugi/#implementation">{t("components.footer.implementation")}</HashLink>
                            <Link to="/regulamin">{t("components.footer.statute")}</Link>
                            <Link to="/polityka-prywatnosci">{t("components.footer.privacy-politics")}</Link>
                            <div className="icons-holder">
                                <a href='https://www.instagram.com/gainfps/'><img src={igIcon} alt='Instagram' /></a>
                                <a href='https://www.facebook.com/gainfps'><img src={fbIcon} alt='Facebook' /></a>
                                <a href='https://www.tiktok.com/@gainfps'> <img src={ttIcon} alt='Tiktok' /></a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer;
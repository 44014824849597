export const authEmailsTranslations = {
    pl: {
        ConfirmSignup: {
            TranslatedSubject: 'GainFPS - Witamy w serwisie'
        },
        InviteUser: {
            TranslatedSubject: 'GainFPS - Zaproszenie do centrum optymalizacji'
        },
        MagicLink: {
            TranslatedSubject: 'GainFPS - Jednorazowe logowanie'
        },
        ChangeEmailAddress: {
            TranslatedSubject: 'GainFPS - Zmiana adresu e-mail'
        },
        ResetPassword: {
            TranslatedSubject: 'GainFPS - Reset hasła'
        }
    },
    en: {
        ConfirmSignup: {
            TranslatedSubject: 'GainFPS - Welcome to the service'
        },
        InviteUser: {
            TranslatedSubject: 'GainFPS - Invitation to register'
        },
        MagicLink: {
            TranslatedSubject: 'GainFPS - One-Time login'
        },
        ChangeEmailAddress: {
            TranslatedSubject: 'GainFPS - Change of email address'
        },
        ResetPassword: {
            TranslatedSubject: 'GainFPS - Password reset'
        }
    },
}

export default authEmailsTranslations;
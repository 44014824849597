import React, { useRef, useState } from 'react'
import './SettingsViewEmail.css'
import SettingsBackButton from '../../settings-components/SettingsBackButton/SettingsBackButton'
import Button from '../../../../Button/Button'
import { Form, Alert, InputGroup } from 'react-bootstrap'
import Spinner from "react-bootstrap/Spinner";
import SimpleContainer from '../../../../SimpleContainer/SimpleContainer'
import { useDatabaseUserContext } from '../../../../../context/DatabaseUserContext'
import visibleOffIcon from '../../../../../assets/img/icons/visibility_off_black_36dp.svg'
import visibleOnIcon from '../../../../../assets/img/icons/visibility_black_36dp.svg'
import supabase from '../../../../../data/supabase'
import { useAuth } from '../../../../../context/AuthContext'
import { useTranslation } from 'react-i18next'

const SettingsViewEmail = ({ setCurrentView }) => {


    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [validEmail, setValidEmail] = useState(true)
    const emailRef = useRef()
    const newEmailRef = useRef()
    const passwordRef = useRef()
    const { userData } = useDatabaseUserContext()
    const { logout } = useAuth()
    const { t } = useTranslation('translation')

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleEmailChange = () => {
        const email = newEmailRef.current.value;
        if (emailRegex.test(email)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        setError('')
        setErrorSuccess('')

        const password = passwordRef.current.value;
        const newEmail = (newEmailRef.current.value).trim();

        // Walidacja nowego email

        if (!password || !newEmail) {
            setLoading(false);
            return setError(t("forms.error.fill-data-error"));
        } else if (!newEmail || !emailRegex.test(newEmail)) {
            setLoading(false);
            return setError(t("page-profile.view-settings.settings-email.wrong-email-error"));
        } else if (newEmail === userData?.email) {
            setLoading(false);
            return setError(t("page-profile.view-settings.settings-email.same-emails-error"));
        }

        try {
            // check if password is ok

            const { data: checkPasswordData, error: checkPasswordError } = await supabase
                .rpc('validate_user_password', { 'current_plain_password': password })

            if (checkPasswordError) throw new Error("Wrong password.")

            const { data: updateData, error: updateError } = await supabase.auth.updateUser({ email: newEmail })

            if (updateError) throw updateError

            setErrorSuccess(t("page-profile.view-settings.settings-email.confirm-email-change"))

            setTimeout(async () => {
                await logout();
            }, 3000);

        } catch (error) {
            if (error?.message?.includes("Wrong password.")) {
                setError(t("forms.feedback.wrong-password"));
            }
            else if (error?.code === 'P0001' || error?.message?.includes(
                "A user with this email address has already been registered") || error?.msg?.includes("Podany adres e-mail jest zajęty.")) {
                setError(t("forms.feedback.email-taken"))
            }
            else {
                setError(t("page-profile.view-settings.settings-email.email-change-error"))
            }

        }

        setLoading(false)
    }

    return (
        <SimpleContainer>
            <div className="settings-view-email-container">
                <div className="settings-view-email-wrapper">
                    <Form onSubmit={handleSubmit} className="form settings-view-form">
                        {error && <Alert variant="danger">{error}</Alert>}
                        {errorSuccess && <Alert variant="success">{errorSuccess}</Alert>}
                        <Form.Group className="form-group settings">
                            <Form.Label>{t("forms.label.email")}</Form.Label>
                            <Form.Control type="email" readOnly defaultValue={userData?.email ?? ''} ref={emailRef} placeholder={t("forms.placeholder.email")} disabled className={(userData?.email) ? `plaintext` : `plaintext loading`} maxLength={45} />
                        </Form.Group>
                        <Form.Group className="form-group settings">
                            <Form.Label>{t("forms.label.password")}</Form.Label>
                            <InputGroup>
                                <Form.Control type={showPassword ? 'text' : "password"} ref={passwordRef} placeholder={t("forms.placeholder.enter-password")} maxLength={45} />
                                <span className="form-control password-toggle" style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} onClick={togglePasswordVisibility}>
                                    {showPassword ? <img src={visibleOffIcon} alt='visible icon' /> : <img src={visibleOnIcon} alt='visible icon' />}
                                </span>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="form-group settings">
                            <Form.Label>{t("page-profile.view-settings.settings-email.new-email-label")}</Form.Label>
                            <Form.Control type="email" ref={newEmailRef} placeholder={t("page-profile.view-settings.settings-email.new-email-placeholder")} maxLength={45} onChange={handleEmailChange} isInvalid={!validEmail} />
                            <Form.Control.Feedback type="invalid">
                                {t("page-profile.view-settings.settings-email.new-email-feedback")}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    <Button type='submit' className='try medium' disabled={loading} onClick={handleSubmit}>{loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("page-profile.view-settings.settings-email.change-email-button")}
                    </Button>
                    <SettingsBackButton setCurrentView={() => { setCurrentView('functions') }} />
                </div>
            </div>
        </SimpleContainer>
    )
}

export default SettingsViewEmail
import React, { useState, useRef, useEffect } from 'react'
import { Form, Alert, InputGroup } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import './UserDataForm.css'
import { useAuth } from '../../../context/AuthContext'
import Button from '../../../components/Button/Button'
import { useDatabaseUserContext } from '../../../context/DatabaseUserContext'
import Spinner from 'react-bootstrap/Spinner'
import phonePrefixOptions from '../../../data/phonePrefixOptions'
import phonePrefixOptionsEN from '../../../data/phonePrefixOptionsEN'
import { sendOTP, verifyOTP } from '../../../utils/OTPCodes';
import supabase from '../../../data/supabase';
import { useTranslation } from 'react-i18next';

const UserDataForm = () => {

    const { t } = useTranslation('translation')
    const phoneRef = useRef()
    const nameRef = useRef()
    const surnameRef = useRef()
    const emailRef = useRef()
    const otpRef = useRef()
    const [error, setError] = useState('')
    const [errorForm, setErrorForm] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const { currentUser } = useAuth()
    const { useReadUserData, userData, updateUserData, updateClientDetails } = useDatabaseUserContext()
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState('+48');
    const [validPhone, setValidPhone] = useState(true)
    const [validName, setValidName] = useState(true)
    const [validSurname, setValidSurname] = useState(true)
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [validOTP, setValidOTP] = useState(true);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const lists = {
        phonePrefixOptions,
        phonePrefixOptionsEN
    }
    useReadUserData(currentUser?.id)

    const nameRegex = /^[^\`<>&"{}|^~\\0-9]{3,}$/;
    const surnameRegex = /^[^\`<>&"{}|^~\\0-9]{3,}$/;
    const phoneRegex = /^[\d\s-]{9,}$/;
    const otpRegex = /^\d{6}$/;

    useEffect(() => {
        userData?.phone_number_prefix && setSelectedPhonePrefix(userData?.phone_number_prefix)
    }, [userData])

    const handlePhonePrefixChange = (selectedOption) => {
        setSelectedPhonePrefix(selectedOption);
        setSaveDisabled(false)
    };

    const handleShowModal = () => {
        setShowOTPModal(!showOTPModal)
    }

    const handleOTPChange = (e) => {
        setValidOTP(otpRegex.test(e.target.value))
    }
    const handlePhoneChange = (e) => {
        const phoneNumber = phoneRef.current.value;

        if (phoneRegex.test(phoneNumber)) {
            setValidPhone(true)
        } else {
            setValidPhone(false)
        }
        setSaveDisabled(false)
    };

    const handleNameChange = () => {
        const name = nameRef.current.value;

        if (nameRegex.test(name)) {
            setValidName(true)
        } else {
            setValidName(false)
        }
        setSaveDisabled(false)
    }

    const handleSurnameChange = () => {
        const surname = surnameRef.current.value;

        if (surnameRegex.test(surname)) {
            setValidSurname(true)
        } else {
            setValidSurname(false)
        }
        setSaveDisabled(false)
    }

    const handleOTPSubmit = async (event) => {
        event.preventDefault();

        let clearPhoneNumber = phoneRef.current.value.trim().replace(/[-\s]/g, '');
        let combinedPhoneNumber = `${selectedPhonePrefix}${clearPhoneNumber}`
        let otpCode = otpRef.current.value;

        if (!otpCode || !validOTP) {
            setErrorForm(t("page-profile.userdata-form.entry-otp-feedback"))
            return;
        }
        try {
            setError('')
            setErrorSuccess('')
            setLoading(true)
            try {
                const { data, error: verifyError } = await verifyOTP({ phone_number: combinedPhoneNumber, code: otpRef.current.value })
                if (verifyError) throw verifyError
            } catch (error) {
                setErrorForm(t("page-profile.userdata-form.wrong-otp-error"))
                setLoading(false)
                return;
            }

            await updateUserData(currentUser?.id, nameRef.current.value, surnameRef.current.value, clearPhoneNumber, selectedPhonePrefix)
            await updateClientDetails(currentUser?.email, nameRef.current.value, surnameRef.current.value, clearPhoneNumber, selectedPhonePrefix)
            setErrorSuccess(t("page-profile.userdata-form.data-saving-success"))
            setShowOTPModal(false)
            setSaveDisabled(true)
        } catch {
            setError(t("page-profile.userdata-form.data-saving-error"))
        }

        setLoading(false)
    };

    async function handleSubmit(e) {
        e.preventDefault()
        setErrorSuccess('')
        setError('')
        setLoading(true)

        if ((nameRef.current.value).trim() === '' ||
            (surnameRef.current.value).trim() === '' ||
            (phoneRef.current.value).trim() === '' || !validName || !validPhone || !validSurname) {
            setLoading(false)
            return setError(t("page-profile.userdata-form.entry-data-error"))
        }

        if ((phoneRef.current.value).trim() === userData?.phone_number && userData?.first_name === (nameRef.current.value).trim() && userData?.last_name === surnameRef?.current.value.trim()) {
            setLoading(false)
            return setError(t("page-profile.userdata-form.data-error"))
        }


        let clearPhoneNumber = phoneRef.current.value.trim().replace(/[-\s]/g, '');
        let combinedPhoneNumber = `${selectedPhonePrefix}${clearPhoneNumber}`

        // Wysłanie kodu OTP

        if (clearPhoneNumber !== userData?.phone_number) {


            try {
                const { data, error } = await supabase
                    .rpc('is_phone_number_taken', {
                        p_phone_number: clearPhoneNumber,
                    })
                if (error) throw error;
                if (data && data === true) {
                    setLoading(false)
                    return setError(t("page-profile.userdata-form.phone-taken-error"))
                }

            } catch (error) {
            }

            const { data, error } = await sendOTP({ phone_number: combinedPhoneNumber, channel: 'sms' })
            if (error) {
                setLoading(false)
                return setError(t("page-profile.userdata-form.otp-sending-error"));
            }

            setShowOTPModal(true)
            setLoading(false)
        } else if (clearPhoneNumber === userData?.phone_number && (userData?.first_name !== nameRef?.current.value.trim() || userData?.last_name !== surnameRef?.current.value.trim())) {
            try {
                const { data: updateUserRow, error: updateUserError } = await supabase
                    .from('users')
                    .update({
                        'first_name': nameRef.current.value.trim(),
                        'last_name': surnameRef.current.value.trim(),
                    })
                    .eq('id', currentUser?.id)

                if (updateUserError) throw updateUserError

                const { data, error } = await supabase
                    .from('client_details')
                    .update({
                        'first_name': nameRef.current.value.trim(),
                        'last_name': surnameRef.current.value.trim(),
                    })
                    .eq('email', currentUser?.email)
                    .select()
                    .single()

                if (error) throw error;

                setLoading(false)
                return setErrorSuccess(t("page-profile.userdata-form.data-saving-success"))

            } catch (error) {
                setLoading(false)
                return setError(t("page-profile.userdata-form.data-saving-error"))
            }

        }

    }

    useEffect(() => {
        if (!userData?.first_name || !userData?.last_name || !userData?.phone_number || !userData?.phone_number_prefix) {
            setError(t("page-profile.userdata-form.entry-data-necessary"))
        } else {
            setError("")
        }
    }, [userData])

    return (
        <>
            <div className="component-userdata-form">
                <div className="component-userdata-form-inner">
                    <Form onSubmit={handleSubmit} className="form userdata-form" noValidate>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {errorSuccess && <Alert variant="success">{errorSuccess}</Alert>}
                        <Form.Group className="form-userdata-group" id="form-userdata-email">
                            <Form.Label>{t("forms.label.email")}</Form.Label>
                            <Form.Control type='email' readOnly defaultValue={userData?.email ?? ''} placeholder={t("forms.placeholder.email")} ref={emailRef} disabled className={(userData && currentUser?.email) ? `plaintext` : `plaintext loading`} maxLength={45} />
                        </Form.Group>
                        <Form.Group className="form-userdata-group" id="form-userdata-name">
                            <Form.Label>{t("forms.label.name")}</Form.Label>
                            <Form.Control type="text" ref={nameRef} defaultValue={userData?.first_name ?? ''} required placeholder={t("forms.placeholder.name")} className={(userData && currentUser?.email) ? `` : `loading`} maxLength={20} isInvalid={!validName} onChange={handleNameChange} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-name")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-userdata-group" id="form-userdata-surname">
                            <Form.Label>{t("forms.label.surname")}</Form.Label>
                            <Form.Control type="text" ref={surnameRef} defaultValue={userData?.last_name ?? ''} required placeholder={t("forms.placeholder.surname")} className={(userData && currentUser?.email) ? `` : `loading`} maxLength={20} isInvalid={!validSurname} onChange={handleSurnameChange} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-surname")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-userdata-group" id="form-userdata-phone">
                            <Form.Label>{t("forms.label.phone")}</Form.Label>
                            <InputGroup>
                                <Form.Select className='phone-select' aria-label='select phone direction' onChange={(e) => { handlePhonePrefixChange(e.target.value) }} value={selectedPhonePrefix}>
                                    {
                                        lists[t("page-profile.userdata-form.phone-prefix-list")].map((option, index) =>
                                            <option key={index} value={option.value} label={option.label}>{option.label}</option>
                                        )
                                    }
                                </Form.Select>
                                <Form.Control type="tel" ref={phoneRef} defaultValue={userData?.phone_number ?? ''} required placeholder={t("forms.placeholder.phone-number")} className={(userData && currentUser?.email) ? `phone-input` : `phone-input loading`} maxLength={15} isInvalid={!validPhone} onChange={handlePhoneChange} style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} />
                                <Form.Control.Feedback type="invalid">
                                    {t("forms.feedback.wrong-phone-format")}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Button type='submit' disabled={loading || saveDisabled} className="try hover2">
                            {loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("page-profile.userdata-form.save-button")}
                        </Button>
                    </Form>

                    <Modal show={showOTPModal} onHide={handleShowModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("page-profile.userdata-form.modal-title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <strong>{t("page-profile.userdata-form.modal-entry-otp")}<br /><br /></strong>
                            <Form onSubmit={handleOTPSubmit} noValidate className='form modal-form'>
                                {errorForm && <Alert variant="danger">{errorForm}</Alert>}
                                {errorSuccess && <Alert variant="success">{errorSuccess}</Alert>}
                                <Form.Group className='form-group'>
                                    <Form.Label>{t("page-profile.userdata-form.modal-label")}</Form.Label>
                                    <Form.Control type="text" placeholder='000000' ref={otpRef} onChange={handleOTPChange} isInvalid={!validOTP} maxLength={6} />
                                    <Form.Control.Feedback type="invalid">
                                        {t("page-profile.userdata-form.modal-wrong-otp")}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button type="submit" className='try small nohover'>
                                    {loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("nav-buttons.confirm")}
                                </Button>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className='small nohover' onClick={handleShowModal}>
                                {loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("nav-buttons.close")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div >
        </>
    )
}

export default UserDataForm


import React from 'react'
import "./Showcase.css"
const Showcase = ({header, text, contactInfo}) => {
    return (
        <>
            <div className="component-showcase">
                <div className="component-showcase-inner">
                
                 <div className='showcase-header-container'>
                    <h4>{header}</h4>
                 </div>
                 <div className='showcase-text-container'>
                    {contactInfo ? <h5>{contactInfo}</h5> : ''}
                    {text ? <p>{text}</p> : ''}
                    
                 </div>
                </div>
            </div>
        </>
    )

}

export default Showcase;
import React from 'react'
import './SimpleContainer.css'
import { Image } from 'react-bootstrap'

const SimpleContainer = ({ children, title, variant, imageSrc, className, onClick }) => {
    return (
        <div className={`component-simple-container ${variant && variant} ${className && className}`} onClick={onClick}>
            <div className="component-simple-container-inner">
                {title &&
                    <div className="title-wrapper">
                        <Image src={imageSrc} alt='title image' />
                        <h3>{title}</h3>

                    </div>
                }
                {
                    children &&
                    <div className="description-wrapper">
                        {children}
                    </div>
                }
            </div>
        </div >
    )
}

export default SimpleContainer
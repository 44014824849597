import React, { useState, useEffect, useRef } from 'react';
import './SectionNewOrder.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Col, Row, Alert, Spinner } from 'react-bootstrap';
import OrderStepTwo from './new-order-steps/OrderStepTwo/OrderStepTwo';
import OrderStepOne from './new-order-steps/OrderStepOne/OrderStepOne';
import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';
import { useNewOrderContext } from '../../../../context/NewOrderContext';
import { useAuth } from '../../../../context/AuthContext';
import supabase from '../../../../data/supabase';
import OrderStepThree from './new-order-steps/OrderStepThree/OrderStepThree';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment-timezone';
import { useDatabasePageContext } from '../../../../context/DatabasePageContext';
import { useTranslation } from 'react-i18next';

const SectionNewOrder = () => {

    const [stepCounter, setStepCounter] = useState(0)
    const location = useLocation()
    const { price, name, promo_price, users_number, support_days, service_name, duration, type } = location?.state || {};
    const [error, setError] = useState('')
    const [complete, setComplete] = useState(false)
    const [loading, setLoading] = useState(true)
    const [nextDisabled, setNextDisabled] = useState(false)
    const [stepTwoDisabled, setStepTwoDisabled] = useState(true)
    const timeoutIdRef = useRef(null);
    const [userData, setUserData] = useState({
        first_name: true,
        last_name: true,
        phone_number: true,
        phone_number_prefix: true
    })

    const { t } = useTranslation('translation')
    const { timezone, currency } = useDatabasePageContext()
    const { isDateAvailable } = useNewOrderContext()
    const { currentUser } = useAuth()
    // states that will be used on final preparing when sending data to backend

    const [orderDetails, setOrderDetails] = useState({
        productName: name,
        productPrice: price,
        serviceName: service_name,
        serviceClient: null,
        purchaseForMyself: true,
        supportDays: support_days,
        bookedDate: null,
        confirmationChecked: null,
        orderId: null,
        serviceDuration: duration,
        discountCode: null,
        type: type,
        currency: 'PLN',
        currencyDisplay: 'zł',
        timezone: 'Europe/Warsaw'
    })

    useEffect(() => {
        handleStateChange('currency', currency)
    }, [currency])

    const handleStateChange = (groupName, optionName) => {
        setOrderDetails((prevState) => {
            return {
                ...prevState,
                [groupName]: optionName
            };
        });
    };

    ////////////////////////////////////////////////////////////////////////////

    const allStepsOrder = [
        <OrderStepOne handleStateChange={handleStateChange} setNextDisabled={setNextDisabled} orderDetails={orderDetails} setStepTwoDisabled={setStepTwoDisabled} stepTwoDisabled={stepTwoDisabled} users_number={users_number} />,
        <OrderStepTwo handleStateChange={handleStateChange} setNextDisabled={setNextDisabled} orderDetails={orderDetails} promo_price={promo_price} users_number={users_number} />,
        <OrderStepThree handleStateChange={handleStateChange} setNextDisabled={setNextDisabled} orderDetails={orderDetails} />
    ]

    ////////////////////////////////////////////////////////////////////////////

    async function updateAvailableHours(date, available_hours) {
        try {
            const { data, error } = await supabase
                .from('available_dates')
                .update({
                    'available_hours': available_hours
                })
                .eq('date', date)
                .select()
                .single()

            if (error) throw error;
            return { data, error }

        } catch (error) {
            return { data: null, error }
        }
    }

    async function removeEmptyAvailableDay(date) {
        try {
            const { data, error } = await supabase
                .from('available_dates')
                .delete()
                .eq('date', date)

            if (error) throw error

            return { data, error }

        } catch (error) {
            return { data: null, error }
        }
    }

    const navigate = useNavigate();

    // Używamy useEffect do anulowania setTimeout, gdy komponent zostaje odmontowany
    useEffect(() => {
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, []);

    useEffect(() => {
        switch (orderDetails?.currency) {
            case 'PLN':
                handleStateChange('currencyDisplay', 'zł')
                break;
            case 'GBP':
                handleStateChange('currencyDisplay', '£')
                break;
            case 'USD':
                handleStateChange('currencyDisplay', '$')
                break;
            default:
                handleStateChange('currencyDisplay', '€')
        }
    }, [orderDetails?.currency])

    useEffect(() => {
        handleStateChange('timezone', timezone)
    }, [timezone])

    const handleTimeout = () => {
        timeoutIdRef.current = setTimeout(() => {
            navigate('/zakup');
        }, 4000);
    }

    useEffect(() => {
        const readUserDataPurchase = async () => {
            setLoading(true)
            if (currentUser?.id) {
                try {
                    const { data, error } = await supabase
                        .from('users')
                        .select('*')
                        .eq('id', currentUser?.id)
                        .single()

                    if (error) throw error;

                    if (data != null) {
                        setUserData(data);
                    }


                } catch (error) {
                }
            }
            setLoading(false)
        }

        readUserDataPurchase();

    }, [currentUser?.id]
    )

    function handleBack() {
        setError('')
        if (stepCounter > 0 && !complete) {
            setStepCounter(stepCounter - 1)
        }
        else {
            navigate(-1)
        }
    }

    async function handleNext() {
        setLoading(true)
        setError('')
        if (!complete) {
            if (stepCounter < 2) {
                // krok 1 i 2 - wpisanie użytkowników i wybór terminu oraz postanowienia koncowe
                if (stepCounter === 0) {
                    // krok 1 - obsluga terminu
                    if (orderDetails?.bookedDate != null &&
                        orderDetails?.bookedDate?.date != null &&
                        orderDetails?.bookedDate?.time != null &&
                        orderDetails?.bookedDate?.time !== 'later' &&
                        orderDetails?.bookedDate?.date !== 'later') {

                        let new_date_moment = moment(`${orderDetails?.bookedDate?.date} ${orderDetails?.bookedDate?.time}`)
                        let isDateAvailableReturn = isDateAvailable(new_date_moment.format('YYYY-MM-DD'), orderDetails?.bookedDate?.time)
                        let availableHours = []

                        if (isDateAvailableReturn && (new_date_moment.isAfter(moment().add(5, 'hours'), 'minutes'))) {

                            try {
                                const { data, error } = await supabase
                                    .from('available_dates')
                                    .select('available_hours')
                                    .eq('date', `${orderDetails?.bookedDate?.date}`)
                                    .single()

                                if (error) throw error;

                                if (data) {
                                    if (data.available_hours) {
                                        availableHours = data.available_hours
                                    }
                                }
                            } catch (error) {
                            }

                            let availableHoursNew = availableHours.filter(hour => moment(`${orderDetails?.bookedDate?.date} ${hour}`).isAfter(moment().add(6, 'hours'), 'minutes'))
                            if (availableHoursNew.length === 0) {
                                // tabela a_h_new jest pusta, usuwamy z bazy danych wiersz dla danego pustego dnia
                                await removeEmptyAvailableDay(orderDetails?.bookedDate?.date)
                            } else {
                                // tabela a_h_new zawiera inne godziny, więc tworzymy nową tabelę, filtrując niepoprawne terminy
                                availableHoursNew = availableHoursNew.filter(hour => moment(`${orderDetails?.bookedDate?.date} ${hour}`).isAfter(moment().add(6, 'hours'), 'minutes'))
                                // wysyłamy do bazy danych zaktualizowaną tabelę a_h_new
                                if (!(availableHoursNew.length === 0)) {
                                    await updateAvailableHours(new_date_moment.format('YYYY-MM-DD'), availableHoursNew)
                                }
                                else {
                                    await removeEmptyAvailableDay(orderDetails?.bookedDate?.date)
                                }
                            }
                            //utworz nowe zamowienie i przejdz dalej jesli termin jest poprawny
                            try {
                                const { data, error } = await supabase
                                    .rpc('create_new_order', {
                                        user_id: currentUser?.id,
                                        package_name: name
                                    })
                                    .single()

                                if (error) throw error;
                                handleStateChange('orderId', data)
                                setStepCounter(stepCounter + 1)

                            } catch (error) {
                                setComplete(true)
                                setError(t("page-purchase.section-new-order.order-error"))
                                handleTimeout()
                                return { data: null, error }
                            }

                            setStepCounter(stepCounter + 1)

                        }
                        else {
                            setError(t("page-purchase.section-new-order.schedule-taken-error"))
                            if (isDateAvailableReturn) {
                                // usuwanie niepoprawnego terminu jeśli istnieje w bazie danych

                                if (new_date_moment.isBefore(moment(), 'day')) {
                                    // jesli wybrany dzien jest wczesniej niż obecny dzien, usuwa caly wiersz
                                    await removeEmptyAvailableDay(orderDetails?.bookedDate?.date)
                                }
                                else {
                                    // jesli wybrany dzien jest dzisiaj lub później niż obecny dzień, wywala tylko złą godzinę
                                    try {
                                        const { data: availableHoursWrong, error } = await supabase
                                            .from('available_dates')
                                            .select('available_hours')
                                            .eq('date', new_date_moment.format('YYYY-MM-DD'))
                                            .single()
                                        if (error) throw error;

                                        if (availableHoursWrong) {
                                            if (availableHoursWrong.available_hours) {
                                                // filtrujemy niepoprawne terminy
                                                let available_hours_wrong = availableHoursWrong.available_hours.filter(hour => moment(`${orderDetails?.bookedDate?.date} ${hour}`).isAfter(moment().add(6, 'hours'), 'minutes'))
                                                // wysyłamy do bazy danych zaktualizowaną tabelę available_hours_wrong bez niepoprawnych terminów
                                                if (!(available_hours_wrong.length === 0)) {
                                                    await updateAvailableHours(new_date_moment.format('YYYY-MM-DD'), available_hours_wrong)
                                                }
                                                else {
                                                    removeEmptyAvailableDay(orderDetails?.bookedDate?.date)
                                                }
                                            }
                                        }

                                    } catch (error) {
                                    }
                                }
                            }
                        }
                    } else {
                        setStepCounter(stepCounter + 1)
                    }
                }
                else if (orderDetails?.bookedDate != null &&
                    orderDetails?.bookedDate?.date != null &&
                    orderDetails?.bookedDate?.time != null &&
                    orderDetails?.bookedDate?.time !== 'later' &&
                    orderDetails?.bookedDate?.date !== 'later') {
                    // krok 2 - sprawdza, czy termin nadal jest dostepny
                    if (await isDateAvailable(orderDetails?.bookedDate?.date, orderDetails?.bookedDate?.time)) {
                        setStepCounter(stepCounter + 1)
                    } else {
                        setComplete(true)
                        setError(t("page-purchase.section-new-order.schedule-taken-error"))
                        handleTimeout()
                    }
                } else {
                    //utwórz zamówienie

                    try {
                        const { data, error } = await supabase
                            .rpc('create_new_order', {
                                user_id: currentUser?.id,
                                package_name: name
                            })
                            .single()

                        if (error) throw error;
                        handleStateChange('orderId', data)
                        setStepCounter(stepCounter + 1)

                    } catch (error) {
                        setComplete(true)
                        setError(t("page-purchase.section-new-order.order-error"))
                        handleTimeout()
                        return { data: null, error }
                    }
                    //przejdź do kroku 3

                }
            }
            else {
                // krok 3 - płatność
                if (orderDetails?.bookedDate != null &&
                    orderDetails?.bookedDate?.date != null &&
                    orderDetails?.bookedDate?.time != null &&
                    orderDetails?.bookedDate?.time !== 'later' &&
                    orderDetails?.bookedDate?.date !== 'later') {

                    if (await isDateAvailable(orderDetails?.bookedDate?.date, orderDetails?.bookedDate?.time)) {
                    }
                    else {
                        setComplete(true)
                        setError(t("page-purchase.section-new-order.schedule-taken-error"))
                        handleTimeout()

                    }
                }
            }

        } else {
            navigate('/zakup')
        }

        setLoading(false)
    }



    return (
        <section className="section-new-order" id="section-new-order">
            <Container className='section-new-order-wrapper'>

                <Row className='header-container'>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Text
                            h1={t("page-purchase.section-new-order.header")}
                            h2={<>{name}<br />{duration ? <><span style={{ fontFamily: 'gilroy-semibold, sans-serif' }}>{t("page-purchase.section-new-order.sub-header-time")}</span> <span style={{ fontFamily: 'gilroy-semibold, sans-serif', background: '#00e0ff', backgroundClip: 'text', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>{duration} {t("page-purchase.section-new-order.sub-header-minutes")}</span> </> : ''}</>}
                            className={"subpage white center"}
                        />
                        {error && <Alert variant="danger">{error}</Alert>}
                    </Col>
                </Row>
                <Row className='step-view-container'>

                    {(loading || complete) ?
                        <Col xl={12} lg={12} md={12} sm={12} className="step-view-state center">
                            {
                                complete ? (
                                    error ? <h4>{error}</h4> :
                                        <h4>{t("page-purchase.section-new-order.payment-screen-message")}</h4>
                                ) :
                                    <div className="spinner-container">
                                        <Spinner animation="border" className='loading-spinner' />
                                    </div>
                            }
                        </Col> : (!userData?.first_name || !userData?.last_name || !userData?.phone_number || !userData?.phone_number_prefix) ?
                            navigate('/konto/dane')
                            :
                            <>
                                {
                                    allStepsOrder[stepCounter]
                                }
                            </>
                    }
                </Row>
                <Row className='bottom-nav-container'>
                    <Col xl={12} lg={12} md={12} sm={12} className='bottom-nav-wrapper'>
                        <Button className='button try medium' onClick={handleBack} disabled={loading ? loading : (complete && !error)}>
                            {stepCounter === 0 ? t("nav-buttons.back") : t("nav-buttons.return")}
                        </Button>
                        {stepCounter !== 2 ? (stepCounter === 0 ?
                            <Button className='button try medium' onClick={handleNext} disabled={
                                loading ? loading : nextDisabled
                            }>
                                {t("nav-buttons.next")}
                            </Button>

                            :
                            <Button className='button try medium' onClick={handleNext} disabled={loading ? loading : (nextDisabled || complete)}>
                                {stepCounter === 1 ? t("action-buttons.pay") : t("nav-buttons.next")}
                            </Button>) : ''
                        }
                    </Col>
                </Row>
            </Container >

        </section >
    )
}

export default SectionNewOrder
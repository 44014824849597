import React, { useState, useEffect } from 'react'
import './ViewSettings.css'
import SettingsViewFunctions from './settings-views/SettingsViewFunctions/SettingsViewFunctions'
import SettingsViewPassword from './settings-views/SettingsViewPassword/SettingsViewPassword'
import SettingsViewEmail from './settings-views/SettingsViewEmail/SettingsViewEmail'

const ViewSettings = () => {

    const [currentView, setCurrentView] = useState()
    let viewComponent;

    switch (currentView) {
        case 'functions':
            viewComponent = <SettingsViewFunctions setCurrentView={setCurrentView} />
            break;
        case 'acc-password-change':
            viewComponent = <SettingsViewPassword setCurrentView={setCurrentView} />
            break;
        case 'acc-email-change':
            viewComponent = <SettingsViewEmail setCurrentView={setCurrentView} />
            break;
        default:
            viewComponent = <SettingsViewFunctions setCurrentView={setCurrentView} />
            break;
    }

    return (
        <div className="view-settings-container">
            <div className="view-settings-wrapper">
                {viewComponent}
            </div>
        </div>
    )
}

export default ViewSettings
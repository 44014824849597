import React, { useState, useRef, useEffect } from 'react'
import "./MyServiceBar.css"
import Button from '../../Button/Button'
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment-timezone';
import { useDatabasePageContext } from '../../../context/DatabasePageContext';
import { useTranslation } from 'react-i18next';

const MyServiceBar = ({ serviceId = '', date = null, name = '-', status = 'prepare', date_changes_limit = '' }) => {

    const { t } = useTranslation('translation')
    const [showButton, setShowButton] = useState(false);
    const nodeRef = useRef(null);
    const { timezone } = useDatabasePageContext()

    useEffect(() => {
        if (date_changes_limit === 0) {
            setShowButton(false);
            return;
        } else if (!date || date === 'later' && date_changes_limit > 0) {
            setShowButton(true)
            return;
        }

        const timeInUserTimeZone = moment(date).tz(timezone);
        const currentTime = moment().tz(timezone);
    }, [date, date_changes_limit, timezone]);

    const renderDateTime = () => {
        if (!date || date === 'later') return (
            <>
                <p>{t("page-profile.my-service-bar.undefined")}</p>
            </>
        )
        const timeInUserTimeZone = moment(date).tz(timezone);
        const dateFormatted = timeInUserTimeZone.format('DD.MM.YYYY');
        const timeFormatted = timeInUserTimeZone.format('HH:mm');
        const timezoneOffset = timeInUserTimeZone.format('Z');
        const formattedOffset = 'UTC' + timezoneOffset.split(':').shift();
        const displayOffset = timezone !== 'Europe/Warsaw';

        return (
            <>
                <p>{dateFormatted}<br />{timeFormatted}<br />
                    {displayOffset && <span style={{ color: 'rgb(129, 128, 128)' }}>{formattedOffset}</span>}
                </p>
            </>
        );
    };

    return (
        <>
            <div className="component-my-service-bar">
                <div className="component-my-service-bar-inner">
                    <div className="date-of-service-container">
                        <div className="date-of-service-description">
                            <h3>{t("page-profile.my-service-bar.date-of-service")}</h3>
                        </div>
                        {
                            showButton ? "" :

                                <div className="date-of-service-wrapper">
                                    {
                                        renderDateTime()
                                    }
                                </div>
                        }

                        {
                            (status == 'prepare' || status == 'awaiting') && date_changes_limit !== 0 &&
                            <>
                                <CSSTransition
                                    in={showButton}
                                    nodeRef={nodeRef}
                                    timeout={300}
                                    classNames="schedule-button"
                                    unmountOnExit
                                    onEnter={() => setShowButton(true)}
                                    onExited={() => setShowButton(false)}>
                                    <div className='bar-collapse-menu-container' ref={nodeRef}>
                                        <Button href='/konto/moje-uslugi/wybor-terminu' id={serviceId} booked_date={date} from={'moje-uslugi'} name={name} date_changes_limit={date_changes_limit} className='try simple'>{(date != null) ? t("page-profile.my-service-bar.change-date") : t("page-profile.my-service-bar.schedule-date")}</Button>
                                    </div>
                                </CSSTransition>

                                <div
                                    className="vertical-dots-wrapper"
                                    style={showButton ? { filter: 'invert(55%) sepia(96%) saturate(957%) hue-rotate(148deg) brightness(107%) contrast(108%)' } : {}}
                                    onClick={() => setShowButton(!showButton)}>
                                </div>
                            </>
                        }
                    </div>
                    <div className="name-of-service-container">
                        <div className="name-of-service-description">
                            <h3>{t("page-profile.my-service-bar.service")} <span style={{ fontWeight: 'bold' }}>#{serviceId}</span>:</h3>
                        </div>
                        <div className="name-of-service-wrapper">
                            <p>{name}</p>
                        </div>

                    </div>
                    <div className="status-of-service-container">
                        <div className="status-of-service-description">
                            <h3>{t("page-profile.my-service-bar.status")}</h3>
                        </div>
                        <div className="status-of-service-wrapper">
                            {(() => {
                                switch (status) {
                                    case 'prepare':
                                        return <Button href='/konto/moje-uslugi/przygotowanie' id={serviceId} from={'moje-uslugi'} name={name} className='status-of-service prepare'>{t("page-profile.my-service-bar.prepare")}</Button>
                                    case 'awaiting':
                                        return <Button className='status-of-service awaiting'>{t("page-profile.my-service-bar.awaiting")}</Button>
                                    case 'start':
                                        return <Button href='/konto/moje-uslugi/przygotowanie/start' id={serviceId} from={'moje-uslugi'} name={name} className='status-of-service prepare'>{t("page-profile.my-service-bar.start")}</Button>
                                    case 'ready':
                                        return <Button className='status-of-service ready'>{t("page-profile.my-service-bar.ready")}</Button>
                                    case 'completed':
                                        return <Button className='status-of-service completed'>{t("page-profile.my-service-bar.completed")}</Button>
                                    default:
                                        return null
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyServiceBar
import React from 'react'
import Slider from "react-slick";
import "./SponsorSlider.css"
import { Image } from 'react-bootstrap';
import WKDZIK from '../../assets/img/partners-logos/wkdzik-vertical.svg'
import PROBOOTCAMPS from '../../assets/img/partners-logos/ProBootcamps-horizontal.webp'

const SponsorSlider = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100
    };

    return (
        <div className="component-sponsor-slider">
            <div className="component-sponsor-slider-inner">
                <Slider {...settings}>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={WKDZIK} alt='wkdzik logo' />
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={PROBOOTCAMPS} alt='probootcamps logo' style={{marginTop: '3px'}}/>
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={WKDZIK} alt='wkdzik logo' />
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={PROBOOTCAMPS} alt='probootcamps logo' style={{marginTop: '3px'}}/>
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={WKDZIK} alt='wkdzik logo' />
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={PROBOOTCAMPS} alt='probootcamps logo' style={{marginTop: '3px'}}/>
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={WKDZIK} alt='wkdzik logo' />
                        </div>
                    </div>
                    <div className="each-slide-effect">
                        <div className='image-wrapper'>
                            <Image src={PROBOOTCAMPS} alt='probootcamps logo' style={{marginTop: '3px'}}/>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>


    )
}

export default SponsorSlider
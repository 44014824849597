import React from 'react'
import './SettingsViewFunctions.css'
import SettingsFunctionBar from '../../settings-components/SettingsFunctionBar/SettingsFunctionBar'
import SettingsFunctionsTable from '../../settings-components/SettingsFunctionsTable/SettingsFunctionsTable'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SettingsViewFunctions = ({ setCurrentView }) => {

    const { t } = useTranslation('translation')
    const navigate = useNavigate()
    return (
        <div className="settings-view-functions-container">
            <div className="settings-view-functions-wrapper">
                <div className="pane-container">
                    <SettingsFunctionsTable title={t("page-profile.view-settings.settings-functions.account-settings")}>
                        <SettingsFunctionBar title={t("page-profile.view-settings.settings-functions.reset-password")} onClick={() => { navigate('/reset-hasla') }} />
                        <SettingsFunctionBar title={t("page-profile.view-settings.settings-functions.change-email")} onClick={() => { setCurrentView('acc-email-change') }} />
                    </SettingsFunctionsTable>
                </div>
            </div>
        </div>
    )
}

export default SettingsViewFunctions
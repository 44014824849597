import React, { useEffect, useRef, useState } from 'react'
import OrderStep from '../OrderStep/OrderStep'
import './OrderStepTwo.css'
import { Col, Form, InputGroup } from 'react-bootstrap'
import moment from 'moment';
import 'moment/locale/pl';
import successIcon from '../../../../../../assets/img/icons/success_green_48dp.svg'
import failureIcon from '../../../../../../assets/img/icons/X_red_48dp.svg'
import touchIcon from '../../../../../../assets/img/icons/touch_app_black_48dp.svg'
import Spinner from 'react-bootstrap/Spinner'
import { useDatabasePageContext } from '../../../../../../context/DatabasePageContext';
import { useTranslation } from 'react-i18next';

const OrderStepTwo = ({ setNextDisabled, orderDetails, handleStateChange, users_number, promo_price }) => {

    const [discountStatus, setDiscountStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(touchIcon)
    const [isFading, setIsFading] = useState(false);
    const codeRef = useRef()
    const { currency, exchangeRates, currencyDisplay } = useDatabasePageContext()
    const { t } = useTranslation('translation')

    const convertToTargetCurrency = (amountInPLN, targetCurrency) => {
        if (!exchangeRates || !exchangeRates[targetCurrency]) {
            // Błąd: nieznany kurs waluty lub kursy walut nie zostały jeszcze pobrane
            return 0;
        }
        return Math.round(amountInPLN / exchangeRates[targetCurrency]);
    }

    let formattedDate = null;

    useEffect(() => {
        if (discountStatus === null) {
            setIcon(touchIcon);
        } else if (discountStatus === true) {
            setIcon(successIcon);
        } else if (discountStatus === false) {
            setIcon(failureIcon);
        }

    }, [discountStatus])

    useEffect(() => {
        handleStateChange('confirmationChecked', null)
    }, [])

    useEffect(() => {

        if (orderDetails?.serviceClient.email_user1 && orderDetails?.bookedDate.date && orderDetails?.bookedDate.time && orderDetails?.confirmationChecked) {
            setNextDisabled(false)
        } else {
            setNextDisabled(true)
        }
    }, [orderDetails])

    if (orderDetails?.bookedDate.date !== 'later' & orderDetails?.bookedDate.time !== 'later') {
        formattedDate = moment(`${orderDetails?.bookedDate?.date} ${orderDetails?.bookedDate?.time}`).format('DD.MM.YYYY')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let code_value = codeRef?.current?.value

        if (!code_value) {
            setLoading(false)
            return;
        }

        if (code_value)

            try {
                const response =
                    // await fetch("http://localhost:4747/api/validate-discount-code", {
                    await fetch("https://gainfps.com/api/validate-discount-code", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            discountCode: codeRef?.current?.value,
                            productPrice: orderDetails?.productPrice
                        }),
                    })

                if (!response.ok) {
                    throw new Error("Network response error");
                }

                // Przekształć odpowiedź w JSON
                const data = await response.json();

                // Odczytaj wartość code_availability
                setDiscountStatus(data?.code_availability);

                if (data?.code_availability === true) {
                    handleStateChange('discountCode', {
                        code: data?.code,
                        discount_type: data?.discount_type,
                        discount_value: data?.discount_value,
                        promo_price: data?.promo_price
                    })
                } else {
                    handleStateChange('discountCode', null)
                }
            } catch (error) {
                setDiscountStatus(false)
                handleStateChange('discountCode', null)
            } finally {
                setLoading(false)
            }

    }

    const handleCodeChange = () => {
        setDiscountStatus(null)
    }

    return (
        <>
            <Col xl={12} lg={12} md={12} sm={12}>
                <OrderStep className='step-two'>
                    <h1>{t("page-purchase.order-step-two.final-terms-header")}</h1>
                    <h3>{t("page-purchase.order-step-two.verify-info")}</h3>
                    <p>{t("page-purchase.order-step-two.service")} <span style={{ color: 'rgb(0,224,255)' }}>{orderDetails?.productName}</span> </p>
                    {
                        (orderDetails.bookedDate?.date !== 'later' && orderDetails.bookedDate?.time !== 'later') ?
                            <p>{t("page-purchase.order-step-two.appointment")} <span style={{ color: 'rgb(0,224,255)' }}>{formattedDate} {orderDetails.bookedDate?.time}</span></p>
                            :
                            <p>{t("page-purchase.order-step-two.appointment")} <span style={{ color: 'rgb(0,224,255)' }}>{t("page-purchase.order-step-two.appointment-to-be-scheduled")}</span></p>
                    }
                    {
                        users_number > 1 ?
                            <p>{t("page-purchase.order-step-two.purchase-for-users")} <span style={{ color: 'rgb(0,224,255)' }}>{orderDetails?.serviceClient.email_user1} <span style={{ color: 'rgb(255,255,255)' }}>& </span>{orderDetails?.serviceClient.email_user2}</span></p>
                            :
                            <p>{t("page-purchase.order-step-two.purchase-for-user")} <span style={{ color: 'rgb(0,224,255)' }}>{orderDetails?.serviceClient.email_user1}</span></p>
                    }

                    <p>{t("page-purchase.order-step-two.final-price")} <span style={discountStatus === true ? { color: 'rgb(0,224,255)' } : { color: 'rgb(0,224,255)' }}>{orderDetails?.discountCode?.promo_price ? `${convertToTargetCurrency(orderDetails?.discountCode?.promo_price, currency)} ${currencyDisplay}` : `${convertToTargetCurrency(orderDetails?.productPrice, currency)} ${currencyDisplay}`}</span></p>
                    <div className="accept-and-discount-container">
                        <div className="choose-option-container">
                            <div className="options-container">
                                <div className="option-wrapper">
                                    <input type="checkbox" name="select-accept-contract-withdrawal" id="select-accept-contract-withdrawal" onChange={(event) => {
                                        event.target.checked ? handleStateChange('confirmationChecked', true) : handleStateChange('confirmationChecked', null)
                                    }} />
                                    <label htmlFor="select-accept-contract-withdrawal" className="option option-3">
                                        <div className="dot-wrapper">
                                            <div className="dot"></div>
                                        </div>
                                        <span>{t("page-purchase.order-step-two.order-and-pay-checkbox")}</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {
                            (orderDetails?.type === 'normal' && !promo_price) &&
                            <Form className='form-discount-code' noValidate onSubmit={handleSubmit}>
                                <Form.Group style={{ height: '100%' }}>
                                    <InputGroup>
                                        <Form.Control placeholder={t("page-purchase.order-step-two.discount-code-placeholder")} maxLength={10} ref={codeRef} onChange={handleCodeChange}></Form.Control>
                                        <button className='button' disabled={discountStatus !== null}>
                                            {
                                                loading ?
                                                    <Spinner className='spinner small' animation='border' />
                                                    :
                                                    <img src={icon} alt="discount code status" className={isFading ? "fade-out" : ""} />
                                            }
                                        </button>
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                        }
                    </div>
                    <h5>{t("page-purchase.order-step-two.law-adnotation")}</h5>
                </OrderStep>
            </Col >
        </>
    )
}

export default OrderStepTwo
import supabase from "../../../../data/supabase";
import sendMail from "../../../../utils/sendMail";
import moment from "moment";
import 'moment/locale/pl'

async function adminAddService(user_email, service_name, service_status, booked_date, booked_time) {
    try {
        const { data, error } = await supabase
            .rpc('admin_add_service',
                {
                    input_email: user_email,
                    input_service_name: service_name,
                    input_service_status: service_status,
                    ...(booked_date ? { input_booked_date: booked_date } : {}),
                    ...(booked_time ? { input_booked_time: booked_time } : {})
                })

        if (error) throw new Error('Wystąpił błąd podczas dodawania usługi.')

        let formattedDate = moment(`${booked_date} ${booked_time}`).format('DD.MM.YYYY, dddd')
        await sendMail({ author: 'noreply', receiver: user_email, subject: 'Pomyślnie zarezerwowano usługę', client_details_email: user_email, service_name: service_name, booked_date: formattedDate, booked_time: booked_time, template: 'u_svcBookedTemplate' })

        return { data, error }

    } catch (error) {
        return { data: null, error }
    }
}

export default adminAddService
import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import MyServiceBar from '../MyServiceBar/MyServiceBar';
import './ViewMyServices.css';
import { useDatabaseUserContext } from '../../../context/DatabaseUserContext';
import { useAuth } from '../../../context/AuthContext';
import Button from '../../Button/Button';
import MyServiceBarSceletor from '../MyServiceBarSceletor/MyServiceBarSceletor';
import { useTranslation } from 'react-i18next';

const ViewMyServices = () => {

    const { currentUser } = useAuth()
    const { userData, bookedServices, useReadBookedServices, useReadUserData } = useDatabaseUserContext()
    const [error, setError] = useState('')
    const { t } = useTranslation('translation')
    useReadUserData(currentUser?.id)
    useReadBookedServices(currentUser?.email)

    useEffect(() => {
        if (userData) {
            if (!userData?.first_name || !userData?.last_name || !userData?.phone_number || !userData?.phone_number_prefix) {
                setError(t("page-profile.view-my-services.fill-userdata-error"))
            } else {
                setError('')
            }
        }
    }, [userData]
    );


    return (
        <>
            <div className="view-my-services-container">
                <div className="view-my-services-wrapper">
                    {error && <Alert variant="danger">{error}</Alert>}
                    {
                        error ? '' :
                            bookedServices ? (bookedServices.length !== 0 ?
                                bookedServices.map((service) =>
                                    <MyServiceBar serviceId={service?.id} key={service?.id} date={service?.booked_date} name={service?.service_name} status={service?.status} date_changes_limit={service?.date_changes_limit} />
                                )
                                :
                                <>
                                    <h2>{t("page-profile.view-my-services.no-services-error")}</h2>
                                    <Button className='try medium' href='/zakup'>{t("page-profile.view-my-services.buy-now")}</Button>
                                </>) : <><MyServiceBarSceletor /><MyServiceBarSceletor /></>
                    }
                </div>
            </div>
        </>
    )
}

export default ViewMyServices
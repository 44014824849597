import React from "react"
import './Polaroid.css'
import { Image } from 'react-bootstrap'

const Polaroid = ({ icon, h4, p }) => {
    return (
        <>
            <div className="component-polaroid">
                <div className="component-polaroid-inner">
                    <div className="polaroid-icon-container">
                        <div className="polaroid-icon-wrapper">
                            <Image
                                src={icon}
                                alt="polaroid icon"/>
                        </div>
                    </div>
                    <div className="polaroid-title-container">
                        <h4>
                            {h4}
                        </h4>
                    </div>
                    <div className="polaroid-text-container">
                        <p>
                            {p}
                        </p>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Polaroid
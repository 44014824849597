import React, { useState, useEffect } from 'react'
import './DownloadGMButton.css'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'
import { gmDownloadedEvent } from '../../data/pixel-events/gmDownloadedEvent'
import { useAuth } from '../../context/AuthContext'
import { hashData } from '../../utils/hashData'
import supabase from '../../data/supabase'

const DownloadGMButton = () => {
    const [loading, setLoading] = useState(true)
    const [downloadLink, setDownloadLink] = useState('')
    const { t, i18n } = useTranslation()
    const isMobile = window.innerWidth < 768
    const { currentUser } = useAuth()
   

    useEffect(() => {
        const fetchVersion = async () => {
            const { data, error } = await supabase
                .from('gaming_mode_version')
                .select('link')
                .order('id', { ascending: false })
                .limit(1)
                .single()
            if (!error && data) {
                setDownloadLink(data.link)
            }
            setLoading(false)
        }

        fetchVersion()
    }, [])
    return (
        <div className="button-container">
            <Button id={"download-gm-button"} disabled={isMobile} href={downloadLink} download={"GamingMode.exe"} className='try' onClick={() => { gmDownloadedEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id)) }} >
                {loading ? t("page-gamingmode.section-gm-main.loading") : t("page-gamingmode.section-gm-main.download-button")}
            </Button>
        </div>
    )
}

export default DownloadGMButton
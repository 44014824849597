import React from 'react'
import "./Button.css"
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
const Button = ({ id, from, name, price, promo_price, href, className = '', children, type, local,
    onClick, disabled, hidden = false, users_number, booked_time, booked_date, support_days, service_name, date_changes_limit, duration, value, download }) => {

    return (
        href ? <>
            {local ? <HashLink to={href} className={`button ${className} ${disabled ? 'disabled' : ''}`}>
                {children}
            </HashLink> :
                <Link hidden={hidden} to={href} className={`button ${className} ${disabled ? 'disabled' : ''}`} onClick={onClick} state={{
                    id: id, name: name, price: price, from: from, promo_price: promo_price, users_number: users_number,
                    booked_date: booked_date, booked_time: booked_time, support_days: support_days, service_name: service_name,
                    date_changes_limit: date_changes_limit, duration, type: type, download: download
                }}>
                    {children}
                </Link>
            }
        </>
            :
            <>
                <button className={`button ${className}`} value={value} type={type} onClick={onClick} disabled={disabled} hidden={hidden}>
                    {children}
                </button>
            </>
    )
}

export default Button;
import allProductsPL from "../Purchase/allProductsPL";

export const allProductsPLBoxes = [
    {
        id: allProductsPL[0].id,
        name: allProductsPL[0].name,
        price: allProductsPL[0].price,
        description: allProductsPL[0].description,
        short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>Rekomendowany</span> dla amatorów</>,
        promo_price: allProductsPL[0].promo_price,
        duration: 60,
        time_description: '60 minut',
        mode: 'zdalny',
        benefits_list: allProductsPL[0].benefits_list,
        support_days: allProductsPL[0].support_days,
        service_name: allProductsPL[0].service_name,
        users_number: allProductsPL[0].users_number,
        status: allProductsPL[0].status,
        type: allProductsPL[0].type,
        advantages_array: [
            "Aplikacja Gaming Mode 1.7",
            "Więcej klatek na sekundę (FPS)",
            "Zredukowany Input Lag",
            "Maksymalnie płynny ekran",
            "Ograniczone spadki FPS",
            "Mniejszy ping",
            "Szybsze działanie programów",
            "Odświeżony i wydajniejszy system Windows",
            "Opieka techniczna przez 30 dni",
        ]
    },
    {
        id: allProductsPL[1].id,
        name: allProductsPL[1].name,
        price: allProductsPL[1].price,
        description: allProductsPL[1].description,
        short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>Rekomendowany</span> dla profesjonalistów</>,
        promo_price: allProductsPL[1].promo_price,
        duration: 180,
        time_description: '180 minut',
        mode: 'zdalny',
        benefits_list: allProductsPL[1].benefits_list,
        support_days: allProductsPL[1].support_days,
        service_name: allProductsPL[1].service_name,
        users_number: allProductsPL[1].users_number,
        status: allProductsPL[1].status,
        type: allProductsPL[1].type,
        advantages_array: [
            "Aplikacja Gaming Mode 1.7",
            "Maksimum klatek na sekundę (FPS)",
            "Zerowy Input Lag",
            "Responsywność 1:1",
            "Wyeliminowany stuttering",
            "Minimalny ping",
            "Diagnostyka oraz poprawa pracy CPU, GPU i RAM",
            "Ultraszybkie działanie programów",
            "Odświeżony i wydajniejszy system Windows",
            "Opieka techniczna przez 60 dni"
        ]
    },
    {
        id: allProductsPL[2].id,
        name: allProductsPL[2].name,
        price: allProductsPL[2].price,
        description: allProductsPL[2].description,
        short_desc: <><span style={{ color: 'rgb(0,224,255)' }}>Rekomendowany</span> dla entuzjastów</>,
        duration: 420,
        time_description: '7 godzin',
        mode: 'dojazd do klienta / odbiór komputera',
        promo_price: allProductsPL[2].promo_price,
        button: allProductsPL[2].button,
        benefits_list: allProductsPL[2].benefits_list,
        support_days: allProductsPL[2].support_days,
        service_name: allProductsPL[2].service_name,
        users_number: allProductsPL[2].users_number,
        status: allProductsPL[2].status,
        type: allProductsPL[2].type,
        advantages_array: [
            <>Wszystko, co zawiera pakiet <span style={{ color: 'rgb(0,224,255)' }}>PRO</span></>,
            "Najwyższa skuteczność",
            "Czyszczenie komputera od wewnątrz",
            "Wymiana past termoprzewodzących (CPU i GPU)",
            "Overclocking CPU, GPU i RAM",
            "Diagnostyka i wydłużone testy obciążeniowe",
            "Przygotowanie stanowiska pod streaming na prośbę klienta",
            "Wymiana części na prośbę klienta",
            "Złożenie komputera na prośbę klienta",
            "Opieka techniczna przez 90 dni"
        ]
    }
]

export default allProductsPLBoxes;
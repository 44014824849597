import React from 'react'
import Footer from '../../components/Footer/Footer'
import TopNavigation from '../../components/TopNavigation/TopNavigation'
import { Container, Row, Col } from 'react-bootstrap'
import Text from '../../components/Text/Text'
import "./Privacy.css"
import StatuteParagraph from '../../components/StatuteParagraph/StatuteParagraph'
import { useTranslation } from 'react-i18next'
import privacyPoliticsPLTranslations from '../../locales/pl/privacy-politics.json'
import privacyPoliticsENGTranslations from '../../locales/en/privacy-politics.json'
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel'

const Privacy = () => {

    const { t, i18n } = useTranslation('privacy_politics')
    const translatedPLSections = privacyPoliticsPLTranslations['page-privacy'].sections;
    const translatedENGSections = privacyPoliticsENGTranslations['page-privacy'].sections;
    return (
        <>
            <TopNavigation />
            <main className="page privacy">
                <SocialMediaPanel />
                <section className="section-privacy">
                    <Container>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>

                                <Text
                                    h1={t("page-privacy.header")}
                                    h2={t("page-privacy.sub-header")}
                                    className={"subpage center"}
                                />

                                <StatuteParagraph>
                                    {[i18n.language === 'pl' ? translatedPLSections : translatedENGSections].map((sections, index) => (
                                        sections.map((section, sectionIndex) => (
                                            <React.Fragment key={sectionIndex}>
                                                <h1>{t(`${section.title}`)}</h1>
                                                {Array.isArray(section.content)
                                                    ? section.content.map((paragraph, paragraphIndex) => (
                                                        <p key={paragraphIndex}>{t(`${paragraph}`)}</p>
                                                    ))
                                                    : <p>{t(`${section.content}`)}</p>}
                                            </React.Fragment>
                                        ))
                                    ))}
                                </StatuteParagraph>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default Privacy
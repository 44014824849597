import React from 'react';
import './PrepareStep.css';

const PrepareStep = ({ children }) => {
    return (
        <div className="component-prepare-step">
            <div className="component-prepare-step-inner">
                {children}
            </div>
        </div>
    )
}

export default PrepareStep
import React, { useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import './ViewSvcScheduling.css'
import SchedulingCalendar from './SchedulingCalendar/SchedulingCalendar';
import Button from '../../Button/Button';
import PrepareStep from '../PrepareStep/PrepareStep';
import moment from 'moment';
import 'moment/locale/pl';
import { useTranslation } from 'react-i18next';

const ViewSvcScheduling = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState('')
    const location = useLocation()
    const { id, booked_date, date_changes_limit } = location.state
    const [secondsRemaining, setSecondsRemaining] = useState()
    const [schedule, setSchedule] = useState({
        booked_date: null,
        booked_time: null
    })
    const navigate = useNavigate()

    const { t } = useTranslation('translation')
    let date_moment = booked_date ? moment(booked_date) : null;
    ///////////////////////////////////////////

    function handleBack() {
        navigate('/konto/moje-uslugi')
    }
    ///////////////////////////////////////////

    return (
        <div className="view-svc-scheduling-container">
            <div className="view-svc-scheduling-wrapper">
                <div className="header-container">
                    <div className="counter-wrapper">
                        <h4>{t("page-profile.view-svc-scheduling.header")}</h4>
                        {error ? <Alert variant="danger">{error}</Alert> : <h5>{t("page-profile.view-svc-scheduling.header-bottom")} #{id}</h5>}
                    </div>
                </div>
                <div className={(loading || complete || (date_changes_limit === 0 || (booked_date && date_moment.isBefore(moment().add(16, 'hours'), 'minutes')))) ? "step-view-container center" : "step-view-container"}>
                    {
                        complete ? (
                            error ? <div className="step-view-text-container">
                                <h4>{t("errors.server-error-occured")}</h4>
                                <p>{t("messages.returning-in")} {secondsRemaining}...</p>
                                <Button className='button try small-med' onClick={() => { handleBack() }}>{t("nav-buttons.return")}</Button>
                            </div> :

                                <div className="step-view-text-container">
                                    <h4>{t("page-profile.view-svc-scheduling.schedule-success")}</h4>
                                    <p>{t("messages.returning-in")} {secondsRemaining}...</p>
                                    <Button className='button try small-med' onClick={() => { handleBack() }}>{t("nav-buttons.return")}</Button>
                                </div>
                        ) : (
                            (date_changes_limit === 0 || (booked_date && date_moment.isBefore(moment().add(16, 'hours'), 'minutes'))) ?
                                <div className="step-view-text-container">
                                    <h4>{t("page-profile.view-svc-scheduling.schedule-unavailable")}</h4>
                                    <Button className='button try small-med' onClick={() => { handleBack() }}>{t("nav-buttons.return")}</Button>
                                </div> :
                                (
                                    loading ?
                                        <div className="spinner-container">
                                            <Spinner animation="border" className='loading-spinner' />
                                        </div>
                                        :
                                        <PrepareStep>
                                            {date_changes_limit !== 0
                                                ?
                                                <SchedulingCalendar schedule={schedule} setSchedule={setSchedule} setLoading={setLoading} loading={loading} setError={setError} setComplete={setComplete} setSecondsRemaining={setSecondsRemaining} service_id={id} prev_booked_date={booked_date ? date_moment.format('YYYY-MM-DD') : null} prev_booked_time={booked_date ? date_moment.format('HH:mm') : null} date_changes_limit={date_changes_limit} />
                                                : (
                                                    <>
                                                        <p>{t("page-profile.view-svc-scheduling.schedule-one-free")}</p>
                                                        {setError(t("page-profile.view-svc-scheduling.schedule-one-free"))}
                                                    </>
                                                )}

                                        </PrepareStep>
                                )
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default ViewSvcScheduling
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Button from "../../../../components/Button/Button"
import Text from "../../../../components/Text/Text"
import './SectionContent.css'
import { useTranslation } from "react-i18next"
const SectionContent = () => {

    const { t } = useTranslation('translation')
    return (
        <>
            <section id="section-content" className="section-content">
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <Text
                                h1={t("page-proserwis.section-content.header")}
                                className={"subpage center"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>

                            <div className="proserwis-navigators-container">
                                <Button href='#team' local className='try black'>{t("page-proserwis.section-content.first-navigator")}</Button>
                                <Button href='#genesis' local className='try black'>{t("page-proserwis.section-content.second-navigator")}</Button>
                                <Button href='#methods' local className='try black'>{t("page-proserwis.section-content.third-navigator")}</Button>
                                <Button href='#security' local className='try black'>{t("page-proserwis.section-content.fourth-navigator")}</Button>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )

}


export default SectionContent
import React, { useState, useRef } from 'react'
import { Link } from "react-router-dom"
import { Form, Alert, Col, Container, Row, InputGroup } from 'react-bootstrap'
import './LoginForm.css'
import { useAuth } from '../../../../context/AuthContext'
import Button from '../../../../components/Button/Button'
import Spinner from 'react-bootstrap/Spinner'
import visibleOffIcon from '../../../../assets/img/icons/visibility_off_black_36dp.svg'
import visibleOnIcon from '../../../../assets/img/icons/visibility_black_36dp.svg'
import { Turnstile } from '@marsidev/react-turnstile'
import { Trans, useTranslation } from 'react-i18next'

export default function LoginForm() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const { login, resetPassword } = useAuth()
    const [showPassword, setShowPassword] = useState(false);
    const [validEmail, setValidEmail] = useState(true)
    const [validPassword, setValidPassword] = useState(true)
    const [captchaToken, setCaptchaToken] = useState()
    const { t } = useTranslation('translation');

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = () => {
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        if (emailRegex.test(email)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }

        if (!password) {
            setValidPassword(false)
        } else {
            setValidPassword(true)
        }
    };

    async function handleSubmit(e) {
        e.preventDefault()

        if (!captchaToken) {
            return setError(t("forms.error.captcha-error"))
        }
        let emailClear = (emailRef.current.value).trim()

        try {
            setError('')
            setLoading(true)
            const err = await login(emailClear, passwordRef.current.value, captchaToken)
            if (err) {
                // login() returns error if there is an error
                // or returns nothing (undefined) if there is no error
                let msg = ''
                switch (err.message) {
                    case 'Invalid login credentials':
                        msg = t("page-login.section-login-form.invalid-login-credentials")
                        break;
                    default:
                        msg = t("page-login.section-login-form.login-failed")
                        break;
                }
                setError(msg)
            }

        } catch (error) {
            setError(t("page-login.section-login-form.login-failed"))
        }

        setLoading(false)
    }

    return (

        <>
            <section id='section-login-form' className='section-login-form'>
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} className='col'>
                            <div className="form-container">
                                <div className="form-wrapper">
                                    {
                                        loading ?
                                            <div className="spinner-container">
                                                <Spinner animation="border" className='loading-spinner' />
                                            </div>
                                            :
                                            <>
                                                <Form onSubmit={handleSubmit} className="form login-form" noValidate>
                                                    <h1>{t("page-login.section-login-form.header")}</h1>
                                                    {error && <Alert variant="danger">{error}</Alert>}
                                                    <Form.Group className="form-group" id="login-form-email">
                                                        <Form.Label>{t("forms.label.email")}</Form.Label>
                                                        <Form.Control type="email" ref={emailRef} required placeholder={t("forms.placeholder.email")} maxLength={45} onChange={handleEmailChange} isInvalid={!validEmail} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.wrong-email")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="form-group" id="login-form-password">

                                                        <Form.Label>{t("forms.label.password")}</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control type={showPassword ? 'text' : "password"} ref={passwordRef} required placeholder={t("forms.placeholder.enter-password")} maxLength={45} isInvalid={!validPassword} onChange={handleEmailChange} />
                                                            <span className="form-control password-toggle" style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} onClick={togglePasswordVisibility}>
                                                                {showPassword ? <img src={visibleOffIcon} alt='visible icon' /> : <img src={visibleOnIcon} alt='visible icon' />}
                                                            </span>
                                                            <Form.Control.Feedback type="invalid">
                                                                {t("forms.feedback.wrong-password")}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>

                                                        <p><Link to="/reset-hasla"><span className="highlighted">{t("page-login.section-login-form.forgot-password")}</span></Link></p>
                                                    </Form.Group>
                                                    <div className="turnstile-container">
                                                        <Turnstile
                                                            siteKey='0x4AAAAAAAJx1CAT2qk2tue5'
                                                            onSuccess={(token) => {
                                                                setCaptchaToken(token)
                                                                setError('')
                                                            }}
                                                            onError={(token) => {
                                                                setCaptchaToken(null)
                                                                setError(t("forms.error.captcha-error"))
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <Button type='submit' disabled={loading} className="try hover2">
                                                        {t("page-login.section-login-form.login-button")}
                                                    </Button>

                                                </Form>
                                            </>
                                    }

                                </div>

                            </div>
                            <div className="need-account-text-wrapper" disabled={loading}>1
                                <p><Trans i18nKey={"page-login.section-login-form.need-account-text"}>Nie posiadasz konta? <Link to="/rejestracja"><span className="highlighted">Zarejestruj się</span></Link></Trans></p>
                            </div>
                        </Col>
                    </Row>
                </Container >

            </section>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Button from '../../../../components/Button/Button'
import { Trans, useTranslation } from 'react-i18next'
import Text from '../../../../components/Text/Text'
import './SectionMain.css'
import GamingModeImage from '../../../../assets/img/gaming-mode/gaming-mode-menu.webp'
import DownloadGMButton from '../../../../components/DownloadGMButton/DownloadGMButton'
import supabase from '../../../../data/supabase'

const SectionMain = () => {
    const [version, setVersion] = useState('')
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation('translation');
    const isMobile = window.innerWidth < 768
    useEffect(() => {
        const fetchVersion = async () => {
            const { data, error } = await supabase
                .from('gaming_mode_version')
                .select('version')
                .order('id', { ascending: false })
                .limit(1)
                .single()
            if (!error && data) {
                setVersion(data.version)
            }
            setLoading(false)
        }

        fetchVersion()
    }, [])

    return (
        <section id="gm-main" className='gm-main'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Text
                            h1={t("gaming-mode")}
                            h2={loading ? t("page-gamingmode.section-gm-main.loading"):`v${version} BETA`}
                            className={"subpage center"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                        <Text
                            h3={<Trans i18nKey="page-gamingmode.section-gm-main.header" />}
                            p={<Trans i18nKey="page-gamingmode.section-gm-main.header-bottom" />}
                        />
                        <div className="download-buttons-container">
                            <DownloadGMButton />
                            <Button href="https://discord.gg/DHUsvBysT9" className='try black'>{t("page-gamingmode.section-gm-main.join-discord-button")}</Button>
                        </div>
                        {isMobile ? <span className="only-pc-text">PC (Windows)</span> : <span className="only-pc-text pc">{loading ? t("page-gamingmode.section-gm-main.loading"):`v${version} BETA`}</span>}
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={0} className='gm-image-container' style={{ textAlign: "right" }}>
                        <Image
                            src={GamingModeImage}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SectionMain
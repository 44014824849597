import React from 'react'
import "./Text.css"
const Text = ({ h5, h4, h3, h2, h1, p, className, id, style }) => {

    return (
        <>
            <div className={"component-text " + className} id={id} style={style}>
                <div className="component-text-inner">
                    {h1 ? <h1>{h1}</h1> : ""}
                    {h2 ? <h2>{h2}</h2> : ""}
                    {h4 ? <h4>{h4}</h4> : ""}
                    {h3 ? <h3>{h3}</h3> : ""}
                    {h5 ? <h5>{h5}</h5> : ""}
                    {p ? <p>{p}</p> : ""}
                </div>
            </div>
        </>
    )
}

export default Text;
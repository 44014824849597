import React from 'react'
import './TimeHolder.css'

const TimeHolder = ({ time, index, chooseTime, clickedTimeIndex }) => {

    return (
        <div className={`component-time-holder ${clickedTimeIndex === index ? 'clicked' : ''}`} onClick={() => {
            chooseTime(index)
        }}>
            <div className="component-time-holder-inner">
                <p> {time}</p>

            </div>
        </div>
    )
}

export default TimeHolder
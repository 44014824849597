import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'
import Text from '../../../../components/Text/Text'
import './SectionInstruction.css'
import SimpleContainer from '../../../../components/SimpleContainer/SimpleContainer'

const SectionInstruction = () => {
    const { t } = useTranslation('translation')
    return (
        <section id='gm-instruction' className='gm-instruction'>
            <Container>
                <Row>
                    <Col xl={6} lg={6} md={12} sm={12} style={{paddingTop: "0.1rem"}}>
                        <SimpleContainer className={"high justifyStart"}>
                            <Text
                                className={'small'}
                                style={{ marginTop: "0" }}
                                h4={t("page-gamingmode.section-gm-instruction.header")}
                                p={
                                    <>
                                        <span style={{ fontWeight: "bold" }}><Trans i18nKey={"page-gamingmode.section-gm-instruction.text"} /></span>
                                    </>
                                }
                            />
                        </SimpleContainer>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} style={{paddingTop: "0.1rem"}} className='gm-modes-tab'>
                        <SimpleContainer className={"high justifyStart"}>
                            <Text
                                className={'small'}
                                h4={t("page-gamingmode.section-gm-modes.header")}
                                style={{ marginTop: "0" }}
                                p={
                                    <>
                                        <span style={{ fontWeight: "bold" }}><Trans i18nKey={"page-gamingmode.section-gm-modes.text"} /><br /><br /></span>
                                        <span><Trans i18nKey={"page-gamingmode.section-gm-modes.list"} /></span>
                                    </>
                                }
                            />
                        </SimpleContainer>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default SectionInstruction
import React, { useState, useEffect } from 'react';
import './SocialMediaPanel.scss';
import instagramIcon from '../../assets/img/socials/instagram-icon.png';
import arrowRight from '../../assets/img/icons/arrow_right_white_48dp.svg';
import { useTranslation } from 'react-i18next';
import { instagramButtonClickedEvent } from '../../data/pixel-events/instagramButtonClickedEvent';
import { hashData } from '../../utils/hashData';
import { useAuth } from '../../context/AuthContext';

function SocialMediaPanel() {
    const [isOpen, setIsOpen] = useState(false);
    const [hasAutoOpened, setHasAutoOpened] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 991);
    const { t, i18n } = useTranslation('translation');
    const { currentUser } = useAuth()

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 991);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isDesktop) {
            const handleScroll = () => {
                if (window.scrollY > 600 && !hasAutoOpened) {
                    setIsOpen(true);
                    setHasAutoOpened(true);
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [hasAutoOpened, isDesktop]);

    useEffect(() => {
        let timer;
        if (isOpen && hasAutoOpened && isDesktop) {
            timer = setTimeout(() => {
                setIsOpen(false);
            }, 5000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isOpen, hasAutoOpened, isDesktop]);

    if (!isDesktop) return null;

    return (
        <div className={`social-panel ${isOpen ? "open" : ""}`}>
            <div className='socials-container'>
                <div className='text-container'>
                    <p>{t("components.social-panel.text-upper")}</p>
                    <p style={{ color: "#e0447c" }}>{t("components.social-panel.text-bottom")}</p>
                </div>
                <div className="icons">
                    <a href="https://www.instagram.com/gainfps" target="_blank" rel="noopener noreferrer" onClick={() => { instagramButtonClickedEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id)) }} id='ig-panel-button'>
                        <img src={instagramIcon} alt="Instagram" className="icon" id='ig-panel-button-img' />
                    </a>
                </div>
            </div>
            <button className={`toggle-button ${isOpen ? "open" : ""}`} onClick={togglePanel}>
                <img src={arrowRight} alt="Arrow" />
            </button>
        </div>
    );
}

export default SocialMediaPanel;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsInEng from '../locales/en/translation.json';
import translationsInPolish from '../locales/pl/translation.json';
import privacyPoliticsInEng from '../locales/en/privacy-politics.json';
import privacyPoliticsInPolish from '../locales/pl/privacy-politics.json';
import statuteInEng from '../locales/en/statute.json'
import statuteInPolish from '../locales/pl/statute.json'

// the translations
const resources = {
    en: {
        translation: translationsInEng,
        privacy_politics: privacyPoliticsInEng,
        statute: statuteInEng
    },
    pl: {
        translation: translationsInPolish,
        privacy_politics: privacyPoliticsInPolish,
        statute: statuteInPolish
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources, // resources are important to load translations for the languages.
        lng: localStorage.getItem("lang"), // It acts as default language. When the site loads, content is shown in this language.  
        debug: false, // Turn on to see debug in console
        fallbackLng: "pl", // use de if selected language is not available
        interpolation: {
            escapeValue: false
        },
        ns: "translation", // namespaces help to divide huge translations into multiple small files.
        defaultNS: "translation",
    });

export default i18n;
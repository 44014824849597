import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import TeamImage from "../../../../assets/img/Teamproserwisv2-min-bckp.webp"
import Showcase from '../../../../components/Showcase/Showcase';
import "./SectionTeam.css"
import Text from '../../../../components/Text/Text';
import { useTranslation } from 'react-i18next';

const SectionTeam = () => {

    const { t } = useTranslation('translation')
    return (
        <>
            <section className="section-team" id='team'>
                <Container>
                    <Row>
                        <Col xl={2} lg={2} md={1} sm={0}>
                        </Col>
                        <Col xl={8} lg={8} md={10} sm={12}>
                            <Text
                                h5={t("page-proserwis.section-team.header")}
                                p={<span>
                                    {t("page-proserwis.section-team.header-sub-text")}
                                </span>}
                                className='small center'
                            />
                        </Col>
                        <Col xl={2} lg={2} md={1} sm={0}>
                        </Col>
                    </Row>
                    <Row className='team-members'>
                        <Col xl={3} lg={3} md={6} sm={12} className='member-wrapper'>
                            <Showcase
                                header={t("page-proserwis.section-team.showcase-one.header")}
                                text={t("page-proserwis.section-team.showcase-one.text")}
                            />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} className='team-img-container'>
                            <Image
                                src={TeamImage}
                                fluid
                                height={'auto'}>

                            </Image>
                        </Col>
                        <Col xl={3} lg={3} md={6} sm={12} className='member-wrapper'>
                            <Showcase
                                header={t("page-proserwis.section-team.showcase-two.header")}
                                text={t("page-proserwis.section-team.showcase-two.text")}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default SectionTeam;
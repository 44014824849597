import React, { useState, useEffect } from 'react';
import './ScrollToTopArrow.css';

const ScrollToTopArrow = () => {

    const [showTopBtn, setShowTopBtn] = useState(false);

    const handleScroll = () => {
        setShowTopBtn(window.scrollY > 400);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Funkcja czyszcząca, która usunie nasłuchiwacz
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="top-to-btm">
            <div className={`icon-position icon-style ${showTopBtn ? 'visible' : ''}`} onClick={goToTop}><span>&#8593;</span></div>
        </div>
    );
};
export default ScrollToTopArrow;
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HidingImage from "../../../../components/HidingImage/HidingImage"
import "./SectionStarsAndTeams.css"
import starsImage from "../../../../assets/img/gwiazdyv3-min.webp"
import teamsImage from "../../../../assets/img/teamsv3-min.webp"
import Text from "../../../../components/Text/Text";
import { TypeAnimation } from 'react-type-animation';
import { Trans, useTranslation } from 'react-i18next'

const SectionStarsAndTeams = () => {

    const { t } = useTranslation('translation');

    return (
        <>
            <section id="section-stars-and-teams" className="section-stars-and-teams">
                <Container>
                    <Row>
                        <Text
                            className="center"
                            h4={t('page-homepage.section-stars-and-teams.header')}
                            h3={t('page-homepage.section-stars-and-teams.header-bottom')}
                            p={<TypeAnimation
                                sequence={[
                                    t('page-homepage.section-stars-and-teams.players') + ".",
                                    2000,
                                    t('page-homepage.section-stars-and-teams.streamers') + ".",
                                    2000,
                                    t('page-homepage.section-stars-and-teams.video-editors') + ".",
                                    2000,
                                    t('page-homepage.section-stars-and-teams.influencers') + ".",
                                    2000,
                                    t('page-homepage.section-stars-and-teams.programmers') + ".",
                                    2000,
                                    t('page-homepage.section-stars-and-teams.graphic-designers') + ".",
                                    2000,
                                    t('page-homepage.section-stars-and-teams.analysts') + ".",
                                    2000,
                                    t('page-homepage.section-stars-and-teams.managers') + ".",
                                    2000,
                                ]}
                                wrapper="span"
                                cursor={true}
                                repeat={Infinity}

                            />}
                        />



                    </Row>
                    <Row>

                        <Col xl={6} lg={6} md={12} sm={12} className="hiding-image-wrapper">
                            <div className="stars-container">
                                <HidingImage
                                    src={starsImage}
                                    text={<Trans i18nKey={"page-homepage.section-stars-and-teams.stars.text"}></Trans>
                                    }
                                />

                                <div className="headline-wrapper">
                                    <h3>{t("page-homepage.section-stars-and-teams.stars.header")}</h3>
                                </div>
                            </div>

                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} className="hiding-image-wrapper">
                            <div className="teams-container">
                                <HidingImage
                                    src={teamsImage}
                                    text={<Trans i18nKey={"page-homepage.section-stars-and-teams.teams.text"}></Trans>}
                                />
                                <div className="headline-wrapper" id="stars-headline">
                                    <h3>{t("page-homepage.section-stars-and-teams.teams.header")}</h3>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )



}

export default SectionStarsAndTeams;
import React, { useRef, useState } from 'react'
import './AdminViewSvcStatus.css'
import AdminBackButton from '../AdminBackButton/AdminBackButton'
import Button from '../../../../Button/Button'
import adminChangeStatusOfService from '../../admin-functions/admin-svc-status'
import { Alert } from 'react-bootstrap'
import Spinner from "react-bootstrap/Spinner";

const statusOptions = ['prepare', 'awaiting', 'start', 'ready', 'completed'];

const AdminViewSvcStatus = ({ setCurrentView }) => {

    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)

    const svcStatusRef = useRef()
    const svcIdRef = useRef()

    const [selectedStatus, setSelectedStatus] = useState('prepare');

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };


    return (
        <div className="admin-view-svc-status-container">
            {error && <Alert variant="danger">{error}</Alert>}
            {errorSuccess && <Alert variant='success'>{errorSuccess}</Alert>}
            <div className="admin-view-svc-status-wrapper">
                <div className="admin-svc-status-bar-container">
                    <div className="admin-svc-status-bar-wrapper">
                        <div className="admin-svc-status-bar-inputs">
                            <div className="svc-id-input-wrapper">
                                <label htmlFor='svc-status-choose-id' className='form-label'>Numer usługi:</label>
                                <input ref={svcIdRef} className='form-control' type='text' placeholder='id' id='svc-status-choose-id' name='svc-status-choose-id' />
                            </div>
                            <div className="svc-status-input-wrapper form-group">
                                <label htmlFor='svc-status-choose-status' className='form-label'>Status:</label>
                                <select
                                    ref={svcStatusRef}
                                    className='form-control'
                                    id='svc-status-choose-status'
                                    name='svc-status-choose-status'
                                    value={selectedStatus}
                                    onChange={handleStatusChange}
                                >
                                    {statusOptions.map((name, index) => (
                                        <option key={index} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <Button className='try medium' disabled={loading} onClick={async () => {
                            setLoading(true)
                            setError('')
                            setErrorSuccess('')
                            const { data, error } = await adminChangeStatusOfService(svcIdRef.current.value, svcStatusRef.current.value)
                            if (error) {
                                setError(error?.message)
                            } else {
                                setErrorSuccess('Status został zmieniony poprawnie.')
                            }
                            setLoading(false)
                        }}>{loading ? <Spinner animation="border" className='loading-spinner small black' /> : 'Zmień status usługi'}</Button>
                        <AdminBackButton setCurrentView={() => { setCurrentView('functions') }} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdminViewSvcStatus
import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'
import Text from '../../../../components/Text/Text'
import './SectionSteamBoost.css'
import GamingModeSteam from '../../../../assets/img/gaming-mode/gaming-mode-steam.webp'

const SectionSteamBoost = () => {

    const { t } = useTranslation()
    return (
        <section id='gm-steam-boost' className='gm-steam-boost'>
            <Container>
                <Row style={{ display: 'flex', alignItems: "center" }}>
                    <Col xl={6} lg={6} md={0} sm={0} className='gm-steam-img-container'>
                        <Image
                            src={GamingModeSteam}
                        />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} >
                        <Text
                            style={{ paddingTop: "0", marginTop: "0", marginBottom: "0" }}
                            h4={t("page-gamingmode.section-gm-steam-boost.header")}
                            p={
                                <>
                                    <span style={{ fontWeight: "bold" }}><Trans i18nKey={"page-gamingmode.section-gm-steam-boost.text"} /><br /><br /></span>
                                </>
                            }
                        />
                        <Text
                            p={
                                <>
                                    <span style={{ fontWeight: "bold", background: "linear-gradient(132deg, rgba(0, 224, 255, 1) 100%, rgba(143, 241, 255, 1) 120%)", WebkitTextFillColor: "transparent", backgroundClip: "text", WebkitBackgroundClip: "text" }}>{t("page-gamingmode.section-gm-steam-boost.text-bottom")}</span>
                                </>
                            }
                        />
                        <Text
                            className={"small"}
                            style={{ paddingTop: "0", marginTop: "0", marginBottom: "0" }}
                            p={
                                <>
                                    <span><Trans i18nKey={"page-gamingmode.section-gm-steam-boost.list"} /></span>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SectionSteamBoost
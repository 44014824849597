import React from 'react'
import './DateHolder.css'
import moment from 'moment';
import 'moment/locale/pl';

const DateHolder = ({ date, index, chooseDate, clickedDateIndex }) => {

    const language = localStorage.getItem('lang') || 'pl';
    moment.locale(language)
    let date_moment = moment(date)
    return (
        <div className={`component-date-holder ${clickedDateIndex === index ? 'clicked' : ''}`} onClick={() => {
            chooseDate(index)
        }}>
            <div className="component-date-holder-inner">
                <p>
                    {date_moment.format('dd')}
                </p>
                <p>
                    {date_moment.format('DD.MM')}
                </p>
            </div>
        </div>
    )
}

export default DateHolder
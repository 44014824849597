import React from 'react'
import './AdminViewMailInvite.css'
import AdminBackButton from '../AdminBackButton/AdminBackButton'

const AdminViewMailInvite = ({ setCurrentView }) => {
    return (
        <div className="admin-view-mail-invite-container">
            <div className="admin-view-mail-invite-wrapper">
                <h1>INVITE</h1>
                <AdminBackButton setCurrentView={() => { setCurrentView('functions') }} />
            </div>
        </div>
    )
}

export default AdminViewMailInvite
import React from 'react'
import Footer from '../../components/Footer/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import TopNavigation from '../../components/TopNavigation/TopNavigation'
import StatuteParagraph from '../../components/StatuteParagraph/StatuteParagraph'
import Text from '../../components/Text/Text'
import "./Statute.css"
import { useTranslation } from 'react-i18next'
import statutePLTranslations from '../../locales/pl/statute.json'
import statuteENGTranslations from '../../locales/en/statute.json'
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel'

const Statute = () => {

    const { t, i18n } = useTranslation('statute')
    const translatedPLSections = statutePLTranslations['page-statute'].sections;
    const translatedENGSections = statuteENGTranslations['page-statute'].sections;
    const mapSections = (sections) => {
        if (!sections) {
            return null; // lub inny sposób obsługi braku danych
        }

        return sections.map((section, index) => (
            <li className='headline' key={index}>
                <h1>{t(`${section.title}`)}</h1>
                {Array.isArray(section.content) ? (
                    <React.Fragment>
                        <ol>
                            {section.content.map((item, itemIndex) => (
                                <React.Fragment key={itemIndex}>
                                    {item.list ? (
                                        <React.Fragment>
                                            {item.list.map((listItem, listItemIndex) => (
                                                <React.Fragment key={listItemIndex}>
                                                    {listItem.list ? (
                                                        <ul className='sub-list'>
                                                            {listItem.list.map((subListItem, subListItemIndex) => (
                                                                <li key={subListItemIndex}>{t(`${subListItem}`)}</li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <li>{t(`${listItem}`)}</li>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ) : (
                                        <p>{t(`${item}`)}</p>
                                    )}
                                </React.Fragment>
                            ))}
                        </ol>
                    </React.Fragment>
                ) : (
                    <p>{t(`${section.content}`)}</p>
                )}
            </li>
        ));
    };

    return (
        <>
            <TopNavigation />
            <main className="page statute">
                <SocialMediaPanel />
                <section className="section-statute" id="section-statute">
                    <Container>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <Text
                                    h1={t("page-statute.header")}
                                    h2={t("page-statute.sub-header")}
                                    className={"subpage center"}
                                />
                                <StatuteParagraph>
                                    <ol>
                                        {i18n.language === 'pl' ? mapSections(translatedPLSections) : mapSections(translatedENGSections)}
                                    </ol>
                                </StatuteParagraph>
                            </Col>
                        </Row>
                    </Container>
                </section >
            </main >
            <Footer />
        </>
    )
}

export default Statute
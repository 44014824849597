import React from 'react'
import { Image } from 'react-bootstrap'
import "./PersonalDataSceletor.scss"

const PersonalData = ({ customName, email }) => {

    return (

        <div className="component-personal-sceletor ">
            <div className="component-personal-sceletor-inner">
                <div className="personal-avatar-wrapper ">
                    <Image
                        alt="personal avatar"
                        className='circular--landscape'
                    />
                </div>
                <div className="personal-info-wrapper">
                    <h2>{customName}</h2>
                    <h3>{email}</h3>
                </div>
            </div>
        </div>

    )
}

export default PersonalData
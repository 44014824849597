import { sendEventToServer } from "../pixelEventsAPI";

// Zakup pakietu - standardowe nazwy 'Purchase' i 'CompletePayment'
export const packagePurchasedEvent = (productSkuId, productName, productPrice, currency, language, hashed_email, hashed_user_id) => {
    const event_id = `purchase${productSkuId}On${Date.now()}`

    // Meta (FB, IG)
    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'Purchase', {
            content_ids: `${productSkuId}`,
            content_name: productName,
            content_type: "product",
            value: productPrice,
            currency: currency,
            language: language
        },
            {
                eventID: event_id,
            });
    }

    // TikTok
    if (typeof window.ttq !== 'undefined') {

        window.ttq.identify({
            "email": hashed_email, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
            "external_id": hashed_user_id // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
        });

        window.ttq.track('CompletePayment', {
            contents: [
                {
                    content_id: `${productSkuId}`, //todo: zmienic na sku_id produktu w stringu, np. "${sku_id}"
                    content_name: productName,
                    price: productPrice
                }
            ],
            content_type: "product",
            value: productPrice,
            currency: currency,
            locale: language // czy na pewno dobrze przekazuje? chyba przekazuje 'en', a powinno ('en', 'US') czy cos takiego? https://www.rfc-editor.org/rfc/bcp/bcp47.txt
        }, { event_id: event_id });
    }

    // Wyślij zdarzenie do serwera
    const eventData = {
        context: {
            user: {
                external_id: hashed_user_id,
                email: hashed_email,
                client_user_agent: navigator.userAgent
            },
            page: {
                url: window.location.href
            }
        },
        properties: {
            contents: [
                {
                    content_id: `${productSkuId}`,
                    content_type: "product",
                    content_name: productName,
                    price: productPrice
                }
            ],
            event_id: event_id,
            value: productPrice,
            currency: currency,
            locale: language
        }
    };

    sendEventToServer('https://gainfps.com/api/m-event', 'Purchase', eventData);
    sendEventToServer('https://gainfps.com/api/t-event', 'CompletePayment', eventData);
    // sendEventToServer('http://localhost:4747/api/m-event', 'Purchase', eventData);
    // sendEventToServer('http://localhost:4747/api/t-event', 'CompletePayment', eventData);
}
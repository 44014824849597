import React from 'react'
import './OrderStep.css'

const OrderStep = ({ children, className = '', disabled }) => {
    return (
        <div className={`component-order-step ${className}`} disabled={disabled}>
            <div className="component-order-step-inner">
                {children}
            </div>
        </div>
    )
}

export default OrderStep

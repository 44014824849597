import React from 'react'
import { Button, Image } from 'react-bootstrap'
import LogoutIcon from '../../assets/img/icons/logout-icon-white.svg'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import './LogoutButton.css'

const LogoutButton = ({ className }) => {

    const navigate = useNavigate()
    const { logout } = useAuth()

    async function handleLogout() {
        try {
            await logout()
            navigate("/logowanie")
        } catch {
        }
    }
    return (
        <Button className={`logout-button ${className}`} onClick={handleLogout}>
            <Image
                src={LogoutIcon}
                alt='logout icon' />
        </Button>
    )
}

export default LogoutButton;
import React from "react"
import './Review.css'
import { Image } from 'react-bootstrap'
import starIcon from "../../assets/img/icons/star-icon.svg"
const review = ({ name, career, avatar, text, className }) => {
    return (
        <>
            <div className={`component-review ${className ? className : ''}`}>
                <div className="component-review-inner">

                    <div className="review-personal-container">
                        <div className="review-avatar-wrapper">
                            <Image src={avatar} alt="review person avatar" />
                        </div>
                        <div className="review-info-container">
                            <div className="review-name-wrapper">
                                <h4>{name}</h4>
                            </div>
                            <div className="review-career-wrapper">
                                <h5>{career}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="review-text-container">
                        <p>{text}</p>
                    </div>
                    <div className="review-stars-container">
                        <Image src={starIcon} alt="star icon" />
                        <Image src={starIcon} alt="star icon" />
                        <Image src={starIcon} alt="star icon" />
                        <Image src={starIcon} alt="star icon" />
                        <Image src={starIcon} alt="star icon" />
                    </div>

                </div>
            </div>
        </>

    )
}

export default review
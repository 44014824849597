import React, { useContext, createContext, useState, useEffect } from 'react'
import supabase from '../data/supabase';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment-timezone';
import i18n from '../i18n';

const DatabasePageContext = createContext()

export function useDatabasePageContext() {
    return useContext(DatabasePageContext)
}

export const DatabasePageProvider = ({ children }) => {

    // states of data to be received from database
    const [promoPackages, setPromoPackages] = useState([]);
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [currency, setCurrency] = useState('PLN')
    const [timezone, setTimezone] = useState('Europe/Warsaw')
    const [currencyDisplay, setCurrencyDisplay] = useState('zł')
    const [exchangeRates, setExchangeRates] = useState({
        EUR: 4.20,
        GBP: 4.92,
        PLN: 1,
        USD: 3.85
    })

    useEffect(() => {
        let userTimezone = moment.tz.guess();
        setTimezone(userTimezone)
    }, [])

    useEffect(() => {
        try {
            fetch('https://ipinfo.io/json?token=96834bf9a78780')
                .then(response => response.json())
                .then(data => {
                    switch (data.country) {
                        case 'PL':
                            setCurrency('PLN');
                            setCurrencyDisplay('zł')
                            break;
                        case 'GB':
                            setCurrency('GBP');
                            setCurrencyDisplay('£')
                            break;
                        case 'US':
                            setCurrency('USD');
                            setCurrencyDisplay('$')
                            break;
                        default:
                            setCurrency('EUR');
                            setCurrencyDisplay('€')
                    }
                });
        } catch (error) {
        }
    }, []);

    // ##########################################
    // Hooks Functions
    // ##########################################

    function useReadPackagesDB() {
        useEffect(() => {
            getPackages();
        }, []
        )
    }

    // ##########################################
    // Functions
    // ##########################################

    async function getPackages(language = i18n.language) {
        setIsPageLoading(true);

        try {
            const { data, error } = await supabase
                .from('packages')
                .select(`
                id,
                price,
                bestseller,
                type,
                promo_price,
                users_number,
                support_days,
                service_name,
                duration,
                status,
                position,
                button,
                special,
                packages_translations (
                    name_translated,
                    short_desc_translated,
                    benefits_list_translated,
                    time_description_translated
                )
            `)
                .eq('packages_translations.language', language)
                .like('type', '%promo%')
                .order('position', { ascending: true });

            if (error) throw error;

            if (data != null) {
                // Przetwarzanie danych, aby uwzględnić tłumaczenia
                const translatedPackages = data.map(pkg => ({
                    ...pkg,
                    name: pkg.packages_translations[0].name_translated,
                    short_desc: pkg.packages_translations[0].short_desc_translated,
                    benefits_list: pkg.packages_translations[0].benefits_list_translated,
                    time_description: pkg.packages_translations[0].time_description_translated
                }
                ));

                setPromoPackages(translatedPackages); // [Package1, Package2, Package3...]
            }
        } catch (error) {
            // Obsługa błędów
        }
        setIsPageLoading(false);
    }

    // ##########################################
    // Values passed by Context
    // ##########################################

    const value = {
        useReadPackagesDB,
        getPackages,
        promoPackages,
        timezone,
        isPageLoading,
        currency,
        currencyDisplay,
        exchangeRates
    }

    return (
        <DatabasePageContext.Provider value={value}>
            {children}
        </DatabasePageContext.Provider>
    )


}

export default DatabasePageProvider
import React, { useRef, useState } from 'react'
import './SettingsViewPassword.css'
import SettingsBackButton from '../../settings-components/SettingsBackButton/SettingsBackButton'
import Button from '../../../../Button/Button'
import { Form, Alert, InputGroup } from 'react-bootstrap'
import Spinner from "react-bootstrap/Spinner";
import SimpleContainer from '../../../../SimpleContainer/SimpleContainer'
import { useDatabaseUserContext } from '../../../../../context/DatabaseUserContext'
import visibleOffIcon from '../../../../../assets/img/icons/visibility_off_black_36dp.svg'
import visibleOnIcon from '../../../../../assets/img/icons/visibility_black_36dp.svg'
import supabase from '../../../../../data/supabase'
import { useAuth } from '../../../../../context/AuthContext'
import { useTranslation } from 'react-i18next'

const SettingsViewPassword = ({ setCurrentView }) => {


    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [validPassword, setValidPassword] = useState(true); // Dodaj stan do śledzenia walidacji hasła
    const emailRef = useRef()
    const newPasswordRef = useRef()
    const passwordConfirmRef = useRef()
    const currentPasswordRef = useRef()
    const { userData } = useDatabaseUserContext()
    const { logout } = useAuth()

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    const { t } = useTranslation('translation');
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };
    const handlePasswordChange = () => {
        const password = newPasswordRef.current.value;
        const confirmPassword = passwordConfirmRef.current.value;

        // Dodaj dowolną logikę walidacji hasła. Poniżej przykład sprawdzania, czy hasło ma co najmniej 8 znaków.
        if (passwordRegex.test(password)) {
            setValidPassword(true);
        } else {
            setValidPassword(false);
        }

        // Możesz dodać więcej logiki walidacji, np. sprawdzenie, czy hasło zawiera pewne znaki, duże litery, cyfry, etc.
    };

    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        setError('')
        setErrorSuccess('')

        const newPassword = newPasswordRef.current.value;
        const passwordConfirm = passwordConfirmRef.current.value;
        const currentPassword = currentPasswordRef.current.value;

        // Walidacja nowego hasła
        if (!newPassword || !passwordRegex.test(newPassword)) {
            setLoading(false);
            return setError(t("page-profile.view-settings.settings-password.new-pw-error"));
        }

        // Walidacja potwierdzenia hasła
        if (newPassword !== passwordConfirm) {
            setLoading(false);
            return setError(t("forms.feedback.passwords-differ"));
        }

        try {
            const { data, error } = await supabase
                .rpc('change_user_password', { 'current_plain_password': currentPassword, 'new_plain_password': newPassword })

            if (error) throw error
            setErrorSuccess(t("page-profile.view-settings.settings-password.pw-change-success"))

            setTimeout(async () => {
                await logout();
            }, 2000);

        } catch (error) {
            setError(t("page-profile.view-settings.settings-password.pw-change-error"))
        }

        setLoading(false)
    }

    return (
        <SimpleContainer>
            <div className="settings-view-password-container">
                <div className="settings-view-password-wrapper">
                    <Form onSubmit={handleSubmit} className="form settings-view-form">
                        {error && <Alert variant="danger">{error}</Alert>}
                        {errorSuccess && <Alert variant="success">{errorSuccess}</Alert>}
                        <Form.Group className="form-group settings">
                            <Form.Label>{t('forms.label.email')}</Form.Label>
                            <Form.Control type="email" readOnly defaultValue={userData?.email ?? ''} ref={emailRef} placeholder={t("forms.placeholder.email")} disabled className={(userData?.email) ? `plaintext` : `plaintext loading`} maxLength={45} />
                        </Form.Group>
                        <Form.Group className="form-group settings">
                            <Form.Label>{t("page-profile.view-settings.settings-password.current-pw-label")}</Form.Label>
                            <InputGroup>
                                <Form.Control type={showOldPassword ? "text" : "password"} ref={currentPasswordRef} placeholder={t("page-profile.view-settings.settings-password.current-pw-placeholder")} maxLength={45} />
                                <span className="form-control password-toggle" style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} onClick={toggleOldPasswordVisibility}>
                                    {showOldPassword ? <img src={visibleOffIcon} alt='visible icon' /> : <img src={visibleOnIcon} alt='visible icon' />}
                                </span>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="form-group settings">
                            <Form.Label>{t("page-profile.view-settings.settings-password.new-pw-label")}</Form.Label>
                            <InputGroup>
                                <Form.Control type={showPassword ? "text" : "password"} ref={newPasswordRef} required placeholder={t("page-profile.view-settings.settings-password.new-pw-placeholder")} maxLength={45} minLength={8} onChange={handlePasswordChange} // Dodaj obsługę zmiany hasła
                                    isInvalid={!validPassword} />
                                <span className="form-control password-toggle" style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} onClick={togglePasswordVisibility}>
                                    {showPassword ? <img src={visibleOffIcon} alt='visible icon' /> : <img src={visibleOnIcon} alt='visible icon' />}
                                </span>
                                <Form.Control.Feedback type="invalid">
                                    {t("forms.feedback.wrong-password-format")}
                                </Form.Control.Feedback>
                            </InputGroup >
                        </Form.Group>
                        <Form.Group className="form-group settings">
                            <Form.Label>{t("page-profile.view-settings.settings-password.repeat-new-pw-label")}</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} placeholder={t("page-profile.view-settings.settings-password.repeat-new-pw-placeholder")} maxLength={45} />
                        </Form.Group>
                    </Form>
                    <Button type='submit' className='try medium' disabled={loading} onClick={handleSubmit}>{loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("page-profile.view-settings.settings-password.change-pw-button")}
                    </Button>
                    <SettingsBackButton setCurrentView={() => { setCurrentView('functions') }} />
                </div>
            </div>
        </SimpleContainer>
    )
}

export default SettingsViewPassword
import React from 'react'
import { useAuth } from "../../context/AuthContext"
import Profile from '../../pages/Profile/Profile'
import { Navigate } from 'react-router-dom'
import Signup from '../../pages/Signup/Signup'
const PrivateRouteSignup = () => {

    const { currentUser } = useAuth()
    return currentUser ? <><Profile /> <Navigate to='/konto' /></> : <Signup />



}

export default PrivateRouteSignup


import React from 'react'
import "./SectionHero.css"
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../../../../components/Button/Button'
import { Trans, useTranslation } from 'react-i18next'

const SectionHero = () => {

    const { t } = useTranslation('translation');

    return (
        <>
            <section id="section-hero" className='section-hero'>
                <Container className='hero'>
                    <Row>
                        <Col xl={12} md={12} sm={12}>
                            <div className="reveal-power-container">
                                <div className="reveal-power-container-inner">
                                    <div className="text-hero-wrapper">
                                        <span><h1>
                                            <Trans i18nKey="page-homepage.section-hero.header" />
                                        </h1></span>
                                    </div>
                                    <div className="button-hero-wrapper">
                                        <Button className='try' href={'/zakup'}>
                                            {t("purchase-buttons.buy-now")}
                                        </Button>
                                    </div>
                                </div>

                            </div>


                        </Col>
                    </Row>
                </Container>
            </section>
        </>

    )
}

export default SectionHero;

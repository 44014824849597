import React from 'react';
import { Outlet } from 'react-router-dom';
import "./FragmentMain.css";
import { BannerContextProvider } from "../../../context/BannerContext";


const FragmentMain = (props) => {


    return (

        <BannerContextProvider>
            <div className='component-fragment-main'>
                <div className='component-fragment-main-inner'>
                    <Outlet />
                </div>
            </div>
        </BannerContextProvider >
    )

}

FragmentMain.defaultProps = {
    fragmentBar: true,
};

export default FragmentMain
import CryptoJS from "crypto-js";

export const hashData = (data) => {
  if (data) {
    // Jeśli dane zawierają znak '@', traktujemy je jako adres e-mail i konwertujemy na małe litery
    if (data.includes('@')) {
      data = data.toLowerCase();
    }
    return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
  } else {
    return null;
  }
}

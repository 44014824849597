import React from 'react'
import Footer from '../../components/Footer/Footer'
import TopNavigation from '../../components/TopNavigation/TopNavigation'
import "./Proserwis.css"
import SectionTeam from './Sections/SectionTeam/SectionTeam'
import SectionTryNowPro from './Sections/SectionTryNowPro/SectionTryNowPro'
import SectionContent from './Sections/SectionContent/SectionContent'
import SectionThemes from './Sections/SectionThemes/SectionThemes'

const Proserwis = () => {

    return (
        <>
            <TopNavigation />
            <main className="page proserwis">
                <SectionContent />
                <SectionTeam />
                <SectionThemes />
                <SectionTryNowPro />
            </main>
            <Footer />
        </>
    )
}

export default Proserwis
import React, { useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Text from '../../../../components/Text/Text'
import ProductBoxDescription from '../../../../components/ProductBoxDescription/ProductBoxDescription'
import "./SectionDescription.css"
import allProductsPLBoxes from '../../allProductsPLBoxes'
import allProductsENBoxes from '../../allProductsENBoxes'
import { useTranslation } from 'react-i18next'
import { servicesSeenEvent } from '../../../../data/pixel-events/servicesSeenEvent'
import { useAuth } from '../../../../context/AuthContext'
import { hashData } from '../../../../utils/hashData'
import { useLocation } from 'react-router-dom'

const SectionDescription = () => {
    const { t, i18n } = useTranslation('translation')
    const {currentUser} = useAuth()
    const hasMounted = useRef(false)
    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);

    useEffect(()=> {
        if (!hasMounted.current) {
            servicesSeenEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id))
            hasMounted.current = true
        }
     },[])

    const boxes = {
        allProductsPLBoxes,
        allProductsENBoxes
    }

    return (
        <section className="section-services-description" id="services-description">
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Text
                            h1={t("page-services.section-description.header")}
                            className={"subpage center"}
                        />
                    </Col>
                </Row>
                {
                    boxes[t("page-services.section-description.boxes-list")].map((product, index) =>
                        <ProductBoxDescription key={index} id={product.id} service_name={product.service_name} users_number={product.users_number} support_days={product.support_days} duration={product.duration} promo_price={product.promo_price} name={product.name} time_description={product.time_description} mode={product.mode} price={product.price} description={product.description} short_desc={product.short_desc} advantages_array={product.advantages_array} button={product.button} benefits_list={product.benefits_list} status={product.status} type={product.type} />

                    )
                }

            </Container>
        </section>
    )
}

export default SectionDescription
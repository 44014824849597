import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Text from '../../../../components/Text/Text'
import Polaroid from "../../../../components/Polaroid/Polaroid"
import inputLagIcon from "../../../../assets/img/icons/inputlag.webp"
import fpsIcon from "../../../../assets/img/icons/fps.webp"
import stutteringIcon from "../../../../assets/img/icons/stuttering.webp"
import pingIcon from "../../../../assets/img/icons/ping.webp"
import './SectionWhatWeImprove.css'
import { useTranslation } from 'react-i18next'

const SectionWhatWeImprove = () => {

    const { t } = useTranslation('translation');

    return (
        <>
            <section id="section-what-we-improve" className="section-what-we-improve">
                <Container>
                    <Row>
                        <Col xl={2} lg={1} md={1} sm={0}></Col>
                        <Col xl={8} lg={10} md={10} sm={12} className='center'>
                            <Text
                                className="center highlight"
                                h4={t("page-homepage.section-what-we-improve.header")}
                                h3={<><span id="proserwis-header">PRO SERWIS</span></>}
                                p={t("page-homepage.section-what-we-improve.text")}
                            />
                        </Col>
                        <Col xl={2} lg={1} md={1} sm={0}></Col>
                    </Row>
                    <Row className='polaroids-container'>
                        <Col xxl={3} xl={6} lg={6} md={12} sm={12} className='polaroid-wrapper'>
                            <Polaroid
                                icon={fpsIcon}
                                h4={t("fps")}
                                p={t("page-homepage.section-what-we-improve.polaroids.fps")}
                            >
                            </Polaroid>
                        </Col>
                        <Col xxl={3} xl={6} lg={6} md={12} sm={12} className='polaroid-wrapper'>
                            <Polaroid
                                icon={inputLagIcon}
                                h4={t("input-lag")}
                                p={t("page-homepage.section-what-we-improve.polaroids.input lag")}
                            >
                            </Polaroid>
                        </Col>
                        <Col xxl={3} xl={6} lg={6} md={12} sm={12} className='polaroid-wrapper'>
                            <Polaroid
                                icon={stutteringIcon}
                                h4={t("stuttering")}
                                p={t("page-homepage.section-what-we-improve.polaroids.stuttering")}>
                            </Polaroid>
                        </Col>
                        <Col xxl={3} xl={6} lg={6} md={12} sm={12} className='polaroid-wrapper'>
                            <Polaroid
                                icon={pingIcon}
                                h4={t("ping")}
                                p={t("page-homepage.section-what-we-improve.polaroids.ping")}>
                            </Polaroid>
                        </Col>
                    </Row>
                </Container>
            </section>


        </>
    )

}

export default SectionWhatWeImprove;
import { Link } from "react-router-dom";
import React, { useState, useRef } from 'react';
import { Form, Alert, Container, Row, Col, InputGroup } from 'react-bootstrap';
import './SignupForm.css';
import { useAuth } from '../../../../context/AuthContext'
import Button from "../../../../components/Button/Button";
import Spinner from "react-bootstrap/Spinner";
import visibleOffIcon from '../../../../assets/img/icons/visibility_off_black_36dp.svg'
import visibleOnIcon from '../../../../assets/img/icons/visibility_black_36dp.svg'
import { Turnstile } from '@marsidev/react-turnstile'
import supabase from "../../../../data/supabase";
import { Trans, useTranslation } from "react-i18next";
import { accountSignedUpEvent } from "../../../../data/pixel-events/accountSignedUpEvent";
import { hashData } from "../../../../utils/hashData";

export default function SignupForm() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const { signup } = useAuth()
    const [statuteAccepted, setStatuteAccepted] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [validPassword, setValidPassword] = useState(true); // Dodaj stan do śledzenia walidacji hasła
    const [validConfPassword, setValidConfPassword] = useState(true); // Dodaj stan do śledzenia walidacji hasła
    const [validEmail, setValidEmail] = useState(true)
    const [captchaToken, setCaptchaToken] = useState()
    const { t, i18n } = useTranslation('translation')

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = () => {
        const password = passwordRef.current.value;
        const confirmPassword = passwordConfirmRef.current.value;
        if (passwordRegex.test(password)) {
            setValidPassword(true);
        } else {
            setValidPassword(false);
        }

        if (password === confirmPassword) {
            setValidConfPassword(true);
        } else {
            setValidConfPassword(false);
        }
        handleEmailChange()
    };

    const handlePasswordConfChange = () => {
        const password = passwordRef.current.value;
        const confirmPassword = passwordConfirmRef.current.value;
        if (password === confirmPassword) {
            setValidConfPassword(true);
        } else {
            setValidConfPassword(false);
        }

        handleEmailChange()
        if (!password) {
            setValidPassword(false)
        }
    };

    const handleEmailChange = () => {
        const email = emailRef.current.value;
        if (emailRegex.test(email)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }
    };

    async function handleSubmit(e) {
        e.preventDefault()
        if (!captchaToken) {
            return setError(t("forms.error.captcha-error"))
        }

        const password = passwordRef.current.value
        const email = (emailRef.current.value).trim()
        if (!emailRegex.test(email)) {
            return setError(t("forms.feedback.wrong-email"))
        }
        if (passwordRef.current.value !==
            passwordConfirmRef.current.value) {
            return setError(t("forms.feedback.passwords-differ"))
        }

        else if (!passwordRegex.test(password)) {
            return setError(t("forms.feedback.wrong-password-format"))
        }

        try {
            setError('')
            setErrorSuccess('')
            setLoading(true)
            let msg = ''

            // sprawdzanie, czy mail nie jest zarejestrowany
            const { data, error } = await supabase
                .rpc('is_email_taken', {
                    p_email: email,
                })

            if (error) throw error;
            if (data && data === true) {
                setError(t("forms.feedback.email-taken"))
            } else {
                const err = await signup(email, password, captchaToken, i18n.language)
                if (err) {
                    // signup() returns error if there is an error
                    // or returns nothing (undefined) if there is no error

                    switch (err.message) {
                        case 'case todo':
                            msg = t("page-signup.signup-form.wrong-data")
                            break;
                        default:
                            msg = t("page-signup.signup-form.signup-failure")
                            break;
                    }
                    setError({ msg })
                }
                else {
                    // rejestracja powiodła się  
                    setError('')
                    setErrorSuccess(t("page-signup.signup-form.signup-success"))
                    accountSignedUpEvent(i18n.language, hashData(email))
                    const newUrl = `${window.location.pathname}?status=success`;
                    window.history.replaceState({ path: newUrl }, '', newUrl);
                }
            }   

        } catch (error) {
            setError(error.message)
        }

        setLoading(false)
    }
    return (
        <>
            <section id='section-signup-form' className='section-signup-form'>
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <div className="form-container">
                                <div className="form-wrapper">
                                    {
                                        loading ?
                                            <div className="spinner-container">
                                                <Spinner animation="border" className='loading-spinner' />
                                            </div>
                                            :
                                            <>
                                                <Form onSubmit={handleSubmit} className="form signup-form" noValidate>
                                                    <h1>{t("page-signup.signup-form.header")}</h1>
                                                    {error && <Alert variant="danger">{error}</Alert>}
                                                    {errorSuccess && <Alert variant='success'>{errorSuccess}</Alert>}
                                                    <Form.Group className="form-group" id="email">
                                                        <Form.Label>{t("forms.label.email")}</Form.Label>
                                                        <Form.Control type="email" ref={emailRef} required placeholder={t("forms.placeholder.email")} maxLength={45} onChange={handleEmailChange} isInvalid={!validEmail} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.wrong-email")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="form-group" id="password">
                                                        <Form.Label>{t("forms.label.password")}</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control type={showPassword ? "text" : "password"} ref={passwordRef} required placeholder={t("forms.placeholder.enter-password")} maxLength={45} minLength={8} onChange={handlePasswordChange}
                                                                isInvalid={!validPassword} />
                                                            <span className="form-control password-toggle" style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} onClick={togglePasswordVisibility}>
                                                                {showPassword ? <img src={visibleOffIcon} alt='visible icon' /> : <img src={visibleOnIcon} alt='visible icon' />}
                                                            </span>
                                                            <Form.Control.Feedback type="invalid">
                                                                {t("forms.feedback.wrong-password-format")}
                                                            </Form.Control.Feedback>
                                                        </InputGroup >
                                                    </Form.Group>
                                                    <Form.Group className="form-group" id="password-confirm">
                                                        <Form.Label>{t("forms.label.confirm-password")}</Form.Label>
                                                        <Form.Control type="password" ref={passwordConfirmRef} required placeholder={t("forms.placeholder.confirm-password")} maxLength={45} minLength={8} onChange={handlePasswordConfChange} isInvalid={!validConfPassword} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.passwords-differ")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <div className="choose-option-container">
                                                        <div className="options-container">
                                                            <div className="option-wrapper">
                                                                <input type="checkbox" name="select-accept-statute" id="select-accept-statute" onChange={(event) => {
                                                                    setStatuteAccepted(event.target.checked)
                                                                }} />
                                                                <label htmlFor="select-accept-statute" className="option option-3 form-label">
                                                                    <div className="dot-wrapper">
                                                                        <div className="dot"></div>
                                                                    </div>
                                                                    <span><Trans i18nKey={"page-signup.signup-form.statute-accept"}>*Oświadczam, że zapoznałem się z <span style={{ fontFamily: 'Gilroy-bold, sans-serif' }}><Link to='/regulamin'>Regulaminem</Link></span> i <span style={{ fontFamily: 'Gilroy-bold, sans-serif' }}><Link to='/polityka-prywatnosci'>Polityką prywatności</Link></span> oraz
                                                                        akceptuję ich treść.</Trans></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="turnstile-container">
                                                        <Turnstile
                                                            siteKey='0x4AAAAAAAJx1CAT2qk2tue5'
                                                            onSuccess={(token) => {
                                                                setCaptchaToken(token)
                                                                setError('')
                                                            }}
                                                            onError={(token) => {
                                                                setCaptchaToken(null)
                                                                setError(t("forms.error.captcha-error"))

                                                            }} />
                                                    </div>
                                                    <Button type='submit' className="try hover2" disabled={!statuteAccepted || loading}>
                                                        {t("page-signup.signup-form.create-acc-button")}
                                                    </Button>
                                                </Form>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="have-account-text-wrapper">
                                <p><Trans i18nKey={"page-signup.signup-form.have-account"}>Posiadasz już konto? <Link to="/logowanie"><span className="highlighted">Zaloguj się</span></Link></Trans></p>
                            </div>
                        </Col>
                    </Row>
                </Container >

            </section >
        </>
    )
}

import React from 'react'
import { Button, Image } from 'react-bootstrap'
import settingsIcon from '../../assets/img/icons/settings-white.svg'
import { useNavigate } from 'react-router-dom'
import './SettingsButton.css'

const SettingsButton = ({ className }) => {

    const navigate = useNavigate()

    async function handleSettings() {
        try {
            navigate("/konto/ustawienia")
        } catch (error) {
        }
    }
    return (
        <Button className={`settings-button ${className}`} onClick={handleSettings}>
            <Image
                src={settingsIcon}
                alt='settings' />
        </Button>
    )
}

export default SettingsButton;
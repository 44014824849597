import React, { useState, useEffect } from 'react'
import './Calendar.css'
import DateHolder from './DateHolder/DateHolder'
import TimeHolder from './TimeHolder/TimeHolder'
import Button from '../Button/Button'
import supabase from '../../data/supabase'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment';
import 'moment/locale/pl';
import 'moment-timezone';
import { useDatabasePageContext } from '../../context/DatabasePageContext'
import { useTranslation } from 'react-i18next'

const Calendar = ({ setSchedulingVisible, handleStateChange }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [availableDates, setAvailableDates] = useState([])
    const [availableHours, setAvailableHours] = useState([])
    const [clickedTimeIndex, setClickedTimeIndex] = useState(-1)
    const [clickedDateIndex, setClickedDateIndex] = useState(-1)
    const { timezone } = useDatabasePageContext()
    const { t } = useTranslation('translation')
    // ustawia sie wlasciwy czas, kiedy wyswietlaja sie dostepne godziny danego dnia
    useEffect(() => {
        handleStateChange(
            'bookedDate', {
            date: availableDates[clickedDateIndex]?.date,
            time: availableHours[clickedTimeIndex]
        }
        )
    }, [availableHours])

    // uaktywnia sie przy kazdym kliknieciu w date lub godzine
    useEffect(() => {
        clickedDateIndex !== (-1) && clickedTimeIndex !== (-1) ?
            handleStateChange(
                'bookedDate', {
                date: availableDates[clickedDateIndex]?.date,
                time: availableHours[clickedTimeIndex]
            })
            :
            handleStateChange(
                'bookedDate', {
                date: null,
                time: null
            })
    }, [clickedDateIndex, clickedTimeIndex])

    const chooseDate = (dateIndex) => {
        setClickedDateIndex(dateIndex)
    }

    const chooseTime = (timeIndex) => {
        setClickedTimeIndex(timeIndex)
    }

    function useReadHours() {
        useEffect(() => {
            if (availableDates && availableDates?.length !== 0 && clickedDateIndex !== (-1)) {
                getHours()
            }
        }, [clickedDateIndex]
        )

    }

    function useReadDates() {
        useEffect(() => {
            getDates()
        }, [])
    }

    async function getHours() {
        setIsLoading(true)
        try {
            const { data, error } = await supabase
                .from('available_dates')
                .select('available_hours')
                .eq('date', `${availableDates[clickedDateIndex]?.date}`)
                .single()

            if (error) throw error;

            if (data !== null) {
                if (data.available_hours !== null) {

                    const timeZoneAdjustedHours = data.available_hours.map(hour => {
                        // Zakładając, że 'hour' jest w formacie 'HH:mm'
                        let dateInWarsaw = moment.tz(`${availableDates[clickedDateIndex]?.date} ${hour}`, 'YYYY-MM-DD HH:mm', 'Europe/Warsaw');
                        return dateInWarsaw.clone().tz(timezone).format('HH:mm');
                       
                    }).sort();

                    setAvailableHours(timeZoneAdjustedHours)
                    setClickedTimeIndex(0)
                }
                else {
                    setAvailableHours([])
                    setClickedTimeIndex(-1)
                }
            }
            else if (data === null) {
                setAvailableHours([])
            }
            else {
                setAvailableHours([])
            }

        } catch (error) {
            setAvailableHours([])
        }
        setIsLoading(false)
    }

    async function getDates() {
        setIsLoading(true)
        try {
            const { data, error } = await supabase
                .from('available_dates')
                .select('date')

            if (error) throw error;

            if (data !== null) {
                const dataSorted = data.sort((a, b) => new Date(a.date) - new Date(b.date));
                setAvailableDates(dataSorted)
                setClickedDateIndex(0)
            }
            else if (data === null) {
                setAvailableDates([])
            }
            else {
                setAvailableDates([])
            }

        } catch (error) {
            setAvailableDates([])
        }
        setIsLoading(false)
    }

    useReadDates()
    useReadHours()

    const handleBack = () => {
        setSchedulingVisible(false)
    }

    return (
        <div className="component-calendar">
            <div className="component-calendar-inner">
                <div className="date-picker-bar-container">
                    <div className="date-picker-bar-wrapper">
                        {
                            availableDates.map((date, index) =>
                                <DateHolder key={index} date={date.date} chooseDate={chooseDate} clickedDateIndex={clickedDateIndex} index={index} />
                            )
                        }


                    </div>
                </div>
                <div className="time-picker-container">
                    {
                        isLoading ? <Spinner animation="border" className='loading-spinner' />
                            :
                            <div className="time-picker-wrapper">
                                {
                                    availableHours.length !== 0 ?
                                        availableHours.map((time, index) =>
                                            <TimeHolder key={index} time={time} chooseTime={chooseTime} clickedTimeIndex={clickedTimeIndex} index={index} />
                                        )

                                        :
                                        (availableDates && availableDates.length !== 0) ?
                                            <p>{t("components.calendar.no-available-hours")}</p> :
                                            <p>{t("components.calendar.no-dates")}</p>
                                }
                            </div>

                    }
                </div>
                <div className="button-back-container">
                    <Button className='button try small-med' onClick={handleBack}>{t("nav-buttons.back")}</Button>
                    <p>{(availableDates && availableDates.length !== 0 && (clickedDateIndex !== (-1))) ?
                        `${t("components.calendar.schedule-chosen")} ${moment(availableDates[clickedDateIndex].date).format('DD.MM.YYYY')}${clickedTimeIndex === (-1) ? '' : ','}` :
                        ''} {(availableHours && availableHours.length !== 0) ? availableHours[clickedTimeIndex] : ''}</p>
                </div>
            </div>
        </div>

    )
}

export default Calendar
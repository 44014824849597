import React, { useRef, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

import './StepFour.css'
import { useTranslation } from 'react-i18next'

const StepFour = ({ setNextDisabled, setClientMessage }) => {

  const [validMsg, setValidMsg] = useState(true)
  const messageRef = useRef()
  const { t } = useTranslation('translation')

  useEffect(() => {
    setNextDisabled(true)
  }, [])

  const msgRegex = /^[^\`<>&"{}|^~\\]{40,500}$/;

  const handleMsgChange = (e) => {
    setValidMsg(msgRegex.test(e.target.value))
    setNextDisabled(!msgRegex.test(e.target.value))
  }

  return (
    <div className="component-step step-four">
      <div className="component-step-inner">
        <Form className="form contact-form" noValidate>
          <Form.Group className="form-contact-group" id="form-contact-message">
            <Form.Label>{t("page-profile.view-prepare.prepare-step-four.question-label")}</Form.Label>
            <Form.Control isInvalid={!validMsg} as="textarea" rows={9} required ref={messageRef} placeholder={t("page-profile.view-prepare.prepare-step-four.placeholder")} minLength={40} maxLength={500} onChange={(event) => {
              setClientMessage(messageRef.current.value)
              handleMsgChange(event)
            }} />
            <Form.Control.Feedback type="invalid">
              {t("page-profile.view-prepare.prepare-step-four.feedback")}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default StepFour
import React from 'react'
import { Link } from 'react-router-dom'
import AccountIcon from '../../assets/img/icons/account_person.svg'
import './AccountMenu.css'
import { useAuth } from '../../context/AuthContext'
import { useTranslation } from 'react-i18next'

const AccountMenu = () => {

    const { currentUser } = useAuth()
    const { t } = useTranslation('translation')

    return (
        <Link to={currentUser ? "/konto/moje-uslugi" : "/logowanie"}>
            <div className="component-account-menu">
                <div className="component-account-menu-inner">

                    {currentUser ? <><img src={AccountIcon} alt='account icon' /><p>{t("components.account-menu.panel")}</p></>
                        :
                        <><img src={AccountIcon} alt='account icon' /><p>{t("components.account-menu.login")}</p></>
                    }
                </div>
            </div>
        </Link >
    )

}

export default AccountMenu
import React, { useState, useRef, useEffect } from 'react'
import { Form, Alert } from 'react-bootstrap'
import './InvoiceDataForm.css'
import { useAuth } from '../../../../context/AuthContext'
import Button from '../../../../components/Button/Button'
import { useDatabaseUserContext } from '../../../../context/DatabaseUserContext'
import Spinner from 'react-bootstrap/Spinner'
import phonePrefixOptions from '../../../../data/phonePrefixOptions'
import phonePrefixOptionsEN from '../../../../data/phonePrefixOptionsEN'
import { useNavigate, useLocation } from 'react-router-dom'
import SimpleContainer from '../../../SimpleContainer/SimpleContainer'
import { useTranslation } from 'react-i18next'

const InvoiceDataForm = () => {

    const { t, i18n } = useTranslation('translation')
    const location = useLocation();
    const cityRef = useRef()
    const streetRef = useRef()
    const zipCodeRef = useRef()
    const companyZipCodeRef = useRef()
    const companyStreetRef = useRef()
    const companyCityRef = useRef()
    const companyNameRef = useRef()
    const companyNipRef = useRef()
    const nameRef = useRef()
    const surnameRef = useRef()
    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const { currentUser } = useAuth()
    const { useReadUserData, userData } = useDatabaseUserContext()
    const [validCompanyName, setValidCompanyName] = useState(true);
    const [validCompanyStreet, setValidCompanyStreet] = useState(true);
    const [validCompanyZipCode, setValidCompanyZipCode] = useState(true);
    const [validCompanyCity, setValidCompanyCity] = useState(true);
    const [validCompanyNip, setValidCompanyNip] = useState(true);
    const [validName, setValidName] = useState(true)
    const [validSurname, setValidSurname] = useState(true)
    const [validStreet, setValidStreet] = useState(true);
    const [validZipCode, setValidZipCode] = useState(true);
    const [validCity, setValidCity] = useState(true);
    const [clientType, setClientType] = useState("private");
    const [companyCountryName, setCompanyCountryName] = useState('Polska')
    const [countryName, setCountryName] = useState('Polska')
    const navigate = useNavigate()
    const { id, name } = location?.state || {}
    useReadUserData(currentUser?.id)
    const timeoutIdRef = useRef(null);
    const [lastInvoiceSent, setLastInvoiceSent] = useState(
        () => Number(localStorage.getItem('lastInvoiceSent')) || null
    );

    const lists = {
        phonePrefixOptions,
        phonePrefixOptionsEN
    };

    const streetRegex = /^[^\`<>&"{}|^~\\]{2,}$/;
    const companyNameRegex = /^[^\`<>&"{}|^~\\]{2,}$/;
    const nameRegex = /^[^\`<>&"{}|^~\\0-9]+$/;
    const surnameRegex = /^[^\`<>&"{}|^~\\0-9]+$/;
    const cityRegex = /^[^\`<>&"{}|^~\\0-9]+$/;
    const zipCodeRegex = /^[a-zA-Z0-9\s\-]{3,}$/;
    const nipRegex = /^(?=(?:.{7,14}$))(?:\d[A-Z]\d{6,12}|[A-Z]\d{5,12}|[A-Z]{1,2}\d{5,12}|\d{6,8}[A-Z]|\d{6,12}|\d{9}[A-Z]\d{2}|\d[A-Z]\d{6,8}[A-Z]|\d[A-Z]{2}\d{5,12}|[A-Z]\d{6,10}[A-Z])$/;

    const handleCompanyNameChange = (event) => {
        const value = event.target.value;
        setValidCompanyName(companyNameRegex.test(value));
    };

    const handleCompanyStreetChange = (event) => {
        const value = event.target.value;
        setValidCompanyStreet(streetRegex.test(value));
    };

    const handleCompanyZipCodeChange = (event) => {
        const value = event.target.value;
        setValidCompanyZipCode(zipCodeRegex.test(value));
    };

    const handleCompanyCityChange = (event) => {
        const value = event.target.value;
        setValidCompanyCity(cityRegex.test(value));
    };

    const handleCompanyNipChange = (event) => {
        const value = event.target.value;
        setValidCompanyNip(nipRegex.test(value));
    };

    const handleStreetChange = (event) => {
        const value = event.target.value;
        setValidStreet(streetRegex.test(value));
    };

    const handleZipCodeChange = (event) => {
        const value = event.target.value;
        setValidZipCode(zipCodeRegex.test(value));
    };

    const handleCityChange = (event) => {
        const value = event.target.value;
        setValidCity(cityRegex.test(value));
    };

    const handleNameChange = (event) => {
        const value = event.target.value;
        setValidName(nameRegex.test(value));
    };

    const handleSurnameChange = (event) => {
        const value = event.target.value;
        setValidSurname(surnameRegex.test(value));
    };

    const handleCompanyCountryChange = (event) => {
        setCompanyCountryName(event.target.value);
    }

    const handleCountryChange = (event) => {
        setCountryName(event.target.value);
    }


    async function handleSubmit(e) {
        e.preventDefault()
        setErrorSuccess('')
        setError('')
        let client_data = {};
        const now = Date.now();

        if (lastInvoiceSent && now - lastInvoiceSent < 1 * 60 * 1000) {
            return setError(t("errors.try-again-later"));
        }

        if (clientType === 'private') {
            if ((nameRef.current.value).trim() === '' ||
                (surnameRef.current.value).trim() === '' ||
                (streetRef.current.value).trim() === '' ||
                (zipCodeRef.current.value).trim() === '' ||
                (cityRef.current.value).trim() === '' ||
                !validName || !validSurname || !validStreet || !validZipCode || !validCity) {

                return setError(t("forms.error.fill-data-error"));
            } else {
                client_data = {
                    name: nameRef.current.value,
                    surname: surnameRef.current.value,
                    street: streetRef.current.value,
                    zip_code: zipCodeRef.current.value,
                    city: cityRef.current.value,
                    country: countryName
                };
            }
        } else if (clientType === 'company') {
            if ((companyNameRef.current.value).trim() === '' ||
                (companyStreetRef.current.value).trim() === '' ||
                (companyZipCodeRef.current.value).trim() === '' ||
                (companyCityRef.current.value).trim() === '' ||
                (companyNipRef.current.value).trim() === '' ||
                !validCompanyName || !validCompanyStreet || !validCompanyZipCode ||
                !validCompanyCity || !validCompanyNip) {

                return setError(t("forms.error.fill-data-error"));
            } else {
                client_data = {
                    company_name: companyNameRef.current.value,
                    company_street: companyStreetRef.current.value,
                    company_zip_code: companyZipCodeRef.current.value,
                    company_city: companyCityRef.current.value,
                    company_country: companyCountryName,
                    company_nip: companyNipRef.current.value
                };
            }
        }

        setLoading(true)
        try {
            const response =
                await fetch("https://gainfps.com/api/send-invoice",
                    // await fetch("http://localhost:4747/api/send-invoice",

                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            order_id: id,
                            user_email: userData?.email,
                            package_name: name,
                            client_type: clientType,
                            client_data: client_data,
                            language: i18n?.language
                        }),
                    })

            if (!response.ok) {
                throw new Error("Network response error.");
            }

            setErrorSuccess(t("page-profile.view-invoice-data.invoice-data-form.invoice-sent"))
            handleTimeout()
            setLastInvoiceSent(now);
            localStorage.setItem('lastInvoiceSent', now.toString());
        } catch (error) {
            setError(t("page-profile.view-invoice-data.invoice-data-form.invoice-error"))

        } finally {
            setLoading(false)
        }

    }

    const handleTimeout = () => {
        timeoutIdRef.current = setTimeout(() => {
            navigate('/konto/moje-uslugi');
        }, 4000);
    }

    const handleClientTypeChange = (e) => {
        setClientType(e.target.value);
    }

    function handleBack() {
        navigate('/konto/historia-zamowien/')
    }

    useEffect(() => {
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, []);

    return (
        <>

            {location.state ?
                <SimpleContainer>
                    <div className="component-invoice-data-form">
                        <div className="component-invoice-data-form-inner">
                            <Form onSubmit={handleSubmit} className="form invoice-data-form" noValidate>
                                {error && <Alert variant="danger">{error}</Alert>}
                                {errorSuccess && <Alert variant="success">{errorSuccess}</Alert>}
                                <Form.Group className="form-invoice-data-group" id="form-invoice-data-type">
                                    <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.client-type-label")}</Form.Label>
                                    <Form.Select onChange={handleClientTypeChange} className='status-select' value={clientType}>
                                        <option value="private">{t("page-profile.view-invoice-data.invoice-data-form.client-type-private")}</option>
                                        <option value="company">{t("page-profile.view-invoice-data.invoice-data-form.client-type-company")}</option>
                                    </Form.Select>
                                </Form.Group>
                                {
                                    clientType === "company" ?
                                        <>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-company-name">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.company-name-label")}</Form.Label>
                                                <Form.Control ref={companyNameRef} isInvalid={!validCompanyName} onChange={handleCompanyNameChange} id='form-invoice-input-company-name' className={(userData && currentUser?.email) ? `` : `loading`} defaultValue={''} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-company-name-placeholder")} maxLength={40} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.company-name-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-company-street">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.address-label")}</Form.Label>
                                                <Form.Control ref={companyStreetRef} isInvalid={!validCompanyStreet} onChange={handleCompanyStreetChange} id='form-invoice-input-company-street' className={(userData && currentUser?.email) ? `` : `loading`} defaultValue={''} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-form.address-placeholder")} maxLength={40} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.address-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-company-zip">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.zip-label")}</Form.Label>
                                                <Form.Control ref={companyZipCodeRef} isInvalid={!validCompanyZipCode} onChange={handleCompanyZipCodeChange} id='form-invoice-input-company-zip-code' className={(userData && currentUser?.email) ? `` : `loading`} defaultValue={''} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-form.zip-placeholder")} maxLength={10} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.zip-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-company-city">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.city-label")}</Form.Label>
                                                <Form.Control ref={companyCityRef} isInvalid={!validCompanyCity} onChange={handleCompanyCityChange} id='form-invoice-input-company-city' className={(userData && currentUser?.email) ? `` : `loading`} defaultValue={''} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-form.city-placeholder")} maxLength={40} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.city-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-company-country">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.region-label")}</Form.Label>
                                                <Form.Select onChange={handleCompanyCountryChange} className='status-select' value={companyCountryName}>
                                                    {
                                                        lists[t("page-profile.view-invoice-data.invoice-data-form.phone-prefix-list")].map((option, index) => {
                                                            const countryName = option.label.split(' ')[0];
                                                            return <option key={index} value={countryName} label={countryName}>{countryName}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-company-nip">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.nip-label")}</Form.Label>
                                                <Form.Control ref={companyNipRef} isInvalid={!validCompanyNip} onChange={handleCompanyNipChange} id='form-invoice-input-company-nip' className={(userData && currentUser?.email) ? `` : `loading`} defaultValue={''} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-form.nip-placeholder")} maxLength={20} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.nip-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </> :
                                        <>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-name">
                                                <Form.Label>{t("forms.label.name")}</Form.Label>
                                                <Form.Control ref={nameRef} isInvalid={!validName} onChange={handleNameChange} id='form-invoice-input-name' type="text" defaultValue={userData?.first_name ?? ''} required placeholder={t("forms.placeholder.name")} className={(userData && currentUser?.email) ? `` : `loading`} maxLength={20} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("forms.feedback.wrong-name")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-surname">
                                                <Form.Label>{t("forms.label.surname")}</Form.Label>
                                                <Form.Control ref={surnameRef} isInvalid={!validSurname} onChange={handleSurnameChange} id='form-invoice-input-surname' type="text" defaultValue={userData?.last_name ?? ''} required placeholder={t("forms.placeholder.surname")} className={(userData && currentUser?.email) ? `` : `loading`} maxLength={20} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("forms.feedback.wrong-surname")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-street">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.address-label")}</Form.Label>
                                                <Form.Control ref={streetRef} isInvalid={!validStreet} onChange={handleStreetChange} id='form-invoice-input-street' defaultValue={''} className={(userData && currentUser?.email) ? `` : `loading`} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-form.address-placeholder")} maxLength={40} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.address-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-zip-code">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.zip-label")}</Form.Label>
                                                <Form.Control ref={zipCodeRef} isInvalid={!validZipCode} onChange={handleZipCodeChange} id='form-invoice-input-zip-code' defaultValue={''} className={(userData && currentUser?.email) ? `` : `loading`} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-form.zip-placeholder")} maxLength={10} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.zip-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-city">
                                                <Form.Label> {t("page-profile.view-invoice-data.invoice-data-form.city-label")}</Form.Label>
                                                <Form.Control ref={cityRef} isInvalid={!validCity} onChange={handleCityChange} id='form-invoice-input-city' defaultValue={''} className={(userData && currentUser?.email) ? `` : `loading`} type="text" placeholder={t("page-profile.view-invoice-data.invoice-data-form.city-placeholder")} maxLength={40} />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("page-profile.view-invoice-data.invoice-data-form.city-feedback")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-invoice-data-group" id="form-invoice-data-country">
                                                <Form.Label>{t("page-profile.view-invoice-data.invoice-data-form.region-label")}</Form.Label>
                                                <Form.Select onChange={handleCountryChange} className='status-select' value={countryName}>
                                                    {
                                                        lists[t("page-profile.view-invoice-data.invoice-data-form.phone-prefix-list")].map((option, index) => {
                                                            const countryName = option.label.split(' ')[0];
                                                            return <option key={index} value={countryName}>{countryName}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </>
                                }
                                <Button type='submit' className="try hover2" disabled={loading || errorSuccess}>
                                    {loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("page-profile.view-invoice-data.invoice-data-form.send-invoice-button")}
                                </Button>
                                <Button className="try hover2" disabled={loading} onClick={handleBack}>
                                    {t("nav-buttons.back")}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </SimpleContainer>
                :
                <Alert variant="danger">{t("errors.page-error")}</Alert>
            }
        </>
    )
}

export default InvoiceDataForm


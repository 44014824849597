export const phonePrefixOptionsEN = [
    { value: '+355', label: 'Albania (+355)' },
    { value: '+376', label: 'Andorra (+376)' },
    { value: '+374', label: 'Armenia (+374)' },
    { value: '+43', label: 'Austria (+43)' },
    { value: '+994', label: 'Azerbaijan (+994)' },
    { value: '+32', label: 'Belgium (+32)' },
    { value: '+387', label: 'Bosnia and Herzegovina (+387)' },
    { value: '+359', label: 'Bulgaria (+359)' },
    { value: '+385', label: 'Croatia (+385)' },
    { value: '+357', label: 'Cyprus (+357)' },
    { value: '+382', label: 'Montenegro (+382)' },
    { value: '+420', label: 'Czech Republic (+420)' },
    { value: '+45', label: 'Denmark (+45)' },
    { value: '+372', label: 'Estonia (+372)' },
    { value: '+358', label: 'Finland (+358)' },
    { value: '+33', label: 'France (+33)' },
    { value: '+30', label: 'Greece (+30)' },
    { value: '+299', label: 'Greenland (+299)' },
    { value: '+995', label: 'Georgia (+995)' },
    { value: '+34', label: 'Spain (+34)' },
    { value: '+31', label: 'Netherlands (+31)' },
    { value: '+353', label: 'Ireland (+353)' },
    { value: '+354', label: 'Iceland (+354)' },
    { value: '+1', label: 'Canada (+1)' },
    { value: '+370', label: 'Lithuania (+370)' },
    { value: '+371', label: 'Latvia (+371)' },
    { value: '+352', label: 'Luxembourg (+352)' },
    { value: '+356', label: 'Malta (+356)' },
    { value: '+52', label: 'Mexico (+52)' },
    { value: '+373', label: 'Moldova (+373)' },
    { value: '+377', label: 'Monaco (+377)' },
    { value: '+49', label: 'Germany (+49)' },
    { value: '+47', label: 'Norway (+47)' },
    { value: '+48', label: 'Poland (+48)' },
    { value: '+351', label: 'Portugal (+351)' },
    { value: '+7', label: 'Russia (+7)' },
    { value: '+40', label: 'Romania (+40)' },
    { value: '+378', label: 'San Marino (+378)' },
    { value: '+381', label: 'Serbia (+381)' },
    { value: '+421', label: 'Slovakia (+421)' },
    { value: '+386', label: 'Slovenia (+386)' },
    { value: '+41', label: 'Switzerland (+41)' },
    { value: '+46', label: 'Sweden (+46)' },
    { value: '+380', label: 'Ukraine (+380)' },
    { value: '+1', label: 'USA (+1)' },
    { value: '+36', label: 'Hungary (+36)' },
    { value: '+44', label: 'United Kingdom (+44)' },
    { value: '+39', label: 'Italy (+39)' },
    { value: '+298', label: 'Faroe Islands (+298)' },
];

export default phonePrefixOptionsEN;


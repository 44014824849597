import React from 'react'
import "./FragmentTopBanner.scss"
import PersonalData from "../PersonalData/PersonalData"
import { useAuth } from "../../../context/AuthContext"
import { useDatabaseUserContext } from '../../../context/DatabaseUserContext'
import PersonalDataSceletor from '../PersonalDataSceletor/PersonalDataSceletor'
import { useTranslation } from 'react-i18next'

const FragmentTopBanner = () => {

    const { currentUser } = useAuth()
    const { userData } = useDatabaseUserContext()
    let emailUser = ""
    const { t } = useTranslation('translation')
    return (
        <>
            <div className='top-banner-wrapper'>
                <div className='top-banner-personal'>
                    {
                        (userData && currentUser?.email) ? <PersonalData customName={userData?.first_name || t("page-profile.fragment-top-banner.top-banner-personal")} email={userData?.email} /> : <PersonalDataSceletor customName={t("page-profile.fragment-top-banner.top-banner-personal")} email={emailUser} />
                    }
                </div>
            </div>
        </>

    )
}

export default FragmentTopBanner


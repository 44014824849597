import React, { useRef, useState } from 'react'
import './AdminViewSvcAdd.css'
import AdminBackButton from '../AdminBackButton/AdminBackButton'
import Button from '../../../../Button/Button'
import adminAddService from '../../admin-functions/admin-svc-add'
import { Alert } from 'react-bootstrap'
import Spinner from "react-bootstrap/Spinner";

const statusOptions = ['prepare', 'awaiting', 'start', 'ready', 'completed'];
const nameOptions = ['STANDARD', 'PRO'];

const AdminViewSvcAdd = ({ setCurrentView }) => {

  const [error, setError] = useState('')
  const [errorSuccess, setErrorSuccess] = useState('')
  const [loading, setLoading] = useState(false)

  const emailRef = useRef()
  const svcNameRef = useRef()
  const svcStatusRef = useRef()
  const svcDateRef = useRef()
  const svcTimeRef = useRef()

  const [selectedStatus, setSelectedStatus] = useState('prepare');
  const [selectedName, setSelectedName] = useState('STANDARD');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleNameChange = (event) => {
    setSelectedName(event.target.value);
  };

  return (
    <div className="admin-view-svc-add-container">
      {error && <Alert variant="danger">{error}</Alert>}
      {errorSuccess && <Alert variant='success'>{errorSuccess}</Alert>}
      <div className="admin-view-svc-add-wrapper">
        <div className="admin-svc-add-bar-container">
          <div className="admin-svc-add-bar-wrapper">
            <div className="admin-svc-add-bar-inputs">
              <div className="email-input-wrapper from-group">
                <label htmlFor='add-svc-email' className='form-label'>E-mail:</label>
                <input ref={emailRef} className='form-control' type='text' placeholder='adres e-mail' id='add-svc-email' name='add-svc-email' />
              </div>
              <div className="svc-name-input-wrapper form-group">
                <label htmlFor='add-svc-name' className='form-label'>Nazwa:</label>
                <select
                  ref={svcNameRef}
                  className='form-control'
                  id='add-svc-name'
                  name='add-svc-name'
                  value={selectedName}
                  onChange={handleNameChange}
                >
                  {nameOptions.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="svc-status-input-wrapper form-group">
                <label htmlFor='add-svc-status-select' className='form-label'>Status:</label>
                <select
                  ref={svcStatusRef}
                  className='form-control'
                  id='add-svc-status-select'
                  name='add-svc-status-select'
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  {statusOptions.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <div className="svc-date-input-wrapper form-group">
                <label htmlFor='add-svc-date-input' className='form-label'>Data:</label>
                <input ref={svcDateRef} className='form-control' type='text' placeholder='DD.MM.YYYY' id='add-svc-date-input' name='add-svc-date-input' />
              </div>
              <div className="svc-time-input-wrapper form-group">
                <label htmlFor='add-svc-time-input' className='form-label'>Czas:</label>
                <input ref={svcTimeRef} className='form-control' type='text' placeholder='hh:mm' id='add-svc-time-input' name='add-svc-time-input' />
              </div>
            </div>

            <Button className='try medium' disabled={loading} onClick={async () => {
              setLoading(true)
              setError('')
              setErrorSuccess('')
              const { data, error } = await adminAddService(emailRef.current.value, svcNameRef.current.value, svcStatusRef.current.value, svcDateRef.current.value, svcTimeRef.current.value)
              if (error) {
                setError(error?.message)
              } else {
                setErrorSuccess('Usługa została dodana poprawnie.')
              }
              setLoading(false)
            }}>{loading ? <Spinner animation="border" className='loading-spinner small black' /> : 'Dodaj usługę'}</Button>
            {/* <Button className='try medium' onClick={() => { supabaseAdd() }}>TEST SUPABASE</Button> */}
            <AdminBackButton setCurrentView={() => { setCurrentView('functions') }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminViewSvcAdd
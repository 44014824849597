import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PrepareStep from '../PrepareStep/PrepareStep';
import StepTwo from '../prepare-steps/StepTwo/StepTwo';
import StepThree from '../prepare-steps/StepThree/StepThree';
import StepFour from '../prepare-steps/StepFour/StepFour';
import StepFive from '../prepare-steps/StepFive/StepFive';
import Button from '../../Button/Button'
import { Alert, Spinner } from 'react-bootstrap'
import { useDatabaseUserContext } from '../../../context/DatabaseUserContext'
import './ViewPrepare.css'
import StepSix from '../prepare-steps/StepSix/StepSix';
import sendMail from '../../../utils/sendMail';
import { useTranslation } from 'react-i18next';

const ViewPrepare = () => {
    const { t, i18n } = useTranslation('translation')
    const [stepCounter, setStepCounter] = useState(0)
    const totalSteps = 5;
    const location = useLocation()
    const { id, name } = location?.state || {}
    const { writePrepareDetails, updateBookedServices } = useDatabaseUserContext()
    const [error, setError] = useState('')
    const [complete, setComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [nextDisabled, setNextDisabled] = useState(false)
    const [secondsRemaining, setSecondsRemaining] = useState()
    const { userData } = useDatabaseUserContext()
    const intervalIdRef = useRef(null);

    // states that will be used on final preparing when sending data to backend

    const [clientMessage, setClientMessage] = useState('');
    const [prepareClientOption, setPrepareClientOption] = useState('')
    const [prepareContactOption, setPrepareContactOption] = useState('')
    const [prepareDetails, setPrepareDetails] = useState({
        onedrive: '',
        geforce_exp: '',
        xbox: ''
    })

    ////////////////////////////////////////////////////////////////////////////

    const allSteps = [
        <StepTwo setPrepareClientOption={setPrepareClientOption} setNextDisabled={setNextDisabled} />,
        <StepThree setNextDisabled={setNextDisabled} setPrepareDetails={setPrepareDetails} prepareDetails={prepareDetails} />,
        <StepFour setNextDisabled={setNextDisabled} setClientMessage={setClientMessage} />,
        <StepFive setNextDisabled={setNextDisabled} setPrepareContactOption={setPrepareContactOption} />,
        <StepSix />
    ]

    const navigate = useNavigate();

    function handleBack() {
        setError('')
        if (stepCounter > 0) {
            setStepCounter(stepCounter - 1)
        }
        else {
            navigate(-1)
        }
    }

    async function handleNext() {
        setLoading(true)
        setError('')
        if (stepCounter < totalSteps - 1) {
            setStepCounter(stepCounter + 1)
        }
        else if (stepCounter === totalSteps - 1) {
            if (complete) {
                if (intervalIdRef.current) {
                    clearInterval(intervalIdRef.current);
                }
                navigate('/konto/moje-uslugi/')
            }
            else {
                try {
                    const { data: prepareData, error: prepareError } = await writePrepareDetails(clientMessage, prepareClientOption, prepareDetails, prepareContactOption)

                    if (prepareError) {
                        setLoading(false)
                        setError(prepareError?.message)
                        return;
                    }
                    else {
                        const { data, error } = await updateBookedServices(id, prepareData.id, 'awaiting')

                        if (error) {
                            setError(error?.message)
                            return;
                        } else {
                            // wywolanie funkcji bez 'await' zeby uzytkownik nie musial czekac na wyslanie maial
                            sendMail({
                                author: 'noreply', receiver: userData?.email, subject: `${prepareData?.prepare_option ? (prepareData?.prepare_option === 'podstawowe' ? t("page-profile.view-prepare.base-prepare") : t("page-profile.view-prepare.advanced-prepare")) : t("page-profile.view-prepare.advanced-prepare")}`, service_name: name,
                                template: `${prepareData?.prepare_option ? (prepareData?.prepare_option === 'podstawowe' ? t("page-profile.view-prepare.base-prepare-template") : t("page-profile.view-prepare.advanced-prepare-template")) : t("page-profile.view-prepare.advanced-prepare-template")}`, language: i18n?.language
                            })
                        }

                    }
                } catch (error) {
                    setError(error.message)
                }
            }
            setComplete(true)
            setLoading(false)

            let remainingSeconds = 5;
            setSecondsRemaining(remainingSeconds)
            // Rozpoczęcie odliczania co sekundę
            intervalIdRef.current = setInterval(() => {
                remainingSeconds--;
                setSecondsRemaining(remainingSeconds);

                if (remainingSeconds <= 0) {
                    clearInterval(intervalIdRef.current);
                    navigate('/konto/moje-uslugi/');
                }
            }, 1000);
        }
        // wysylanie danych do bazy danych
        setLoading(false)
    }

    // Używamy useEffect do czyszczenia interwału, gdy komponent zostaje odmontowany
    useEffect(() => {
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, []);

    return (
        <> {
            location.state ?
                <div className="view-prepare-container">
                    <div className="view-prepare-wrapper">
                        <div className="header-container">
                            <div className="counter-wrapper">
                                <h4>{t("page-profile.view-prepare.step-label")} {stepCounter + 1}/{totalSteps}</h4>
                                {error ? <Alert variant="danger">{error}</Alert> : <h5>{t("page-profile.view-prepare.service-id-label")} #{id}</h5>}
                            </div>
                        </div>
                        <div className={(loading || complete) ? "step-view-container center" : "step-view-container"}>
                            {
                                complete ? (
                                    error ? <h4>{t("errors.server-error-occured")}</h4> :
                                        <div className="step-view-text-container">
                                            <h4>{t("page-profile.view-prepare.prepare-success")}</h4>
                                            <p>{t("messages.returning-in")} {secondsRemaining}...</p>
                                        </div>

                                ) : (
                                    loading ?
                                        <div className="spinner-container">
                                            <Spinner animation="border" className='loading-spinner' />
                                        </div>
                                        :
                                        <PrepareStep>
                                            {
                                                allSteps[stepCounter]
                                            }
                                        </PrepareStep>
                                )
                            }
                        </div>
                        <div className="bottom-nav-container">
                            <div className="bottom-nav-left">
                                <Button className='button try medium' onClick={handleBack} disabled={loading ? loading : complete}>
                                    {stepCounter === 0 ? t("nav-buttons.back") : t("nav-buttons.return")}
                                </Button>
                            </div>
                            <div className="bottom-nav-right">
                                {stepCounter === 0 ?
                                    <Button className='button try medium' onClick={handleNext} disabled={
                                        loading ? loading : nextDisabled
                                    }>
                                        {t("nav-buttons.next")}
                                    </Button>

                                    :
                                    <Button className='button try medium' onClick={handleNext} disabled={loading ? loading : nextDisabled}>
                                        {stepCounter === 5 ? t("nav-buttons.end") : t("nav-buttons.next")}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Alert variant="danger">{t("errors.page-error")}</Alert>
        }</>
    )
}

export default ViewPrepare
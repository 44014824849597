import React, { useState, useEffect, useRef } from 'react'
import './StepFive.css'
import { Image } from 'react-bootstrap';
import MessengerLogo from '../../../../assets/img/socials/messenger-logo.svg'
import DiscordLogo from '../../../../assets/img/socials/discord-logo.svg'
import SimpleContainer from '../../../SimpleContainer/SimpleContainer';
import ArrowBackPurple from '../../../../assets/img/icons/arrow_back_purple_36dp.svg';
import ArrowBackPink from '../../../../assets/img/icons/arrow_back_pink_36dp.svg';
import { Trans, useTranslation } from 'react-i18next';


const StepFive = ({ setNextDisabled, setPrepareContactOption }) => {

    const { t } = useTranslation("translation")
    const [selectedOption, setSelectedOption] = useState('')
    const [loading, setLoading] = useState(true)
    const usernameRef = useRef()
    useEffect(() => {
        setNextDisabled(true)
    }, [])

    function handleClick(name) {
        setPrepareContactOption(name)
    }

    const handleOptionChange = (groupName, optionName) => {
        setPrepareContactOption((prevState) => {
            return {
                ...prevState,
                [groupName]: optionName
            };
        });

    };

    const checkIfEmpty = () => {
        if ((usernameRef.current.value).trim() === '') {
            setNextDisabled(true)
        } else {
            setNextDisabled(false)
        }
    }

    const onLoad = () => {
        setLoading(false)
    }


    return (
        <div className="component-step step-five">
            <div className="component-step-inner">
                {!selectedOption &&
                    <div className="description-container">
                        <p>{t("page-profile.view-prepare.prepare-step-five.main-description")} <br />{t("page-profile.view-prepare.prepare-step-five.sub-description")}</p>
                    </div>
                }
                <div className="options-container">
                    {
                        selectedOption === '' &&
                        <>

                            <div className={`component-simple-container discord button`} onClick={() => {
                                setSelectedOption('discord')
                                handleClick({ form: 'discord', username: '' })
                            }}>
                                <div className="component-simple-container-inner">
                                    <div className="title-wrapper">

                                        <div className={loading ? "image-wrapper loading" : "image-wrapper"}>
                                            <Image src={DiscordLogo} alt='discord logo' onLoad={onLoad} />
                                        </div>
                                        <h3>DISCORD</h3>
                                    </div>
                                </div >
                            </div>
                            <div className={`component-simple-container messenger button`} onClick={() => {
                                setSelectedOption('messenger')
                                handleClick({ form: 'messenger' })
                                setNextDisabled(false)
                            }}>
                                <div className="component-simple-container-inner">
                                    <div className="title-wrapper">
                                        <div className={loading ? "image-wrapper loading" : "image-wrapper"}>
                                            <Image src={MessengerLogo} alt='messenger logo' />
                                        </div>
                                        <h3>MESSENGER</h3>
                                    </div>
                                </div >
                            </div>
                        </>
                    }
                    {
                        selectedOption === 'discord' &&
                        <SimpleContainer variant={'discord'} className={'center'}>
                            <ol>
                                <li>
                                    {t("page-profile.view-prepare.prepare-step-five.dc-name-label")}<br />
                                    <input formNoValidate type='text' placeholder='player_4212' ref={usernameRef} maxLength={22} minLength={4} onChange={(event) => {
                                        handleOptionChange('username', usernameRef.current.value)
                                        checkIfEmpty()
                                    }} />
                                </li>

                                <li>
                                    <Trans i18nKey={"page-profile.view-prepare.prepare-step-five.add-gainfps-desc"}>Dodaj GainFPS na Discordzie
                                        nazwa: <span style={{ color: 'rgb(88, 101, 242)' }}>gainfps</span></Trans>
                                </li>
                                <div className="bottom-flex">
                                    <a href="https://www.discord.com/users/1137702158435897444" target="_blank" rel="noopener noreferrer">🔗 {t("page-profile.view-prepare.prepare-step-five.add-gainfps-label")}</a>
                                    <div className='clickable' onClick={() => {
                                        setSelectedOption('')
                                        setNextDisabled(true)
                                    }}><Image src={ArrowBackPurple} alt='arrow back purple' /></div>
                                </div>
                            </ol>
                        </SimpleContainer>}

                    {selectedOption === 'messenger' &&
                        < SimpleContainer variant={'messenger'} className={'center'}>
                            <ol>
                                <li>
                                    <Trans i18nKey={"page-profile.view-prepare.prepare-step-five.msg-description"}>
                                        Kliknij w poniższy odnośnik i skorzystaj z gotowej wiadomości: <span style={{ fontStyle: 'italic', fontFamily: 'Gilroy-regular, sans-serif', color: 'rgb(0,224,255)' }}>Wybrałem messenger jako formę szybkiego kontaktu</span>
                                    </Trans>
                                    <a href='https://m.me/gainfps' target="_blank" rel="noopener noreferrer">🔗 {t("page-profile.view-prepare.prepare-step-five.msg-send-msg")}</a>
                                    <br /><br />
                                    {t("page-profile.view-prepare.prepare-step-five.msg-or")}
                                    <br />
                                    {t("page-profile.view-prepare.prepare-step-five.msg-copy-and-write")}<br />
                                    <a href='https://www.facebook.com/gainfps/' target="_blank" rel="noopener noreferrer">🔗 {t("page-profile.view-prepare.prepare-step-five.msg-fb")}</a>
                                </li>

                                <div className="bottom-flex right">
                                    <div className='clickable' onClick={() => {
                                        setSelectedOption('')
                                        setNextDisabled(true)
                                    }}><Image src={ArrowBackPink} alt='arrow back ping' /></div>
                                </div>

                            </ol>
                        </SimpleContainer>
                    }
                </div>
            </div>
        </div >
    )
}

export default StepFive
import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import "./TopNavigation.css";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Logotype from '../../assets/img/gainfps/logotype-color.svg'
import AccountMenu from '../AccountMenu/AccountMenu';
import MobileMenu from '../MobileMenu/MobileMenu';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next'
import { navReviewsClickedEvent } from '../../data/pixel-events/navReviewsClickedEvent';
import { useAuth } from '../../context/AuthContext';
import { hashData } from '../../utils/hashData';

const TopNavigation = ({ isBlack, className }) => {

    const { currentUser } = useAuth()
    const { t, i18n } = useTranslation();
    const [isServicesOpen, setIsServicesOpen] = useState(false)
    const [hoveringDropdown, setHoveringDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleMouseEnter = () => {
        setIsServicesOpen(true);
    };

    const handleMouseLeave = () => {
        if (!hoveringDropdown) {
            setIsServicesOpen(false);
        }
    };

    const handleDropdownMouseEnter = () => {
        setHoveringDropdown(true);
        setIsServicesOpen(true);
    };

    const handleDropdownMouseLeave = () => {
        setHoveringDropdown(false);
        setIsServicesOpen(false);
    };

    return (
        <>
            <header>
                <div className={`component-top-navigation ${isBlack ? "black" : ""} ${className ? className : ''}`}>
                    <div className="component-top-navigation-inner">
                        <Container>
                            <Row>
                                <Col xl={2} lg={2} md={4} sm={8} className='col center'>

                                    <div className="logotype-wrapper">
                                        <Link to='/'>
                                            <img src={Logotype} alt='logotype' />
                                        </Link>
                                    </div>
                                </Col>
                                <Col xl={10} lg={10} md={8} sm={4} className='col center end'>
                                    <div className="list-container">
                                        <ul>
                                            <li className='nav-highlighted'>
                                                <Link to="/gaming-mode">{t("gaming-mode").toUpperCase()}</Link>
                                            </li>
                                            <li
                                                ref={dropdownRef}
                                            >
                                                <Dropdown show={isServicesOpen} className="nav-dropdown" autoClose="outside">
                                                    <Dropdown.Toggle as="a" className="nav-link" href="#" role="button" onMouseEnter={handleMouseEnter}>
                                                        {t("services").toUpperCase()}
                                                    </Dropdown.Toggle>
                                                    <div className="dropdown-menu-container" onMouseLeave={handleDropdownMouseLeave}>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as={Link} to="/uslugi">
                                                                {t("package-description")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as={Link} to="/proserwis">
                                                                {t("pro-serwis")}
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                            </li>
                                            <li>
                                                <HashLink id='nav-reviews-link' style={{ position: "relative", zIndex: "10001" }} onClick={() => navReviewsClickedEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id))} to='/#section-reviews'>{t("reviews").toUpperCase()}</HashLink>
                                            </li>
                                            <li>
                                                <Link to="/kontakt">{t("contact").toUpperCase()}</Link>
                                            </li>
                                            <li>
                                                <Button href="/zakup" name="btn-nav-purchase" id="btn-nav-purchase" className='try'>{t("purchase-buttons.buy-now")}</Button>
                                            </li>
                                            <li className='account-menu'>
                                                <AccountMenu />
                                            </li>
                                            <li>
                                                <LanguageSelector />
                                            </li>
                                        </ul>
                                    </div>

                                    <MobileMenu />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </header>
        </>
    )
}

TopNavigation.defaultProps = {
    isBlack: false
}

export default TopNavigation
async function sendOTP({ phone_number, channel }) {
    try {
        const response = 
        await fetch('https://gainfps.com/api/send_otp_code', {
        // await fetch('http://localhost:4747/api/send_otp_code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone_number, channel })
        })

        if (!response.ok) {
            throw new Error('Wystąpił błąd podczas wysyłania kodu OTP.');
        }

        const data = await response.json();

        return { data: data, error: null };

    } catch (error) {
        return { data: null, error: error }
    }

}

async function verifyOTP({ phone_number, code }) {
    try {
        const response = await 
        fetch('https://gainfps.com/api/verify_otp_code', {
        // fetch('http://localhost:4747/api/verify_otp_code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone_number, code })
        })

        if (!response.ok) {
            throw new Error('Wystąpił błąd podczas weryfikacji kodu OTP.');
        }
        const data = await response.json();
        if (data?.error) {
            throw data.error
        }
        return { data: data, error: null };

    } catch (error) {
        return { data: null, error: error }
    }
}

export { sendOTP, verifyOTP }
import React, { useState, useRef } from 'react';
import { Form, Alert } from 'react-bootstrap';
import './ContactForm.css';
import { useAuth } from '../../../context/AuthContext';
import Button from '../../../components/Button/Button';
import { useEffect } from 'react';
import { useDatabaseUserContext } from '../../../context/DatabaseUserContext';
import Spinner from "react-bootstrap/Spinner";
import sendMail from '../../../utils/sendMail';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t, i18n } = useTranslation('translation')
    const emailRef = useRef()
    const phoneRef = useRef()
    const nameRef = useRef()
    const subjectRef = useRef()
    const messageRef = useRef()
    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const [validMsg, setValidMsg] = useState(true)
    const [validSubject, setValidSubject] = useState(true)
    const { currentUser } = useAuth()
    const { useReadUserData, userData } = useDatabaseUserContext()
    const [lastSent, setLastSent] = useState(
        () => Number(localStorage.getItem('lastSent')) || null
    );
    useReadUserData(currentUser?.id)


    useEffect(() => {
        if (userData) {
            if ((nameRef.current.value).trim() === '' ||
                (phoneRef.current.value).trim() === '' ||
                (emailRef.current.value).trim() === '') {
                setError(t("page-profile.contact-form.empty-userdata-error"))
            } else {
                setError('')
            }
        }
    }, [userData]
    );

    const handleSubjectChange = () => {
        const subject = subjectRef.current.value;

        if (subject.length < 8) {
            setValidSubject(false)
        } else {
            setValidSubject(true)
        }
    }

    const handleMsgChange = () => {
        const msg = messageRef.current.value;

        if (msg.length < 35) {
            setValidMsg(false)
        } else {
            setValidMsg(true)
        }
    }

    const transformToHTMLParagraphs = (text) => {
        return text.split('\n').map((segment, index) => `<p style="Margin:0;font-family:'lucida sans unicode','lucida grande',sans-serif;line-height:21px;letter-spacing:0;color:#cccccc;font-size:14px!important;margin-bottom: 20px;">${segment}</p>`).join('');
    };


    async function handleSubmit(e) {
        e.preventDefault()
        setError('')
        setErrorSuccess('')

        const now = Date.now();
        if (lastSent && now - lastSent < 5 * 60 * 1000) {
            return setError(t('forms.error.try-again-later'));
        }

        if (!(nameRef.current.value &&
            phoneRef.current.value &&
            emailRef.current.value)) {
            return setError(t("page-profile.contact-form.empty-userdata-error"))
        }

        if (!(messageRef.current.value && subjectRef.current.value &&
            validMsg && validSubject)) {
            return setError(t("page-profile.contact-form.fill-form-properly"))
        }

        const formattedMessage = transformToHTMLParagraphs(messageRef.current.value);

        const dataAdmin = {
            author: 'noreply',
            sender: emailRef.current.value,
            receiver: 'kontakt@gainfps.com',
            subject: `${subjectRef.current.value}`,
            full_name: nameRef.current.value,
            phone: phoneRef.current.value,
            message: formattedMessage,
            template: 'a_contactFormTemplate'
        };

        const dataUser = {
            author: 'noreply',
            sender: emailRef.current.value,
            receiver: emailRef.current.value,
            subject: `${subjectRef.current.value}`,
            full_name: nameRef.current.value,
            phone: phoneRef.current.value,
            message: formattedMessage,
            template: t("page-profile.contact-form.user-email-template"),
            language: i18n?.language
        }

        try {
            setError('')
            setErrorSuccess('')
            setLoading(true)
            await sendMail(dataAdmin)
            await sendMail(dataUser)
            setErrorSuccess(t("page-profile.contact-form.message-sent-properly"))
            setLastSent(now);
            localStorage.setItem('lastSent', now.toString());  // zapisujemy czas do localStorage
        } catch (error) {
            setError(t("page-profile.contact-form.message-error"))
        }

        setLoading(false)
    }

    return (
        <>
            <div className="component-contact-form">
                <div className="component-contact-form-inner">
                    <Form onSubmit={handleSubmit} className="form contact-form" noValidate>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {errorSuccess && <Alert variant='success'>{errorSuccess}</Alert>}
                        <Form.Group className="form-contact-group" id="form-contact-email">
                            <Form.Control type='email' readOnly disabled defaultValue={userData?.email ?? ''} placeholder='adres e-mail' ref={emailRef} className={(userData && currentUser?.email) ? `plaintext` : `plaintext loading`} maxLength={45} />
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-name">
                            <Form.Control type='text' readOnly placeholder='imię nazwisko' disabled defaultValue={`${(userData?.first_name ?? '') + (userData?.first_name ? ' ' : '') + (userData?.last_name ?? '')}`} ref={nameRef} className={(userData && currentUser?.email) ? `plaintext` : `plaintext loading`} maxLength={45} />
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-phone">
                            <Form.Control type='tel' readOnly placeholder='numer telefonu' disabled defaultValue={(userData?.phone_number_prefix && userData?.phone_number) ? `${userData?.phone_number_prefix} ${userData?.phone_number}` : ''} ref={phoneRef} className={(userData && currentUser?.email) ? `plaintext` : `plaintext loading`} maxLength={45} />
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-topic">
                            <Form.Label>{t("forms.label.subject")}</Form.Label>
                            <Form.Control type="text" ref={subjectRef} required placeholder={t("forms.placeholder.subject")} maxLength={45} onChange={handleSubjectChange} isInvalid={!validSubject} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-subject")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="form-contact-group" id="form-contact-message">
                            <Form.Label>{t("forms.label.message")}</Form.Label>
                            <Form.Control as="textarea" rows={3} required ref={messageRef} placeholder={t("page-profile.contact-form.message-placeholder")} minLength={30} maxLength={2000} onChange={handleMsgChange} isInvalid={!validMsg} />
                            <Form.Control.Feedback type="invalid">
                                {t("forms.feedback.wrong-message")}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button type='submit' className="try hover2" disabled={loading}>
                            {loading ? <Spinner animation="border" className='loading-spinner small black' /> : t("nav-buttons.send")}
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default ContactForm
export const sendEventToServer = async (url, eventName, eventData) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ eventName, eventData })
        });

        if (!response.ok) {
            throw new Error(`Error in sendEventToServer:`)
        }
    } catch (error) {

    }
};

import React from 'react'
import './SettingsFunctionsTable.css'

const SettingsFunctionsTable = ({ children, title }) => {
    return (
        <div className="component-settings-functions-table">
            <div className="component-settings-functions-table-inner">
                <div className="specific-functions-title">
                    <h3>{title}</h3>
                </div>
                {children}
            </div>
        </div>
    )
}

export default SettingsFunctionsTable
import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import TopNavigation from '../../components/TopNavigation/TopNavigation'
import "./Services.css"
import SectionContent from './Sections/SectionContent/SectionContent'
import SectionDescription from './Sections/SectionDescription/SectionDescription'
import SectionImplementation from './Sections/SectionImplementation/SectionImplementation'
import SectionDescriptionPromo from './Sections/SectionDescriptionPromo/SectionDescriptionPromo'
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel'

const Services = () => {

    return (
        <>
            <TopNavigation />
            <main className="page services">
                <SocialMediaPanel />
                <SectionContent />
                <SectionImplementation />
                <SectionDescription />
                <SectionDescriptionPromo />
            </main >
            <Footer />
        </>
    )
}

export default Services
import React from 'react'
import Footer from '../../components/Footer/Footer'
import TopNavigation from '../../components/TopNavigation/TopNavigation'
import "./Contact.css"
import { Container, Col, Row } from 'react-bootstrap'
import Text from '../../components/Text/Text'
import Showcase from '../../components/Showcase/Showcase'
import ContactForm from './ContactForm/ContactForm'
import { useTranslation } from 'react-i18next'
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel'

const Contact = () => {

    const { t } = useTranslation('translation');

    return (
        <>
            <TopNavigation />
            <main className="page contact">
            <SocialMediaPanel />
                <section className="section-contact">
                    <Container>
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <Text
                                    h1={t("page-contact.header")}
                                    className={"subpage center"}
                                />
                            </Col>

                            <Col xl={6} lg={6} md={12} sm={12} className='contact-data-container'>
                                <div className="contact-showcases-container">
                                    <Showcase
                                        header={t("page-contact.showcase-one.header")}
                                        contactInfo={t("page-contact.showcase-one.contact")}
                                    />
                                    <Showcase
                                        header={t("page-contact.showcase-two.header")}
                                        contactInfo={t("page-contact.showcase-two.contact")}
                                    />
                                    <Showcase
                                           header={t("page-contact.showcase-three.header")}
                                           contactInfo={t("page-contact.showcase-three.contact")}
                                    />

                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} className='contact-contact-form-container' id='contact-form'>
                                <ContactForm />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default Contact
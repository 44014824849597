import React, { useEffect, useState } from 'react'
import './StepThree.css'
import { useTranslation } from 'react-i18next';

const StepThree = ({ setPrepareDetails, setNextDisabled }) => {

  const [selectedOptions, setSelectedOptions] = useState({
    onedrive: null,
    geforce_exp: null,
    xbox: null
  });

  const { t } = useTranslation("translation")

  const checkIfAtLeastOneSelected = () => {
    if (selectedOptions.onedrive !== null &&
      selectedOptions.geforce_exp !== null &&
      selectedOptions.xbox !== null) {
      setPrepareDetails(selectedOptions)
      setNextDisabled(false)
    }
    else {
      setNextDisabled(true)
    }
  }

  const handleOptionChange = (groupName, optionName) => {
    setSelectedOptions((prevState) => {
      return {
        ...prevState,
        [groupName]: optionName
      };
    });

  };

  useEffect(() => {
    setNextDisabled(true)
  }, [])

  useEffect(() => {
    checkIfAtLeastOneSelected()
  }, [selectedOptions])

  return (
    <div className="component-step step-three">
      <div className="component-step-inner">
        <div className="choose-option-container">
          <p>{t("page-profile.view-prepare.prepare-step-three.question-one.label")}</p>
          <div className="options-container">
            <div className="option-wrapper">
              <input type="radio" name="select-onedrive" id="option-3" onChange={() => {
                handleOptionChange('onedrive', false)
              }} />
              <label htmlFor="option-3" className="option option-3">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-three.question-one.answer-one")}</span>
              </label>
            </div>
            <div className="option-wrapper">
              <input type="radio" name="select-onedrive" id="option-4" onChange={() => {
                handleOptionChange('onedrive', true)
              }} />
              <label htmlFor="option-4" className="option option-4">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-three.question-one.answer-two")}</span>
              </label>
            </div>
            <div className="option-wrapper">
              <input type="radio" name="select-onedrive" id="option-5" onChange={() => {
                handleOptionChange('onedrive', false)
              }} />
              <label htmlFor="option-5" className="option option-5">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-three.question-one.answer-three")}</span>
              </label>
            </div>
          </div>
        </div>
        <div className="choose-option-container">
          <p>{t("page-profile.view-prepare.prepare-step-three.question-two.label")}</p>
          <div className="options-container">
            <div className="option-wrapper">
              <input type="radio" name="select-geforce_exp" id="option-6" onChange={() => {
                handleOptionChange('geforce_exp', false)
              }} />
              <label htmlFor="option-6" className="option option-6">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-three.question-two.answer-one")}</span>
              </label>
            </div>
            <div className="option-wrapper">
              <input type="radio" name="select-geforce_exp" id="option-7" onChange={() => {
                handleOptionChange('geforce_exp', true)
              }} />
              <label htmlFor="option-7" className="option option-7">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-three.question-two.answer-two")}</span>
              </label>
            </div>
            <div className="option-wrapper">
              <input type="radio" name="select-geforce_exp" id="option-8" onChange={() => {
                handleOptionChange('geforce_exp', false)
              }} />
              <label htmlFor="option-8" className="option option-8">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-three.question-two.answer-three")}</span>
              </label>
            </div>

          </div>
        </div>
        <div className="choose-option-container">
          <p>{t("page-profile.view-prepare.prepare-step-three.question-three.label")}</p>
          <div className="options-container">
            <div className="option-wrapper">
              <input type="radio" name="select-xbox" id="option-9" onChange={() => {
                handleOptionChange('xbox', true)
              }} />
              <label htmlFor="option-9" className="option option-9">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>

                <span>{t("page-profile.view-prepare.prepare-step-three.question-three.answer-one")}</span>
              </label>
            </div>
            <div className="option-wrapper">
              <input type="radio" name="select-xbox" id="option-10" onChange={() => {
                handleOptionChange('xbox', false)
              }} />

              <label htmlFor="option-10" className="option option-10">
                <div className="dot-wrapper">
                  <div className="dot"></div>
                </div>
                <span>{t("page-profile.view-prepare.prepare-step-three.question-three.answer-two")}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepThree
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import LogoutButton from '../../../components/LogoutButton/LogoutButton'
import "./FragmentSideNav.css"
import { useDatabaseUserContext } from '../../../context/DatabaseUserContext'
import SettingsButton from '../../SettingsButton/SettingsButton'
import { useTranslation } from 'react-i18next'

const FragmentSideNav = () => {

    const { userData } = useDatabaseUserContext()
    const isNavLinkActive = () => {

        const validPaths = [
            '/konto/',
            '/konto',
            '/konto/moje-uslugi'
        ];

        const currentPath = location.pathname;

        return validPaths.some(path => (currentPath === (path) || currentPath.startsWith('/konto/moje-uslugi')));
    };
    const { t } = useTranslation('translation')
    const location = useLocation();

    return (
        <div className="component-side-nav">
            <div className="component-side-nav-inner">
                <div className="list-container">
                    <ul>
                        {
                            userData?.role === 'administrator' && <li><NavLink activeclassname="active" to="/konto/panel-administratora">Admin</NavLink></li>
                        }
                        <li><NavLink className={({ isActive, isPending }) =>
                            isActive = isNavLinkActive() ? "active" : ""
                        } activeclassname="active" to="/konto/moje-uslugi">{t("page-profile.fragment-side-nav.my-services")}</NavLink></li>
                        <li><NavLink activeclassname="active" to="/konto/historia-zamowien">{t("page-profile.fragment-side-nav.orders-history")}</NavLink></li>
                        <li><NavLink activeclassname="active" to="/konto/dane">{t("page-profile.fragment-side-nav.userdata")}</NavLink></li>
                        <li><NavLink activeclassname="active" to="/konto/pomoc">{t("page-profile.fragment-side-nav.help")}</NavLink></li>
                    </ul>
                </div>
                <div className="buttons-container">
                    <div className='settings-button-wrapper'>
                        <NavLink activeclassname="active button" to="/konto/ustawienia"><SettingsButton /></NavLink>
                    </div>
                    <div className="logout-button-wrapper">
                        <LogoutButton />
                    </div>
                </div>


            </div>
        </div>

    )
}

export default FragmentSideNav
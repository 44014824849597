import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import Homepage from "./pages/Homepage/Homepage"
import Contact from './pages/Contact/Contact';
import Proserwis from './pages/Proserwis/Proserwis';
import Statute from './pages/Statute/Statute';
import Privacy from './pages/Privacy/Privacy';
import Services from './pages/Services/Services';
import Purchase from './pages/Purchase/Purchase';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PrivateRouteSignup from './components/PrivateRoute/PrivateRouteSignup';
import PrivateRouteLogin from './components/PrivateRoute/PrivateRouteLogin';
import ViewOrdersHistory from './components/components-profile/ViewOrdersHistory/ViewOrdersHistory';
import ViewMyServices from './components/components-profile/ViewMyServices/ViewMyServices';
import ViewUserData from './components/components-profile/ViewUserData/ViewUserData';
import ViewHelp from './components/components-profile/ViewHelp/ViewHelp';
import ViewPrepare from './components/components-profile/ViewPrepare/ViewPrepare';
import DatabasePageProvider from './context/DatabasePageContext';
import SectionProducts from './pages/Purchase/Sections/SectionProducts/SectionProducts';
import PrivateRoutePurchase from './components/PrivateRoute/PrivateRoutePurchase';
import AdminViewsHolder from './components/components-profile/AdminPanel/admin-components/AdminViewsHolder/AdminViewsHolder';
import Payment from './pages/Payment/Payment';
import ViewSvcScheduling from './components/components-profile/ViewSvcScheduling/ViewSvcScheduling';
import ViewPrepareStart from './components/components-profile/ViewPrepareStart/ViewPrepareStart';
import ScrollToTopArrow from './components/ScrollToTopArrow/ScrollToTopArrow';
import ScrollToTopAuto from './components/ScrollToTopAuto/ScrollToTopAuto';
import ViewSettings from './components/components-profile/ViewSettings/ViewSettings';
import PasswordSetNewForm from './pages/PasswordReset/Sections/PasswordSetNewForm/PasswordSetNewForm';
import PasswordResetForm from './pages/PasswordReset/Sections/PasswordResetForm/PasswordResetForm'
import PasswordReset from './pages/PasswordReset/PasswordReset';
import ViewInvoiceData from './components/components-profile/ViewInvoiceData/ViewInvoiceData';
import GamingMode from './pages/GamingMode/GamingMode';

// KORZYSTAJ ZE SNIPPETÓW: rafce
function App() {

  return (
    <AuthProvider>
      <DatabasePageProvider>
        <BrowserRouter>
          <ScrollToTopAuto />
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="/konto/" element={<PrivateRoute />} >
              <Route path="/konto/" element={<ViewMyServices />} />
              <Route path='/konto/moje-uslugi/' element={<ViewMyServices />} />
              <Route path='/konto/moje-uslugi/przygotowanie' element={<ViewPrepare />} />
              <Route path='/konto/moje-uslugi/przygotowanie/start' element={<ViewPrepareStart />} />
              <Route path='/konto/moje-uslugi/wybor-terminu' element={<ViewSvcScheduling />} />
              <Route path="/konto/dane" element={<ViewUserData />} />
              <Route path="/konto/historia-zamowien" element={<ViewOrdersHistory />} />
              <Route path="/konto/historia-zamowien/faktura" element={<ViewInvoiceData />} />
              <Route path="/konto/ustawienia" element={<ViewSettings />} />
              <Route path="/konto/pomoc" element={<ViewHelp />} />
              <Route path="/konto/panel-administratora" element={<AdminViewsHolder />} />
            </Route>
            <Route path="/proserwis" element={<Proserwis />} />
            <Route path="/regulamin" element={<Statute />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/uslugi" element={<Services />} />
            <Route path="/polityka-prywatnosci" element={<Privacy />} />
            <Route path="/gaming-mode" element={<GamingMode />} />
            <Route path="/zakup" element={<Purchase />} >
              <Route path="/zakup/" element={<SectionProducts />} />
              <Route path='/zakup/personalizacja' element={<PrivateRoutePurchase />} />
            </Route>
            <Route path="/zakup/finalizacja-zamowienia" element={<Payment />} />
            <Route exact path='/rejestracja' element={<PrivateRouteSignup />} />
            <Route exact path='/logowanie' element={<PrivateRouteLogin />} />
            <Route path='/reset-hasla' element={<PasswordReset />} >
              <Route path='/reset-hasla/' element={<PasswordResetForm />} />
              <Route path='/reset-hasla/formularz' element={<PasswordSetNewForm />} />
            </Route>
            <Route path="*" element={<><Homepage /> <Navigate to='/' /></>} />
          </Routes>
        </BrowserRouter>
        <ScrollToTopArrow />
      </DatabasePageProvider>
    </AuthProvider>
  );
}

export default App;

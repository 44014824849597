import React from 'react'
import "./InstructionBox.css"

const InstructionBox = ({ number, title, description }) => {
    return (
        <div className="component-instruction-box">
            <div className="component-instruction-box-inner">
                <div className="number-container">
                    <div className="number-wrapper">
                        <h1>{number}</h1>
                    </div>
                </div>
                <div className="title-container">
                    <h2>{title}</h2>
                </div>
                <div className="description-container">
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default InstructionBox
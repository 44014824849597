import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from '../../../../Button/Button';
import './ApproveModal.css';
import moment from 'moment';
import 'moment/locale/pl';
import { useTranslation } from 'react-i18next';

const ApproveModal = ({ booked_date, booked_time, createReservation, onHide, onShow, show }) => {

    const { t } = useTranslation('translation')
    let date_moment = moment(booked_date)
    async function handleApproveClick() {
        onHide()
        await createReservation()
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            onShow={onShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t("page-profile.view-svc-scheduling.scheduling-calendar.approve-modal.approve-reservation")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{t("page-profile.view-svc-scheduling.scheduling-calendar.approve-modal.scheduling-this")}</h4>
                <h4>{date_moment.format('DD.MM.YYYY')} ({date_moment.format('dddd')}), {booked_time}</h4>
                <p>
                    {t("page-profile.view-svc-scheduling.scheduling-calendar.approve-modal.sms-reminder")}<br />
                    {t("page-profile.view-svc-scheduling.scheduling-calendar.approve-modal.prepare-reminder")}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className='button try medium nohover' onClick={onHide}>{t("nav-buttons.back")}</Button>
                <Button className='button try medium nohover' onClick={handleApproveClick}>{t("nav-buttons.confirm")}</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ApproveModal;
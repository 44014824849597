import React, { useState } from 'react'
import Button from '../../Button/Button'
import "./HistoryOrderBar.css"
import moment from 'moment';
import 'moment/locale/pl';
import { useDatabasePageContext } from '../../../context/DatabasePageContext';
import { useTranslation } from 'react-i18next';
const HistoryOrderBar = ({ date = '-', orderId = '-', packageName = '-', status = '', orderClients = [], invoiceSent = 'true', amount = 0, currency = 'PLN' }) => {

    const { t } = useTranslation('translation')
    const [loading, setLoading] = useState()
    const { timezone } = useDatabasePageContext()

    let timeInUserTimeZone = moment(date).tz(timezone);
    let dateFormatted = timeInUserTimeZone.format('DD.MM.YYYY')

    const currencySymbols = {
        GBP: '£',
        USD: '$',
        EUR: '€',
        PLN: 'zł'
    };

    return (
        <>
            <div className="component-history-order-bar">
                <div className="component-history-order-bar-inner">
                    <div className="data-of-order-container">
                        <div className="data-of-order-wrapper">
                            <h2>{status === 'completed' ? t("page-profile.history-order-bar.completed") : t("page-profile.history-order-bar.pending")}</h2>
                            <p>{dateFormatted}</p>
                            <p>{`Id: #`}<span style={{ fontFamily: 'Gilroy-Bold, sans-serif' }}>{orderId}</span></p>
                            <h2>{`${amount ? amount : ''} ${currency ? currencySymbols[currency] : ''}`}</h2>
                        </div>
                    </div>
                    <div className="name-of-service-container">
                        <div className="name-of-service-description">
                            <h3>{t("page-profile.history-order-bar.package")}</h3>
                        </div>
                        <div className="name-of-service-wrapper">
                            <p>{packageName}</p>
                        </div>
                    </div>
                    <div className="users-emails-container">
                        <div className="users-emails-description">
                            <h3>{t("page-profile.history-order-bar.clients")}</h3>
                        </div>
                        <div className="users-emails-wrapper">
                            {
                                orderClients.length > 0 ?
                                    orderClients.map((orderClient, index) =>
                                        <p key={index}> {orderClient}</p>
                                    )
                                    : <p>{t("page-profile.history-order-bar.undefined")}</p>
                            }
                        </div>
                    </div>
                    <div className="ask-for-invoice-container">
                        <div className="ask-for-invoice-description">
                            <h3>{t("page-profile.history-order-bar.send-invoice")}</h3>
                        </div>
                        <div className="ask-for-invoice-wrapper">
                            <Button href='/konto/historia-zamowien/faktura' id={orderId} name={packageName} disabled={loading || invoiceSent} className={`status-of-invoice-sent ${invoiceSent}`}>{invoiceSent === true ? t("page-profile.history-order-bar.sent") : t("page-profile.history-order-bar.send")}</Button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HistoryOrderBar
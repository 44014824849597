import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Text from '../../../../components/Text/Text'
import ImproveMonitorSvg from '../../../../assets/img/Monitorv3-min.webp'
import "./SectionUsePotential.css"
import Button from "../../../../components/Button/Button"
import { Trans, useTranslation } from 'react-i18next'

const SectionUsePotential = () => {

    const { t } = useTranslation('translation');

    return (
        <>
            <section id="section-use-potential" className="section-use-potential">
                <Container>
                    <Row>
                        <Col xl={6} lg={12} md={12} sm={12} className='use-potential-text-wrapper'>
                            <Text
                                h4={<Trans i18nKey="page-homepage.section-use-potential.header" />}
                                h3={<Trans i18nKey="page-homepage.section-use-potential.header-bottom" />}
                                p={<Trans i18nKey="page-homepage.section-use-potential.text" />}
                            />
                            <Button href="/zakup" className='try'>{t("purchase-buttons.try-now")}</Button>
                        </Col>
                        <Col xl={6} lg={0} md={0} sm={0} className="monitor-container">
                            <Image
                                src={ImproveMonitorSvg}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>


        </>
    )

}

export default SectionUsePotential;
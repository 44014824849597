import React from 'react'
import ShopProduct from '../ShopProduct/ShopProduct'
import './ProductBoxDescription.css'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ProductBoxDescription = ({ id, name, price, type, description, duration, advantages_array, short_desc, time_description, mode, promo_price, button, benefits_list, service_name, users_number, support_days, status }) => {

    const { t } = useTranslation('translation')

    return (
        <Row className='product-box-description-container' id={`box-${name}`}>
            <Col xl={5} lg={5} md={5} sm={5} className='shop-product-container'>
                <ShopProduct id={id} service_name={service_name} type={type} users_number={users_number} duration={duration} support_days={support_days} price={price} promo_price={promo_price} name={name} description={description} readMore={false} hover={false} button={button} benefits_list={benefits_list} status={status} nohover />
            </Col>
            <Col xl={7} lg={7} md={7} sm={7} className='description-container'>
                <div className="short-description-wrapper">
                    <h1>{short_desc}</h1>
                    <div className="time-and-mode-container">
                        <p><span style={{ fontFamily: 'Gilroy-regular, sans-serif', color: '#FFFFFF' }}>{t("components.product-box-description.service-time")}</span> {time_description}</p>
                        <p><span style={{ fontFamily: 'Gilroy-regular, sans-serif', color: '#FFFFFF' }}>{t("components.product-box-description.mode")}</span> {mode}</p>
                    </div>
                </div>
                <div className="list-container">
                    <div className="list-wrapper">
                        <ol className='product-box-ol'>
                            {advantages_array.map((advantage, index) =>
                                <li key={index}>{advantage}</li>
                            )}
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ProductBoxDescription
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import "./SectionReviews.css"
import ReviewsSlider from "../../../../components/ReviewsSlider/ReviewsSlider"
import Button from '../../../../components/Button/Button'
import { Trans, useTranslation } from 'react-i18next'

const SectionReviews = () => {

    const { t } = useTranslation('translation');

    return (
        <>
            <section id="section-reviews" className="section-reviews">
                <Container>
                    <Row>
                        <Col xl={6} lg={6} md={12} sm={12}>
                            <div className="text-reviews-container">
                                <h1>165+</h1>
                                <h2><span><Trans i18nKey={"page-homepage.section-reviews.header-bottom"} /></span></h2>
                                <Button href="/zakup" className='try'>{t("purchase-buttons.join-us")}</Button>
                            </div>

                        </Col>

                        <Col xl={6} lg={6} md={12} sm={12}>
                            <ReviewsSlider />
                            <div className="other-reviews-wrapper">
                                <a href="https://www.facebook.com/mendicsgo/reviews">{t("page-homepage.section-reviews.under-slider")}</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SectionReviews;
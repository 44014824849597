import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import InstructionBox from '../../../../components/InstructionBox/InstructionBox'
import "./SectionImplementation.css"
import { Trans, useTranslation } from 'react-i18next'

const SectionImplementation = () => {

    const { t } = useTranslation('translation')

    const InstructionBoxes = [
        {
            number: 1,
            title: t("page-services.section-implementation.box-one.title"),
            description: t("page-services.section-implementation.box-one.description"),
            link_name: t("page-services.section-implementation.box-one.link_name"),
            link: t("page-services.section-implementation.box-one.link")
        },
        {
            number: 2,
            title: t("page-services.section-implementation.box-two.title"),
            description: <Trans i18nKey={"page-services.section-implementation.box-two.description"}>Zdecyduj się na jeden z dostępnych pakietów i rozpocznij zamówienie. Wpisz adres e-mail klienta, <span style={{ color: 'rgb(0,194,220)' }}>wybierz termin (lub zrób to później)</span> i wykonaj płatność.</Trans>,
            link_name: t("page-services.section-implementation.box-two.link_name"),
            link: t("page-services.section-implementation.box-two.link")
        },
        {
            number: 3,
            title: t("page-services.section-implementation.box-three.title"),
            description: <Trans i18nKey={"page-services.section-implementation.box-three.description"}>Zakupiona usługa pojawi się w widoku <span style={{ color: 'rgb(0,194,220)' }}>Moje usługi</span> w panelu użytkownika. Kliknij w przycisk <span style={{ color: 'rgb(0,194,220)' }}>PRZYGOTUJ</span> i przejdź przez interaktywny proces przygotowania do optymalizacji.</Trans>,
        },
        {
            number: 4,
            title: t("page-services.section-implementation.box-four.title"),
            description: <Trans i18nKey={"page-services.section-implementation.box-four.description"}>Dwie godziny przed zaplanowanym terminem otrzymasz <span style={{ color: 'rgb(0,194,220)' }}>przypomnienie e-mail i SMS</span>.</Trans>,
        },
        {
            number: 5,
            title: t("page-services.section-implementation.box-five.title"),
            description: <Trans i18nKey={"page-services.section-implementation.box-five.description"}>Nie musisz być dostępny podczas pierwszych <span style={{ color: 'rgb(0,194,220)' }}>45 minut optymalizacji</span>. Później wymagamy Twojej obecności.</Trans>,
        },
        {
            number: 6,
            title:  t("page-services.section-implementation.box-six.title"),
            description: <Trans i18nKey={"page-services.section-implementation.box-six.description"}>Gotowe! Przetestuj efekty i podziel się z nami swoją opinią. W razie pytań lub problemów, skorzystaj z <span style={{ color: 'rgb(0,194,220)' }}>nawet 90 dni opieki technicznej</span>.</Trans>,
        }
    ]

    return (
        <section className='section-implementation' id="services-implementation">
            <Container>
                <Row>
                    {
                        InstructionBoxes.map((box, index) =>
                            <Col xl={4} lg={6} md={6} sm={12} key={index}>
                                <InstructionBox key={index} number={box.number} title={box.title} description={box.description} />
                            </Col>
                        )
                    }
                </Row>
            </Container>
        </section>
    )
}

export default SectionImplementation
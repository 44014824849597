import React, { useRef } from 'react'
import "./MyServiceBarSceletor.css"

const MyServiceBarSceletor = () => {


    const nodeRef = useRef(null);

    return (
        <>
            <div className="component-my-service-bar-sceletor">
                <div className="component-my-service-bar-sceletor-inner">

                </div>
            </div>
        </>
    )
}

export default MyServiceBarSceletor
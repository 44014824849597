import React from 'react'
import TopNavigation from '../../components/TopNavigation/TopNavigation'
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel'
import Footer from '../../components/Footer/Footer'
import SectionMain from './Sections/SectionMain/SectionMain'
import './GamingMode.css'
import SectionContent from './Sections/SectionContent/SectionContent'
import SectionHowItWorks from './Sections/SectionHowItWorks/SectionHowItWorks'
import SectionInstruction from './Sections/SectionInstruction/SectionInstruction'
import SectionSteamBoost from './Sections/SectionSteamBoost/SectionSteamBoost'
import SectionTryNow from './Sections/SectionTryNow/SectionTryNowGM'

const GamingMode = () => {
    return (
        <>
            <TopNavigation />
            <main className='page gaming-mode'>
                <SocialMediaPanel />
                <SectionMain />
                <SectionContent />
                <SectionHowItWorks />
                <SectionInstruction />
                <SectionSteamBoost />
                <SectionTryNow />
            </main>
            <Footer />
        </>
    )
}

export default GamingMode
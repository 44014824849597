import React, { useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Text from '../../../../components/Text/Text'
import ShopProduct from '../../../../components/ShopProduct/ShopProduct'
import { useDatabasePageContext } from '../../../../context/DatabasePageContext'
import Spinner from 'react-bootstrap/Spinner'
import './SectionProducts.css'
import { allProductsPL } from '../../allProductsPL'
import { allProductsEN } from '../../allProductsEN'
import { useTranslation } from 'react-i18next'
import { buyNowPageSeenEvent } from '../../../../data/pixel-events/buyNowPageSeenEvent'
import { useAuth } from '../../../../context/AuthContext'
import { hashData } from '../../../../utils/hashData'

const SectionProducts = () => {

    const { t, i18n } = useTranslation('translation')
    const { currentUser } = useAuth()
    const hasMounted = useRef(false)
    const { useReadPackagesDB, promoPackages, isPageLoading, exchangeRates } = useDatabasePageContext()
    const productsList = {
        allProductsPL,
        allProductsEN
    }
    useReadPackagesDB()

    useEffect(() => {
        if (!hasMounted.current) {
            buyNowPageSeenEvent(i18n.language, hashData(currentUser?.email), hashData(currentUser?.id))
            hasMounted.current = true
        }
    }, [])

    return (
        <section className="section-products" id="section-products">
            <Container>
                <Row className='main-products-container'>
                    {
                        exchangeRates ?
                            productsList[t('page-purchase.section-products.products-list')].map((product) =>
                                <Col xl={4} lg={6} md={12} sm={12} key={product.id} className='shop-product-wrapper'>
                                    <ShopProduct key={product.id} id={product.id} type={product.type} name={product.name} price={product.price} description={product.description} bestseller={product.bestseller} users_number={product.users_number} button={product.button} support_days={product.support_days} service_name={product.service_name} promo_price={product.promo_price} benefits_list={product.benefits_list} duration={product.duration} status={product.status} special={product.special} />
                                </Col>
                            )
                            : <Spinner animation="border" className='loading-spinner' />
                    }
                </Row>
                <Row className='promo-products-text'>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Text
                            h1={t("page-purchase.section-products.promo-header")}
                            h3={t("page-purchase.section-products.promo-sub-header")}
                            className={"subpage center"}
                        />
                    </Col>
                </Row>
                <Row className='promo-products-container'>
                    {
                        isPageLoading ? <Spinner animation="border" className='loading-spinner' /> :
                            promoPackages.map((product) =>
                                <Col xl={4} lg={6} md={12} sm={12} key={product.id} className='shop-product-wrapper'>
                                    <ShopProduct key={product.id} promo_price={product.promo_price} id={product.id} name={product.name} price={product.price} description={product.short_desc} bestseller={product.bestseller} users_number={product.users_number} support_days={product.support_days} service_name={product.service_name} type={product.type} benefits_list={product.benefits_list} duration={product.duration} status={product.status} button={product.button} />
                                </Col>
                            )
                    }
                </Row>
            </Container>
        </section>
    )
}

export default SectionProducts


import React from 'react'
import './SettingsBackButton.css'
import Button from '../../../../Button/Button'
import { useTranslation } from 'react-i18next'

const SettingsBackButton = ({ setCurrentView }) => {

    const { t } = useTranslation('translation')
    function handleBack() {
        setCurrentView()
    }

    return (
        <Button className="try small" onClick={handleBack}>{t("nav-buttons.return")}</Button>
    )
}

export default SettingsBackButton
import React, { useRef, useState } from 'react'
import './AdminViewMailMsg.css'
import AdminBackButton from '../AdminBackButton/AdminBackButton'
import Button from '../../../../Button/Button'
import adminMailMsgSend from '../../admin-functions/admin-mail-send'
import { Alert } from 'react-bootstrap'
import Spinner from "react-bootstrap/Spinner";

const AdminViewMailMsg = ({ setCurrentView }) => {

    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const mailMsgRef = useRef()
    const mailSubjectRef = useRef()
    const mailReceiverRef = useRef()
    const mailLanguageRef = useRef()

    const transformToHTMLParagraphs = (text) => {
        return text.split('\n').map((segment, index) => `<p>${segment}</p>`).join('');
    };

    return (

        <div className="admin-view-mail-msg-container">
            {error && <Alert variant="danger">{error}</Alert>}
            {errorSuccess && <Alert variant='success'>{errorSuccess}</Alert>}
            <div className="admin-view-mail-msg-wrapper">
                <div className="admin-mail-msg-bar-container">
                    <div className="admin-mail-msg-bar-wrapper">
                        <div className="admin-mail-msg-bar-inputs">
                            <div className="mail-msg-input-wrapper">
                                <label htmlFor='mail-msg-email-input' className='form-label'>Odbiorca:</label>
                                <input ref={mailReceiverRef} className='form-control' type='text' placeholder='e-mail odbiorcy' id='mail-msg-email-input' name='mail-msg-email-input' />
                            </div>
                            <div className="mail-msg-input-wrapper">
                                <label htmlFor='mail-msg-subject-input' className='form-label'>Temat:</label>
                                <input ref={mailSubjectRef} className='form-control' type='text' placeholder='temat' id='mail-msg-subject-input' name='mail-msg-subject-input' />
                            </div>
                            <div className="mail-msg-input-wrapper form-group">
                                <label htmlFor='mail-msg-message-input' className='form-label'>Wiadomość:</label>
                                <textarea ref={mailMsgRef} rows="5" className='form-control' placeholder='wiadomość' id='mail-msg-message-input' name='mail-msg-message-input' />
                            </div>
                            <div className="mail-msg-input-wrapper">
                                <select ref={mailLanguageRef} className='form-control' id='mail-msg-language-select' name='mail-msg-language-select' style={{marginTop: "8px"}}>
                                    <option value="pl">Polski (PL)</option>
                                    <option value="en">Angielski (EN)</option>
                                </select>
                            </div>
                        </div>
                        <Button className='try medium' disabled={loading} onClick={async () => {
                            setLoading(true)
                            setError('')
                            setErrorSuccess('')

                            // Zastąp znaki nowej linii odpowiednim formatem dla e-mail
                            const formattedMessage = transformToHTMLParagraphs(mailMsgRef.current.value);

                            const error = await adminMailMsgSend(mailReceiverRef.current.value, formattedMessage, mailSubjectRef.current.value, mailLanguageRef.current.value)
                            if (error) {
                                setError(error?.message)
                            } else {
                                setErrorSuccess('Wiadomość wysłana poprawnie')
                            }
                            setLoading(false)
                        }}>{loading ? <Spinner animation="border" className='loading-spinner small black' /> : 'Wyślij wiadomość'}</Button>
                        <AdminBackButton setCurrentView={() => { setCurrentView('functions') }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminViewMailMsg
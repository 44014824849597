import React, { useEffect } from 'react'
import Button from '../../../../../../../components/Button/Button'
import { useTranslation } from 'react-i18next'

const ViewChooseOptions = ({ handleStateChange, buttonDisabled, handleOptionChange, handleButtonClick, setNextDisabled, buttonHidden }) => {

    const { t } = useTranslation('translation')
    useEffect(() => {
        setNextDisabled(true)
    }, [setNextDisabled])

    return (
        <div className="choose-option-container">
            <Button className={'button try radio'} onClick={handleButtonClick} disabled={buttonDisabled} hidden={buttonHidden}>{t("page-purchase.order-step-one.view-choose-options.scheduling")}</Button>
            <div className="options-container">
                <div className="option-wrapper">
                    <input type="checkbox" name="select-decide-later" id="decide-later" onChange={(event) => {
                        handleOptionChange()
                        event.target.checked ?
                            handleStateChange('bookedDate', { date: 'later', time: 'later' })

                            :
                            handleStateChange('bookedDate', null)
                    }} />
                    <label htmlFor="decide-later" className="option option-3">
                        <span>{t("page-purchase.order-step-one.view-choose-options.decide-later")}</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default ViewChooseOptions
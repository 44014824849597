import React, { useState, useEffect } from 'react'
import './SchedulingCalendar.css'
import { useDatabasePageContext } from '../../../../context/DatabasePageContext'
import { useDatabaseUserContext } from '../../../../context/DatabaseUserContext'
import DateHolder from './DateHolder/DateHolder'
import TimeHolder from './TimeHolder/TimeHolder'
import Button from '../../../Button/Button'
import supabase from '../../../../data/supabase'
import Spinner from 'react-bootstrap/Spinner'
import { useNavigate } from 'react-router-dom'
import ApproveModal from './ApproveModal/ApproveModal'
import sendMail from '../../../../utils/sendMail'
import moment from 'moment';
import 'moment/locale/pl';
import 'moment-timezone';
import { useTranslation } from 'react-i18next'

const SchedulingCalendar = ({ schedule, setSchedule, setLoading, loading, setComplete, setSecondsRemaining, service_id, setError, prev_booked_date, prev_booked_time, date_changes_limit }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [availableDates, setAvailableDates] = useState([])
    const [availableHours, setAvailableHours] = useState([])
    const [clickedDateIndex, setClickedDateIndex] = useState(-1)
    const [clickedTimeIndex, setClickedTimeIndex] = useState(-1)
    const { timezone } = useDatabasePageContext()
    const [modalShow, setModalShow] = useState(false)
    const { t, i18n } = useTranslation('translation')
    const { updateBookedServiceSchedule, removeEmptyAvailableDay, updateAvailableHours } = useDatabaseUserContext()
    const navigate = useNavigate()

    // ustawia sie wlasciwy czas, kiedy wyswietlaja sie dostepne godziny danego dnia
    useEffect(() => {
        setSchedule(
            {
                booked_date: availableDates[clickedDateIndex]?.date,
                booked_time: availableHours[clickedTimeIndex]
            }
        )
    }, [availableHours])

    // uaktywnia sie przy kazdym kliknieciu w date lub godzine
    useEffect(() => {
        clickedDateIndex !== (-1) && clickedTimeIndex !== (-1) ?
            setSchedule(
                {
                    booked_date: availableDates[clickedDateIndex]?.date,
                    booked_time: availableHours[clickedTimeIndex]
                })
            :
            setSchedule(
                {
                    booked_date: null,
                    booked_time: null
                })
    }, [clickedDateIndex, clickedTimeIndex])

    const chooseDate = (dateIndex) => {
        setClickedDateIndex(dateIndex)
    }

    const chooseTime = (timeIndex) => {
        setClickedTimeIndex(timeIndex)
    }

    function useReadHours() {
        useEffect(() => {
            if (availableDates && availableDates?.length !== 0 && clickedDateIndex !== (-1)) {
                getHours()
            }
        }, [clickedDateIndex]
        )

    }

    function useReadDates() {
        useEffect(() => {
            getDates()
        }, [])
    }

    async function isDateAvailable(date, time) {
        try {
            let date_moment = moment(`${date} ${time}`).tz('Europe/Warsaw')

            date = date_moment.format('YYYY-MM-DD')
            time = date_moment.format('HH:mm')

            const { data, error } = await supabase
                .from('available_dates')
                .select('available_hours')
                .eq('date', date)
                .single()

            if (error) throw error;

            if (data != null) {
                if ((data?.available_hours)?.includes(time)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }

        } catch (error) {
            return false;
        }
    }

    async function getHours() {
        setIsLoading(true)
        try {
            const { data, error } = await supabase
                .from('available_dates')
                .select('available_hours')
                .eq('date', `${availableDates[clickedDateIndex]?.date}`)
                .single()

            if (error) throw error;

            if (data !== null) {
                if (data.available_hours !== null) {

                    const timeZoneAdjustedHours = data.available_hours.map(hour => {
                        // Zakładając, że 'hour' jest w formacie 'HH:mm'
                        let dateInWarsaw = moment.tz(`${availableDates[clickedDateIndex]?.date} ${hour}`, 'YYYY-MM-DD HH:mm', 'Europe/Warsaw');
                        return dateInWarsaw.clone().tz(timezone).format('HH:mm');
                    }).sort();

                    setAvailableHours(timeZoneAdjustedHours)
                    setClickedTimeIndex(0)
                }
                else {
                    setAvailableHours([])
                    setClickedTimeIndex(-1)
                }
            }
            else if (data == null) {
                setAvailableHours([])
            }
            else {
                setAvailableHours([])
            }

        } catch (error) {
            setAvailableHours([])
        }
        setIsLoading(false)
    }

    async function getDates() {
        setIsLoading(true)
        try {
            const { data, error } = await supabase
                .from('available_dates')
                .select('date')

            if (error) throw error;

            if (data !== null) {
                const dataSorted = data.sort((a, b) => new Date(a.date) - new Date(b.date));
                setAvailableDates(dataSorted)
                setClickedDateIndex(0)
            }
            else if (data === null) {
                setAvailableDates([])
            }
            else {
                setAvailableDates([])
            }

        } catch (error) {
            setAvailableDates([])
        }
        setIsLoading(false)
    }

    async function createReservation() {
        setLoading(true)
        try {
            const { data, error } = await supabase
                .from('booked_services')
                .select('date_changes_limit')
                .eq('id', service_id)
                .single()

            if (error) throw error

            if (data.date_changes_limit === 0) {
                setError(t("page-profile.view-svc-scheduling.schedule-one-free"))
                setTimeout(() => navigate('/konto/moje-uslugi/'),
                    3000)
                return;
            }
        } catch (error) { }

        const booked_date = (availableDates && availableDates.length !== 0 && (clickedDateIndex !== (-1))) ? availableDates[clickedDateIndex].date : t("page-profile.view-svc-scheduling.scheduling-calendar.date-error")
        const booked_time = (availableHours && availableHours.length !== 0) ? availableHours[clickedTimeIndex] : t("page-profile.view-svc-scheduling.scheduling-calendar.time-error")

        let new_date_moment = moment.tz(`${booked_date} ${booked_time}`, timezone)
        let prev_date_moment = moment.tz(`${prev_booked_date} ${prev_booked_time}`, timezone)

        if (prev_booked_date && prev_booked_time && prev_date_moment.isBefore(moment().add(14, 'hours'), 'minutes')) {
            setError(t("page-profile.view-svc-scheduling.schedule-unavailable"))
            setTimeout(() => navigate('/konto/moje-uslugi/'),
                3000)
            return;
        }
        let isDateAvailableReturn = isDateAvailable(new_date_moment.format('YYYY-MM-DD'), booked_time)
        // 1. wybranie terminu i klikniecie w przycisk 'dalej'

        // 2. sprawdzenie, czy termin jest nadal dostępny i czy jest później niż 5 godzin od obecnej daty
        if (isDateAvailableReturn && (new_date_moment.isAfter(moment().add(5, 'hours'), 'minutes'))) {
            try {
                const { data, error } = await updateBookedServiceSchedule(service_id, new_date_moment.format('YYYY-MM-DD HH:mm'))
                if (error)
                    throw error
                else {
                    let formatted_new_date = new_date_moment.format('DD.MM.YYYY, dddd')
                    let formatted_new_time = new_date_moment.format('HH:mm')
                    await sendMail({ author: 'noreply', receiver: data.client_details.email, subject: t("page-profile.view-svc-scheduling.scheduling-calendar.new-service-schedule"), service_name: data.service_name, booked_date: formatted_new_date, booked_time: formatted_new_time, timezone: timezone, template: t("page-profile.view-svc-scheduling.scheduling-calendar.new-schedule-template"), language: i18n?.language })
                    let availableHoursNew = availableHours.filter(hour => hour !== booked_time)

                    if (prev_booked_date && prev_booked_time) {
                        try {
                            const { data: changeLimitData, error: changeLimitError } = await supabase
                                .from('booked_services')
                                .update({
                                    'date_changes_limit': (date_changes_limit - 1)
                                })
                                .eq('id', service_id)

                            if (changeLimitError) throw error
                        } catch (error) {
                        }
                        // stary termin istnieje
                        if (prev_date_moment.isAfter(moment().add(7, 'hours'), 'hours')) {
                            // termin jest później niż za 7 godzin
                            try {
                                const { data, error } = await supabase
                                    .from('available_dates')
                                    .select('*')
                                    .eq('date', prev_date_moment.format('YYYY-MM-DD'))
                                    .single()

                                const resultData = data || {};

                                if (error && !(error.code === 'PGRST116')) {
                                    throw error
                                }

                                if (Object.keys(resultData).length < 1) {
                                    // wiersz dla starego terminu nie istnieje
                                    // dodaje nowy wiersz ze starym terminem
                                    const { data: newDateData, error: newDateError } = await supabase
                                        .from('available_dates')
                                        .insert({
                                            'date': prev_date_moment.tz('Europe/Warsaw').format('YYYY-MM-DD'),
                                            'available_hours': [prev_date_moment.tz('Europe/Warsaw').format('HH:mm')]
                                        })
                                    if (newDateError) throw error
                                } else {
                                    // wiersz dla starego terminu istnieje
                                    let available_hours_prev = data.available_hours
                                    // sprawdzanie, czy w tabeli a_h_prev znajduje się już stary termin
                                    if (!(available_hours_prev.includes(prev_date_moment.tz('Europe/Warsaw').format('HH:mm')))) {
                                        // terminu nie ma w tablicy, więc go dodaję
                                        available_hours_prev.push(prev_date_moment.tz('Europe/Warsaw').format('HH:mm'))
                                        await updateAvailableHours(prev_date_moment.format('YYYY-MM-DD'), available_hours_prev)
                                    }
                                }
                            } catch (error) {
                            }
                        }
                    }
                    // rpc_handle_new_date
                    // niezależnie czy stary termin istnieje, zawsze sie wykona
                    if (availableHoursNew.length === 0) {
                        // tabela a_h_new jest pusta, usuwamy z bazy danych wiersz dla danego pustego dnia
                        await removeEmptyAvailableDay(booked_date)
                    } else {
                        // tabela a_h_new zawiera inne godziny, więc tworzymy nową tabelę, filtrując niepoprawne terminy
                        availableHoursNew = availableHoursNew.filter(hour => moment(`${booked_date} ${hour}`).isAfter(moment().add(6, 'hours'), 'minutes'))
                        // wysyłamy do bazy danych zaktualizowaną tabelę a_h_new
                        if (!(availableHoursNew.length === 0)) {
                            await updateAvailableHours(new_date_moment.format('YYYY-MM-DD'), availableHoursNew)
                        }
                        else {
                            await removeEmptyAvailableDay(booked_date)
                        }
                    }
                }

            } catch (error) {
                setError(t("page-profile.view-svc-scheduling.scheduling-error"))
            }
        } else {
            setError(t("page-profile.view-svc-scheduling.schedule-taken"))

            if (isDateAvailableReturn) {
                // usuwanie niepoprawnego terminu jeśli istnieje w bazie danych

                if (new_date_moment.isBefore(moment(), 'day')) {
                    // jesli wybrany dzien jest wczesniej niż obecny dzien, usuwa caly wiersz
                    await removeEmptyAvailableDay(booked_date)
                }
                else {
                    // jesli wybrany dzien jest dzisiaj lub później niż obecny dzień, wywala tylko złą godzinę
                    try {
                        const { data: availableHoursWrong, error } = await supabase
                            .from('available_dates')
                            .select('available_hours')
                            .eq('date', new_date_moment.format('YYYY-MM-DD'))
                            .single()
                        if (error) throw error;

                        if (availableHoursWrong) {
                            if (availableHoursWrong.available_hours) {
                                // filtrujemy niepoprawne terminy
                                let available_hours_wrong = availableHoursWrong.available_hours.filter(hour => moment(`${booked_date} ${hour}`).isAfter(moment().add(6, 'hours'), 'minutes'))
                                // wysyłamy do bazy danych zaktualizowaną tabelę available_hours_wrong bez niepoprawnych terminów
                                if (!(available_hours_wrong.length === 0)) {
                                    await updateAvailableHours(new_date_moment.format('YYYY-MM-DD'), available_hours_wrong)
                                }
                                else {
                                    await removeEmptyAvailableDay(booked_date)
                                }
                            }
                        }

                    } catch (error) {
                    }
                }
            }
        }

        setComplete(true)
        setLoading(false)

        let remainingSeconds = 5;
        setSecondsRemaining(remainingSeconds)
        // Rozpoczęcie odliczania co sekundę
        const intervalId = setInterval(() => {
            remainingSeconds--;
            setSecondsRemaining(remainingSeconds);

            if (remainingSeconds <= 0) {
                clearInterval(intervalId);
                navigate('/konto/moje-uslugi/');
            }
        }, 1000);
    }

    useReadDates()
    useReadHours()

    const handleBack = () => {
        navigate(-1)
    }

    const handleNext = () => {
        setModalShow(true)
    }

    return (
        <div className="component-scheduling-calendar">
            <div className="component-scheduling-calendar-inner">
                <div className="date-picker-bar-container">
                    <div className="date-picker-bar-wrapper">
                        {
                            availableDates.map((date, index) =>
                                <DateHolder key={index} date={date.date} chooseDate={chooseDate} clickedDateIndex={clickedDateIndex} index={index} />
                            )
                        }


                    </div>
                </div>

                <div className="time-picker-container">
                    {
                        isLoading ? <Spinner animation="border" className='loading-spinner' />
                            :
                            <div className="time-picker-wrapper">
                                {
                                    availableHours.length !== 0 ?
                                        availableHours.map((time, index) =>
                                            <TimeHolder key={index} time={time} chooseTime={chooseTime} clickedTimeIndex={clickedTimeIndex} index={index} />
                                        )

                                        :
                                        (availableDates && availableDates.length !== 0) ?
                                            <p>{t("page-profile.view-svc-scheduling.scheduling-calendar.no-hours-left")}</p> :
                                            <p>{t("page-profile.view-svc-scheduling.scheduling-calendar.no-dates-left")}</p>
                                }
                            </div>

                    }
                </div>
                <div className="buttons-nav-container">
                    <Button className='button try small-med' onClick={handleBack} disabled={loading || isLoading}>{t("nav-buttons.back")}</Button>
                    <Button className='button try small-med' onClick={handleNext} disabled={loading || isLoading || !(schedule.booked_date && schedule.booked_time)}>{t("nav-buttons.schedule")}</Button>
                </div>

                <ApproveModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    booked_date={(availableDates && availableDates.length !== 0 && (clickedDateIndex !== (-1))) ? availableDates[clickedDateIndex].date : t("page-profile.view-svc-scheduling.scheduling-calendar.date-error")}
                    booked_time={(availableHours && availableHours.length !== 0) ? availableHours[clickedTimeIndex] : t("page-profile.view-svc-scheduling.scheduling-calendar.time-error")}
                    createReservation={createReservation}
                />
            </div>
        </div>

    )
}

export default SchedulingCalendar
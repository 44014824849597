import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from "../../context/AuthContext"
import Login from '../../pages/Login/Login'
import SectionNewOrder from '../../pages/Purchase/Sections/SectionNewOrder/SectionNewOrder'


const PrivateRoutePurchase = () => {

    const { currentUser } = useAuth()

    return currentUser ? <><SectionNewOrder /></> : <><Login /><Navigate to="/logowanie" /></>

}

export default PrivateRoutePurchase


import React, { useRef, useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import OrderStep from '../OrderStep/OrderStep';
import ViewChooseOptions from './ViewChooseOptions/ViewChooseOptions';
import Calendar from '../../../../../../components/Calendar/Calendar';
import './OrderStepOne.css'
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation, Trans } from 'react-i18next';

const OrderStepOne = ({ setNextDisabled, orderDetails, handleStateChange, setStepTwoDisabled, stepTwoDisabled, users_number }) => {

    const emailRef1 = useRef()
    const emailRef2 = useRef()
    const { currentUser } = useAuth()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [schedulingVisible, setSchedulingVisible] = useState(false)
    const [validEmail, setValidEmail] = useState(true)
    const [validEmail2, setValidEmail2] = useState(true)
    const [validOrderDetails, setValidOrderDetails] = useState(true)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const { t } = useTranslation('translation')


    const handleEmailChange = (index) => {

        if (index === 0 || !index) {

            const email = emailRef1?.current?.value;
            if (!email) {
                return setValidEmail(true)
            }

            if (!orderDetails?.purchaseForMyself && emailRef1?.current?.value === currentUser?.email) {
                return setValidEmail(false)
            }

            if (emailRegex.test(email) && emailRef2?.current?.value !== email) {
                setValidEmail(true)
            } else {
                setValidEmail(false)
            }

        } else if (index === 1) {
            const email = emailRef2?.current?.value;
            if (emailRegex.test(email) && emailRef1?.current?.value !== email) {
                setValidEmail2(true)
            } else {
                setValidEmail2(false)
            }

        }

    }

    useEffect(() => {

        if (users_number > 1) {
            if ((validEmail && (emailRef1?.current?.value)?.trim() !== '') && (validEmail2 && (emailRef2?.current?.value)?.trim() !== '')) {
                setStepTwoDisabled(false)
            } else {
                setStepTwoDisabled(true)
            }
        } else {
            if ((validEmail && (emailRef1?.current?.value)?.trim() !== '')) {
                setStepTwoDisabled(false)
            } else {
                setStepTwoDisabled(true)
            }
        }
    }, [validEmail, validEmail2, emailRef1?.current?.value, emailRef2?.current?.value])

    useEffect(() => {

        if (orderDetails?.purchaseForMyself && currentUser?.email) {
            return setValidEmail(true)
        }

    }, [orderDetails?.purchaseForMyself])

    useEffect(() => {
        if (!(orderDetails?.productPrice && orderDetails?.serviceName && orderDetails?.productName && orderDetails?.serviceDuration && orderDetails?.supportDays && orderDetails?.type)) {
            setValidOrderDetails(false)
            return
        }

        setNextDisabled(true)

        if (users_number > 1) {
            handleStateChange('serviceClient', {
                email_user1: emailRef1?.current?.value,
                email_user2: emailRef2?.current?.value
            })
        } else {
            handleStateChange('serviceClient', { email_user1: emailRef1?.current?.value })
        }

        handleStateChange('bookedDate', { date: null, time: null })

        !(orderDetails.serviceClient) && setStepTwoDisabled(true)
    }, [])

    useEffect(() => {
        if (users_number > 1) {
            if (
                orderDetails.serviceClient?.email_user1 &&
                orderDetails.serviceClient?.email_user2 &&
                orderDetails.bookedDate?.date &&
                orderDetails.bookedDate?.time && ((validEmail & (emailRef1?.current?.value)?.trim() !== '') && (validEmail2 & (emailRef2?.current?.value)?.trim() !== ''))
            ) {
                setNextDisabled(false)
            }
            else {
                setNextDisabled(true)
            }
        } else {
            if (
                orderDetails.serviceClient?.email_user1 &&
                orderDetails.bookedDate?.date &&
                orderDetails.bookedDate?.time && (validEmail & (emailRef1?.current?.value)?.trim() !== '')
            ) {
                setNextDisabled(false)
            }
            else {
                setNextDisabled(true)
            }
        }

    }, [orderDetails, users_number])


    const handleOptionChange = () => {
        setButtonDisabled(!buttonDisabled)

    }
    const handleButtonClick = () => {
        setSchedulingVisible(!schedulingVisible)
    }

    return (
        <>
            {validOrderDetails ?
                <>
                    <Col xl={6} lg={6} md={12} sm={12} >
                        <OrderStep className='step-one flex-start'>
                            <h1>{t("page-purchase.order-step-one.header")}</h1>
                            <h3>{t("page-purchase.order-step-one.sub-header")}</h3>
                            <Form className="form new-order-client" onSubmit={e => { e.preventDefault() }} noValidate>
                                {
                                    users_number > 1 ?
                                        <>
                                            {
                                                Array.from({ length: users_number }).map((_, index) => (
                                                    <Form.Group key={index} className="form-group" id={`form-order-emails-group-${index}`}>
                                                        <Form.Label>{`${t("page-purchase.order-step-one.client-email-label")} ${index + 1}:`}</Form.Label>
                                                        <Form.Control
                                                            key={index}
                                                            type="email"
                                                            isInvalid={{ "0": !validEmail, "1": !validEmail2, }[index]}
                                                            defaultValue={users_number > 1 ? orderDetails?.serviceClient?.[`email_user${index + 1}`] : orderDetails?.serviceClient?.email_user1}
                                                            required
                                                            ref={{ "0": emailRef1, "1": emailRef2, }[index]}
                                                            placeholder={`${t("page-purchase.order-step-one.client-email-placeholder")} ${index + 1}`} // Display an incremental placeholder for each input
                                                            onChange={(event) => {
                                                                users_number > 1 ? handleStateChange('serviceClient', {
                                                                    ...orderDetails.serviceClient,
                                                                    [`email_user${index + 1}`]: event.target.value
                                                                })
                                                                    :
                                                                    handleStateChange('serviceClient',
                                                                        {
                                                                            ...orderDetails.serviceClient,
                                                                            email_user1: event.target.value
                                                                        });
                                                                handleEmailChange(index)
                                                            }}
                                                            className='mb-3'
                                                            maxLength={45}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.wrong-email")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                ))
                                            }
                                        </>
                                        :

                                        <>
                                            {
                                                orderDetails?.purchaseForMyself ?
                                                    <Form.Group className="form-group" id="form-order-user-email-group" style={{ marginBottom: '1rem' }}>
                                                        <Form.Label>{t("page-purchase.order-step-one.your-email-label")}</Form.Label>
                                                        <Form.Control style={{ fontFamily: 'Gilroy-semibold, sans-serif' }}
                                                            type="email"
                                                            isInvalid={!validEmail}
                                                            defaultValue={currentUser?.email}
                                                            required
                                                            disabled
                                                            ref={emailRef1}
                                                            placeholder={t("page-purchase.order-step-one.your-email-placeholder")}
                                                            onChange={(event) => {
                                                                handleStateChange('serviceClient', { email_user1: event.target.value });
                                                                handleEmailChange(0)
                                                            }}
                                                            maxLength={45}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.wrong-email")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    :
                                                    <Form.Group className="form-group" id="form-order-emails-group" style={{ marginBottom: '1rem' }}>
                                                        <Form.Label>{t("page-purchase.order-step-one.client-email-label-solo")}</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            isInvalid={!validEmail}
                                                            defaultValue={''}
                                                            required
                                                            ref={emailRef1}
                                                            placeholder={t("page-purchase.order-step-one.client-email-placeholder-solo")}
                                                            onChange={(event) => {
                                                                handleStateChange('serviceClient', { email_user1: event.target.value });
                                                                handleEmailChange(0)
                                                            }}
                                                            maxLength={45}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.wrong-email")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                            }
                                            <div className="choose-option-container flex-start small">
                                                <div className="options-container">
                                                    <div className="option-wrapper">
                                                        <input type="radio" name="select-purchase-for-myself" id="select-purchase-for-myself" defaultChecked={true} onChange={(event) => {
                                                            event.target.checked && handleStateChange('purchaseForMyself', true)
                                                            event.target.checked && (emailRef1.current.value = currentUser?.email)
                                                            event.target.checked && handleEmailChange(0)
                                                        }} />
                                                        <label htmlFor="select-purchase-for-myself" className="option">
                                                            <div className="dot-wrapper">
                                                                <div className="dot"></div>
                                                            </div>
                                                            <span>{t("page-purchase.order-step-one.for-yourself")}</span>
                                                        </label>
                                                    </div>
                                                    <div className="option-wrapper">
                                                        <input type="radio" name="select-purchase-for-myself" id="select-purchase-for-somebody" onChange={(event) => {
                                                            event.target.checked && handleStateChange('purchaseForMyself', false)
                                                            event.target.checked && (emailRef1.current.value = '')
                                                            event.target.checked && handleEmailChange(0)
                                                        }} />
                                                        <label htmlFor="select-purchase-for-somebody" className="option">
                                                            <div className="dot-wrapper">
                                                                <div className="dot"></div>
                                                            </div>
                                                            <span>{t("page-purchase.order-step-one.for-somebody")}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </Form>
                        </OrderStep>
                    </Col >
                    <Col xl={6} lg={6} md={12} sm={12}>
                        <OrderStep disabled={stepTwoDisabled}>
                            <h1>{t("page-purchase.order-step-one.header-two")}</h1>
                            {
                                <div className="content-container">
                                    {
                                        orderDetails?.type === 'promo'
                                            ?
                                            <>
                                                <h3><Trans i18nKey="page-purchase.order-step-one.promo-package-note" components={[<strong />, <strong />, <strong />]} /></h3>
                                                <ViewChooseOptions setNextDisabled={setNextDisabled} orderDetails={orderDetails} handleStateChange={handleStateChange} buttonDisabled={true} buttonHidden={true} handleOptionChange={handleOptionChange} handleButtonClick={handleButtonClick} />
                                            </>
                                            :
                                            (
                                                schedulingVisible && orderDetails?.purchaseForMyself ?
                                                    <>
                                                        <h3 style={{ marginBottom: '1rem' }}>{t("page-purchase.order-step-one.choose-date-and-time")}</h3>
                                                        <Calendar orderDetails={orderDetails} handleStateChange={handleStateChange} setSchedulingVisible={setSchedulingVisible} />
                                                    </>
                                                    :
                                                    orderDetails?.purchaseForMyself ?
                                                        <>
                                                            <h3><Trans i18nKey="page-purchase.order-step-one.choose-appointment-regular" components={[<span style={{ fontStyle: 'italic', fontWeight: 'bold' }} />]} /></h3>
                                                            <ViewChooseOptions setNextDisabled={setNextDisabled} orderDetails={orderDetails} handleStateChange={handleStateChange} buttonDisabled={buttonDisabled} handleOptionChange={handleOptionChange} handleButtonClick={handleButtonClick} />

                                                        </>
                                                        :
                                                        <>
                                                            <h3><Trans i18nKey="page-purchase.order-step-one.decide-later-option-for-others" components={[<span style={{ fontStyle: 'italic', fontWeight: 'bold' }} />, <span style={{ fontStyle: 'italic', fontWeight: 'bold' }} />]} /></h3>
                                                            <ViewChooseOptions setNextDisabled={setNextDisabled} orderDetails={orderDetails} handleStateChange={handleStateChange} buttonDisabled={true} buttonHidden={true} handleOptionChange={handleOptionChange} handleButtonClick={handleButtonClick} />
                                                        </>
                                            )
                                    }
                                </div>
                            }

                        </OrderStep>
                    </Col >
                </> :
                <>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <OrderStep className='center'>
                            <h1>{t("errors.error-occured")}</h1>
                            <h3>{t("page-purchase.section-new-order.browser-tab-changed")}</h3>
                        </OrderStep>
                    </Col>
                </>
            }
        </>

    )
}

export default OrderStepOne
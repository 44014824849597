import React from 'react'
import './AdminFunctionBar.css'

const AdminFunctionBar = ({ children, title, onClick }) => {
    return (
        <div className="component-admin-function-bar" onClick={onClick}>
            <div className="component-admin-function-bar-inner">
                <div className="title-container">
                    <h1>{title}</h1>
                </div>
                <div className="content-container">
                    {children}
                </div>

            </div>
        </div>
    )
}

export default AdminFunctionBar
import React from 'react'
import '../UserDataForm/UserDataForm'
import UserDataForm from '../UserDataForm/UserDataForm'
import SimpleContainer from '../../SimpleContainer/SimpleContainer'

const ProfileUserData = () => {
  return (
    <>
      <SimpleContainer>
        <UserDataForm />
      </SimpleContainer>
    </>
  )
}

export default ProfileUserData
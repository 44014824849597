import supabase from "../../../../data/supabase";
import sendMail from "../../../../utils/sendMail";

async function adminChangeStatusOfService(service_id, status) {
    try {
        const { data, error } = await supabase
            .from('booked_services')
            .update(
                {
                    'status': status
                }
            )
            .eq('id', service_id)
            .select('*, client_details(email)')
            .single()


        if (error || data.length === 0) throw new Error('Wystąpił błąd podczas zmiany statusu.')
        let support_days = ''

        switch (data.service_name) {
            case 'STANDARD':
                support_days = 30;
                break;
            case 'PRO':
                support_days = 60;
                break;
            case 'PREMIUM':
                support_days = 90;
                break;
        }

        let subjectsCompleted = {
            'en': 'Your service has been completed',
            'pl': 'Twoja usługa została zakończona'
        }

        let subjectsInteraction = {
            'en': 'Service interaction required',
            'pl': 'Wymagana interakcja przy usłudze'
        }
        
        switch (status) {
            case 'completed':
                await sendMail({ author: 'noreply', receiver: data.client_details.email, subject: subjectsCompleted[data?.language], service_name: data.service_name, template: 'u_svcCompletedTemplate', support_days: support_days, language: data.language })
                break;
            case 'prepare':
                await sendMail({ author: 'noreply', receiver: data.client_details.email, subject: subjectsInteraction[data?.language], service_name: data.service_name, service_status: 'PRZYGOTUJ', template: 'u_svcStatusChangedTemplate', language: data.language })
                break;
            case 'start':
                await sendMail({ author: 'noreply', receiver: data.client_details.email, subject: subjectsInteraction[data?.language], service_name: data.service_name, service_status: 'ROZPOCZNIJ', template: 'u_svcStatusChangedTemplate', language: data.language })
                break;
            default:
                break;
        }

        return { data, error }

    } catch (error) {
        return { data: null, error }
    }
}

export default adminChangeStatusOfService
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from "../../context/AuthContext"
import Profile from '../../pages/Profile/Profile'
import Login from '../../pages/Login/Login'

const PrivateRouteLogin = () => {

    const { currentUser } = useAuth()

    return currentUser ? <> <Profile /> <Navigate to='/konto' /> </> : <Login />

}

export default PrivateRouteLogin


import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { useTranslation, Trans } from 'react-i18next'
import Text from '../../../../components/Text/Text'
import './SectionHowItWorks.css'
import GamingModeJoy from '../../../../assets/img/gaming-mode/gaming-mode-joy.webp'
const SectionHowItWorks = () => {

    const { t } = useTranslation()
    return (
        <section id='gm-how-it-works' className='gm-how-it-works'>
            <Container>
                <Row style={{display: "flex", alignItems: "center"}}>
                    <Col xl={6} lg={6} md={0} sm={0} >
                        <Text
                            style={{ paddingTop: "0", marginTop: "0", marginBottom: "0" }}
                            h4={t("page-gamingmode.section-gm-how-it-works.header")}
                            p={
                                <>
                                    <span style={{ fontWeight: "bold" }}><Trans i18nKey={"page-gamingmode.section-gm-how-it-works.text-upper"} /><br /><br /></span>
                                </>
                            }
                        />
                        <Text
                            className={"small"}
                            style={{ paddingTop: "0", marginTop: "0", marginBottom: "0" }}
                            p={
                                <>
                                    <span><Trans i18nKey={"page-gamingmode.section-gm-how-it-works.text-bottom"} /></span>
                                </>
                            }
                        />
                    </Col>
                    <Col xl={6} lg={6} md={0} sm={0} className='gm-image-container' style={{ textAlign: "right" }}>
                        <Image
                            src={GamingModeJoy}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SectionHowItWorks
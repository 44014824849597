import React, { useState, useEffect } from "react";
import i18n from "../../i18n";
import PLFlag from "../../assets/img/icons/flag-pl.png"
import ENFlag from "../../assets/img/icons/flag-uk.png"
import "./LanguageSelector.css"
import { useDatabasePageContext } from "../../context/DatabasePageContext";


const LanguageSelector = () => {

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.
    const { timezone, getPackages } = useDatabasePageContext()

    useEffect(() => {
        // Ustawienie języka na podstawie wartości w localStorage
        const savedLanguage = localStorage.getItem("lang")
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
            setSelectedLanguage(savedLanguage);
        } else {
            const defaultLanguage = timezone === 'Europe/Warsaw' ? 'pl' : 'en';
            i18n.changeLanguage(defaultLanguage);
            setSelectedLanguage(defaultLanguage);
            localStorage.setItem("lang", defaultLanguage);
        }
    }, []);

    const toggleLanguage = () => {
        const newLanguage = selectedLanguage === 'pl' ? 'en' : 'pl';
        i18n.changeLanguage(newLanguage);
        setSelectedLanguage(newLanguage);
        localStorage.setItem("lang", newLanguage);
        getPackages()
    };

    return (
        <div className="language-selector" onClick={toggleLanguage} style={{ cursor: 'pointer' }}>
            <img
                src={selectedLanguage === 'pl' ? ENFlag : PLFlag}
                alt="Change Language"
                style={{ width: '24px', height: '17px' }} />
        </div>
    );
};

export default LanguageSelector;
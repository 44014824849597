import React, { useContext, createContext, useState, useEffect } from 'react'
import supabase from '../data/supabase';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment-timezone';
import { useDatabasePageContext } from './DatabasePageContext';

const DatabaseUserContext = createContext()

export function useDatabaseUserContext() {
    return useContext(DatabaseUserContext)
}

export const DatabaseUserProvider = ({ children }) => {


    // states of data to be received from database
    const [bookedServices, setBookedServices] = useState(null)
    const [orders, setOrders] = useState(null)
    const [userData, setUserData] = useState(null)
    const { timezone } = useDatabasePageContext()
    // ##########################################
    // Hooks Functions Read
    // ##########################################

    function useReadBookedServices(email) {
        useEffect(() => {
            if (email) {
                getBookedServices(email);
            }
        }, [email]
        )
    }

    function useReadOrders(id) {
        useEffect(() => {
            if (id) {
                getOrders(id);
            }
        }, [id]
        )
    }

    function useReadUserData(id) {
        useEffect(() => {
            if (id) {
                getUserData(id);
            }
        }, [id]
        )
    }


    // ##########################################
    // Functions Read
    // ##########################################

    async function getBookedServices(email) {

        try {
            const { data, error } = await supabase
                .rpc('get_booked_services', { user_email: email })
                .order('id', { ascending: false })

            if (error) throw error;

            if (data != null) {
                setBookedServices(data);
            }

        } catch (error) {
        }
    }


    async function getOrders(id) {
        try {
            const { data, error } = await supabase
                .from('order_clients_view')
                .select('*, packages(id,name,price)')
                .eq('user_id', id)
                .order('order_id', { ascending: false })


            if (error) throw error;

            if (data != null) {
                let dataNew = data.filter(order => order.paid === true)
                setOrders(dataNew);
            }
        } catch (error) {
        }
    }

    async function getUserData(id) {

        try {
            const { data, error } = await supabase
                .from('users')
                .select('*')
                .eq('id', id)
                .single()

            if (error) throw error;

            if (data != null) {
                setUserData(data);
            }

        } catch (error) {
        }
    }

    // ##########################################
    // Functions Write & Update
    // ##########################################

    async function updateUserData(id, first_name, last_name, phone_number, phone_number_prefix) {
        try {
            const { data, error } = await supabase
                .from('users')
                .update({
                    'first_name': first_name,
                    'last_name': last_name,
                    'phone_number': phone_number,
                    'phone_number_prefix': phone_number_prefix
                })
                .eq('id', id)

            if (error) throw error;
        } catch (error) {
        }
    }

    async function updateClientDetails(email, first_name, last_name, phone_number, phone_number_prefix) {
        try {
            const { data, error } = await supabase
                .from('client_details')
                .update({
                    'first_name': first_name,
                    'last_name': last_name,
                    'phone_number': phone_number,
                    'phone_number_prefix': phone_number_prefix
                })
                .eq('email', email)
                .select()
                .single()

            if (error) throw error;

            return { data, error }

        } catch (error) {
            return { data: null, error }
        }
    }

    async function writePrepareDetails(message, prepare_option, answers, contact_option) {
        try {
            const { data, error } = await supabase
                .from('client_prepare_details')
                .insert({
                    'message': message,
                    'prepare_option': prepare_option,
                    'answers': answers,
                    'contact_option': contact_option
                })
                .select()
                .single()

            // jesli wyrzuci blad, linijka ponizej nie wykona sie, a funkcja przejdzie do catch()
            if (error) throw error;

            // wykona sie jesli nie ma bledu
            return { data, error }

        } catch (error) {
            return { data: null, error }
        }
    }

    async function updateBookedServices(id, client_prepare_details_id, status) {

        try {
            const { data, error } = await supabase
                .rpc('update_booked_services', {
                    p_id: id,
                    p_client_prepare_details_id: client_prepare_details_id,
                    p_status: status
                })
                .single()

            if (error) throw error;
            return { data, error }

        } catch (error) {
            return { data: null, error }
        }
    }

    async function updateBookedServiceStatus(id, status) {
        try {
            const { data, error } = await supabase
                .from('booked_services')
                .update({ 'status': status })
                .eq('id', id)
                .select()
                .single()

            if (error) throw error;
            return { data, error }
        } catch (error) {
            return { data: null, error }
        }
    }

    async function updateBookedServiceSchedule(id, booked_date) {

        let new_date_moment = moment.tz(`${booked_date}`, timezone)

        try {
            const { data, error } = await supabase
                .from('booked_services')
                .update({
                    'booked_date': new_date_moment
                })
                .eq('id', id)
                .select('*, client_details(email)')
                .single()

            if (error) throw error;
            return { data, error }
        } catch (error) {
            return { data: null, error }
        }
    }

    async function removeEmptyAvailableDay(date) {
        try {
            const { data, error } = await supabase
                .from('available_dates')
                .delete()
                .eq('date', date)

            if (error) throw error
            return { data, error }

        } catch (error) {
            return { data: null, error }
        }
    }

    async function updateAvailableHours(date, available_hours) {
        try {

            const { data, error } = await supabase
                .from('available_dates')
                .update({
                    'available_hours': available_hours
                })
                .eq('date', date)
                .select()
                .single()

            if (error) throw error;
            return { data, error }

        } catch (error) {
            return { data: null, error }
        }
    }

    // ##########################################
    // Values passed by Context
    // ##########################################

    const value = {
        useReadBookedServices,
        useReadOrders,
        useReadUserData,
        getUserData,
        updateUserData,
        writePrepareDetails,
        updateBookedServices,
        updateClientDetails,
        updateBookedServiceStatus,
        updateBookedServiceSchedule,
        updateAvailableHours,
        removeEmptyAvailableDay,
        bookedServices,
        orders,
        userData

    }

    return (
        <DatabaseUserContext.Provider value={value}>
            {children}
        </DatabaseUserContext.Provider>
    )


}

export default DatabaseUserProvider

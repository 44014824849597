import React from 'react';
import LoginForm from './Sections/LoginForm/LoginForm'
import './Login.css';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import Footer from '../../components/Footer/Footer';
import DatabaseUserProvider
    from '../../context/DatabaseUserContext';
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel';
const Login = () => {

    return (
        <>
            <TopNavigation />
            <main className="page login">
                <SocialMediaPanel />
                <DatabaseUserProvider>
                    <LoginForm />
                </DatabaseUserProvider>
            </main>
            <Footer />
        </>
    )

}

export default Login
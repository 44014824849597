import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Form, Alert, Container, Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import Button from '../../../../components/Button/Button';
import './PasswordResetForm.css';
import { useAuth } from '../../../../context/AuthContext';
import supabase from '../../../../data/supabase';
import { Turnstile } from '@marsidev/react-turnstile'
import { Trans, useTranslation } from 'react-i18next'

const PasswordResetForm = () => {

    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const [captchaToken, setCaptchaToken] = useState()
    const [validEmail, setValidEmail] = useState(true)
    const [validEmail2, setValidEmail2] = useState(true)
    const { currentUser } = useAuth()
    const emailRef = useRef()
    const emailRef2 = useRef()
    const { t } = useTranslation('translation')
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleEmailChange = () => {
        const email = emailRef.current.value;
        if (emailRegex.test(email)) {
            setValidEmail(true)
        } else {
            setValidEmail(false)
        }

        if (!emailRef2.current.value) {
            setValidEmail2(false)
        }
    }

    const handleEmailChange2 = () => {
        const email = emailRef2.current.value;
        if (emailRegex.test(email)) {
            setValidEmail2(true)
        } else {
            setValidEmail2(false)
        }

        if (!emailRef.current.value) {
            setValidEmail(false)
        }

        if (!(emailRef.current.value === email)) {
            setValidEmail2(false)
        }

    }

    async function handleSubmit(e) {

        e.preventDefault()
        if (!captchaToken) {
            return setError(t("forms.error.captcha-error"))
        }

        const email = (emailRef.current.value).trim()
        const email2 = (emailRef2.current.value).trim()
        if (!emailRegex.test(email) || (!emailRegex.test(email2))) {
            return setError(t("forms.feedback.wrong-email"))
        }
        if (emailRef.current.value !==
            emailRef2.current.value) {
            return setError(t("forms.feedback.emails-differ"))
        }
        try {
            setError('')
            setErrorSuccess('')
            setLoading(true)

            const { data, error } = await supabase.auth.resetPasswordForEmail(emailRef.current.value, {
                captchaToken: captchaToken,
                redirectTo: 'https://gainfps.com/reset-hasla/formularz'
                // redirectTo: 'http://localhost:4747/reset-hasla/formularz'
            })

            if (error) {
                setError(t("errors.error-occured"))
            }
            else {
                // rejestracja powiodła się
                setError('')
                setErrorSuccess(t("messages.check-your-mailbox"))
            }

        } catch (error) {
            setError(error.message)
        }

        setLoading(false)
    }
    return (
        <>
            <section id='section-pw-reset-form' className='section-pw-reset-form'>
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <div className="form-container">
                                <div className="form-wrapper">
                                    {
                                        loading ?
                                            <div className="spinner-container">
                                                <Spinner animation="border" className='loading-spinner' />
                                            </div>
                                            :
                                            <>
                                                <Form onSubmit={handleSubmit} className="form pw-reset-form" noValidate>
                                                    <h1>{t("page-password-reset.section-pw-reset-form.header")}</h1>
                                                    {error && <Alert variant="danger">{error}</Alert>}
                                                    {errorSuccess && <Alert variant='success'>{errorSuccess}</Alert>}
                                                    <Form.Group className="form-group" id="email">
                                                        <Form.Label>{t("forms.label.email")}</Form.Label>
                                                        <Form.Control type="email" ref={emailRef} defaultValue={currentUser?.email} required placeholder={t("forms.placeholder.email")} maxLength={45} onChange={handleEmailChange} isInvalid={!validEmail} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.wrong-email")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group className="form-group" id="email">
                                                        <Form.Label>{t("forms.label.confirm-email")}</Form.Label>
                                                        <Form.Control type="email" ref={emailRef2} defaultValue={currentUser?.email} required placeholder={t("forms.placeholder.confirm-email")} maxLength={45} onChange={handleEmailChange2} isInvalid={!validEmail2} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.placeholder.confirm-email")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <div className="turnstile-container">
                                                        <Turnstile
                                                            siteKey='0x4AAAAAAAJx1CAT2qk2tue5'
                                                            onSuccess={(token) => {
                                                                setCaptchaToken(token)
                                                                setError('')
                                                            }}
                                                            onError={(token) => {
                                                                setCaptchaToken(null)
                                                                setError(t("forms.error.captcha-error"))

                                                            }} />
                                                    </div>
                                                    <Button type='submit' className="try hover2" disabled={loading}>
                                                        {t("page-password-reset.section-pw-reset-form.reset-button")}
                                                    </Button>
                                                </Form>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="have-account-text-wrapper">
                                <p><Trans i18nKey={"page-password-reset.section-pw-reset-form.have-account-text"}>Powrót do <Link to='/logowanie'><span className='highlighted'>Logowania</span></Link></Trans></p>
                            </div>
                        </Col>
                    </Row>
                </Container >

            </section >
        </>
    )
}

export default PasswordResetForm
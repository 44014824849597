import React from 'react'
import "./StatuteParagraph.css"
const StatuteParagraph = ({ className, children }) => {

    return (
        <>
            <div className={"component-statute-paragraph " + className}>
                <div className="component-statute-paragraph-inner">
                    {children}
                </div>
            </div>
        </>
    )
}

export default StatuteParagraph;
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Form, Alert, Container, Row, Col, InputGroup } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import Button from '../../../../components/Button/Button';
import './PasswordSetNewForm.css';
import { useAuth } from '../../../../context/AuthContext';
import supabase from '../../../../data/supabase';
import { Turnstile } from '@marsidev/react-turnstile'
import visibleOffIcon from '../../../../assets/img/icons/visibility_off_black_36dp.svg'
import visibleOnIcon from '../../../../assets/img/icons/visibility_black_36dp.svg'
import { useTranslation } from 'react-i18next'

const PasswordSetNewForm = () => {

    const [error, setError] = useState('')
    const [errorSuccess, setErrorSuccess] = useState('')
    const [loading, setLoading] = useState(true)
    const [captchaToken, setCaptchaToken] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [validPassword, setValidPassword] = useState(true);
    const [validConfPassword, setValidConfPassword] = useState(true); // Dodaj stan do śledzenia walidacji hasła
    const [hash, setHash] = useState(null)
    const passwordRef = useRef()
    const passwordConfRef = useRef()
    const { logout } = useAuth()
    const navigate = useNavigate()
    const { t } = useTranslation("translation")
    useEffect(() => {
        setHash(window.location.hash);
    }, [])

    useEffect(() => {
        setError('')
        let type;
        let accessToken;
        let refreshToken;
        if (hash) {
            const hashArr = hash.substring(1).split("&").map((param) => param.split("="));

            for (const [key, value] of hashArr) {
                if (key === "type") {
                    type = value;
                } else if (key === 'access_token') {
                    accessToken = value;
                } else if (key === 'refresh_token') {
                    refreshToken = value;
                }
            }

            if (type !== 'recovery' || !accessToken || typeof accessToken === 'object' || !refreshToken) {
                setLoading(false)
                return setError(t("page-password-reset.section-pw-set-new-form.not-confirmed-error"))
            }
        } else {
            setLoading(false)
            return setError(t("page-password-reset.section-pw-set-new-form.not-confirmed-error"))
        }
        setLoading(false)

    }, [hash])

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = () => {
        const password = passwordRef.current.value;
        const confirmPassword = passwordConfRef.current.value;
        if (passwordRegex.test(password)) {
            setValidPassword(true);
        } else {
            setValidPassword(false);
        }
        if (password === confirmPassword) {
            setValidConfPassword(true);
        } else {
            setValidConfPassword(false);
        }
    };

    const handlePasswordConfChange = () => {
        const password = passwordRef.current.value;
        const confirmPassword = passwordConfRef.current.value;

        // Dodaj dowolną logikę walidacji hasła. Poniżej przykład sprawdzania, czy hasło ma co najmniej 8 znaków.
        if (password === confirmPassword) {
            setValidConfPassword(true);
        } else {
            setValidConfPassword(false);
        }
        // Możesz dodać więcej logiki walidacji, np. sprawdzenie, czy hasło zawiera pewne znaki, duże litery, cyfry, etc.
    };

    async function handleSubmit(e) {
        e.preventDefault()

        const password = passwordRef.current.value;
        const passwordConfirm = passwordConfRef.current.value;
        // Walidacja nowego hasła
        if (!password || !passwordRegex.test(password)) {
            setLoading(false);
            return setError(t("page-password-reset.section-pw-set-new-form.new-pw-error"));
        }

        // Walidacja potwierdzenia hasła
        if (password !== passwordConfirm) {
            setLoading(false);
            return setError(t("forms.feedback.passwords-differ"));
        }

        setLoading(true)
        setError('')
        setErrorSuccess('')
        let type;
        let accessToken;
        let refreshToken;


        try {
            if (hash) {
                const hashArr = hash.substring(1).split("&").map((param) => param.split("="));

                for (const [key, value] of hashArr) {
                    if (key === "type") {
                        type = value;
                    } else if (key === 'access_token') {
                        accessToken = value;
                    } else if (key === 'refresh_token') {
                        refreshToken = value;
                    }
                }

                if (type !== 'recovery' || !accessToken || typeof accessToken === 'object' || !refreshToken) {
                    setLoading(false)
                    return setError(t("page-password-reset.section-pw-set-new-form.not-confirmed-error"))
                }

                try {
                    const { data: sessionData, error: sessionError } = await supabase.auth.setSession({ access_token: accessToken, refresh_token: refreshToken })
                    if (sessionError) throw sessionError

                } catch (error) {
                    setError(t("errors.page-error"))
                    setTimeout(() => {
                        navigate('/')
                    }, 2000)
                    return
                }

            } else {
                setLoading(false)
                return setError(t("page-password-reset.section-pw-set-new-form.not-confirmed-error"))
            }
        } catch (error) {
            return
        }

        try {

            const { data: updateData, error: updateError } = await supabase.auth.updateUser({ password: password }, captchaToken)
            if (updateError) throw updateError
            setErrorSuccess(t("page-password-reset.section-pw-set-new-form.success-pw-changed"))
            await logout();
            setTimeout(async () => {
                navigate('/logowanie')
            }, 2000);

        } catch (error) {
            if (error?.message.includes('New password should be different from the old password.')) {
                setError(t("page-password-reset.section-pw-set-new-form.pw-not-differs"))
            }
            else {
                setError(t("page-password-reset.section-pw-set-new-form.pw-error"))
            }

        }

        setLoading(false)
    }

    return (
        <section id='section-pw-set-new-form' className='section-pw-set-new-form'>
            <Container>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <div className="form-container">
                            <div className="form-wrapper">
                                {
                                    loading ?
                                        <div className="spinner-container">
                                            <Spinner animation="border" className='loading-spinner' />
                                        </div>
                                        :
                                        <>
                                            <Form onSubmit={handleSubmit} className="form pw-set-new-form" noValidate>
                                                <h1>{t("page-password-reset.section-pw-set-new-form.header")}</h1>
                                                {error && <Alert variant="danger">{error}</Alert>}
                                                {errorSuccess && <Alert variant="success">{errorSuccess}</Alert>}
                                                <Form.Group className="form-group settings">
                                                    <Form.Label>{t("page-password-reset.section-pw-set-new-form.new-password")}</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control type={showPassword ? "text" : "password"} ref={passwordRef} required placeholder={t("forms.placeholder.enter-password")} maxLength={45} minLength={8} onChange={handlePasswordChange} // Dodaj obsługę zmiany hasła
                                                            isInvalid={!validPassword} />
                                                        <span className="form-control password-toggle" style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }} onClick={togglePasswordVisibility}>
                                                            {showPassword ? <img src={visibleOffIcon} alt='visible icon' /> : <img src={visibleOnIcon} alt='visible icon' />}
                                                        </span>
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("forms.feedback.wrong-password-format")}
                                                        </Form.Control.Feedback>
                                                    </InputGroup >
                                                </Form.Group>
                                                <Form.Group className="form-group settings">
                                                    <Form.Label>{t("page-password-reset.section-pw-set-new-form.repeat-new-pw")}</Form.Label>
                                                    <Form.Control type="password" ref={passwordConfRef} placeholder={t("page-password-reset.section-pw-set-new-form.repeat-new-pw-ph")} maxLength={45} isInvalid={!validConfPassword} onChange={handlePasswordConfChange} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("forms.feedback.passwords-differ")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <div className="turnstile-container">
                                                    <Turnstile
                                                        siteKey='0x4AAAAAAAJx1CAT2qk2tue5'
                                                        onSuccess={(token) => {
                                                            setCaptchaToken(token)
                                                        }}
                                                        onError={(token) => {
                                                            setCaptchaToken(null)
                                                            setError(t("forms.error.captcha-error"))
                                                        }} />
                                                </div>
                                                <Button type='submit' className="try hover2" disabled={loading}>
                                                    {t("page-password-reset.section-pw-set-new-form.change-pw-button")}
                                                </Button>
                                            </Form>
                                        </>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container >

        </section >
    )
}

export default PasswordSetNewForm
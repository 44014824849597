export const phonePrefixOptions = [
    { value: '+355', label: 'Albania (+355)' },
    { value: '+376', label: 'Andora (+376)' },
    { value: '+374', label: 'Armenia (+374)' },
    { value: '+43', label: 'Austria (+43)' },
    { value: '+994', label: 'Azerbejdżan (+994)' },
    { value: '+32', label: 'Belgia (+32)' },
    { value: '+387', label: 'Bośnia i Hercegowina (+387)' },
    { value: '+359', label: 'Bułgaria (+359)' },
    { value: '+385', label: 'Chorwacja (+385)' },
    { value: '+357', label: 'Cypr (+357)' },
    { value: '+382', label: 'Czarnogóra (+382)' },
    { value: '+420', label: 'Czechy (+420)' },
    { value: '+45', label: 'Dania (+45)' },
    { value: '+372', label: 'Estonia (+372)' },
    { value: '+358', label: 'Finlandia (+358)' },
    { value: '+33', label: 'Francja (+33)' },
    { value: '+30', label: 'Grecja (+30)' },
    { value: '+299', label: 'Grenlandia (+299)' },
    { value: '+995', label: 'Gruzja (+995)' },
    { value: '+34', label: 'Hiszpania (+34)' },
    { value: '+31', label: 'Holandia (+31)' },
    { value: '+353', label: 'Irlandia (+353)' },
    { value: '+354', label: 'Islandia (+354)' },
    { value: '+1', label: 'Kanada (+1)' },
    { value: '+370', label: 'Litwa (+370)' },
    { value: '+371', label: 'Łotwa (+371)' },
    { value: '+352', label: 'Luksemburg (+352)' },
    { value: '+356', label: 'Malta (+356)' },
    { value: '+52', label: 'Meksyk (+52)' },
    { value: '+373', label: 'Mołdawia (+373)' },
    { value: '+377', label: 'Monako (+377)' },
    { value: '+49', label: 'Niemcy (+49)' },
    { value: '+47', label: 'Norwegia (+47)' },
    { value: '+48', label: 'Polska (+48)' },
    { value: '+351', label: 'Portugalia (+351)' },
    { value: '+7', label: 'Rosja (+7)' },
    { value: '+40', label: 'Rumunia (+40)' },
    { value: '+378', label: 'San Marino (+378)' },
    { value: '+381', label: 'Serbia (+381)' },
    { value: '+421', label: 'Słowacja (+421)' },
    { value: '+386', label: 'Słowenia (+386)' },
    { value: '+41', label: 'Szwajcaria (+41)' },
    { value: '+46', label: 'Szwecja (+46)' },
    { value: '+380', label: 'Ukraina (+380)' },
    { value: '+1', label: 'USA (+1)' },
    { value: '+36', label: 'Węgry (+36)' },
    { value: '+44', label: 'Wielka Brytania (+44)' },
    { value: '+39', label: 'Włochy (+39)' },
    { value: '+298', label: 'Wyspy Owcze (+298)' },
];

export default phonePrefixOptions;


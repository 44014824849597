import React, { useEffect } from 'react'
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import Footer from '../../components/Footer/Footer';
import SectionFullProfile from './Sections/SectionFullProfile/SectionFullProfile';
import DatabaseUserProvider from '../../context/DatabaseUserContext';
import SocialMediaPanel from '../../components/SocialMediaPanel/SocialMediaPanel';
const Profile = () => {
 

    return (
        <>
            <TopNavigation />
            <DatabaseUserProvider>
                <main className="page profile center">
                    <SocialMediaPanel />
                    <SectionFullProfile />
                </main>
            </DatabaseUserProvider>
            <Footer />
        </>

    )
}


export default Profile;
import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { useAuth } from '../../../../../../context/AuthContext'
import OrderStep from '../OrderStep/OrderStep'
import './OrderStepThree.css'
import { loadStripe } from '@stripe/stripe-js'
import { DotWave } from '@uiball/loaders'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from '../../../../../../components/CheckoutForm/CheckoutForm'
import SponsorSlider from '../../../../../../components/SponsorSlider/SponsorSlider'
import { useDatabasePageContext } from '../../../../../../context/DatabasePageContext'
import { useTranslation } from 'react-i18next'


const OrderStepThree = ({ setNextDisabled, orderDetails, handleStateChange }) => {
    const { currentUser } = useAuth()
    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(true);
    const { exchangeRates } = useDatabasePageContext()
    const { t, i18n } = useTranslation('translation')
    const convertToTargetCurrency = (amountInPLN, targetCurrency) => {
        if (!exchangeRates || !exchangeRates[targetCurrency]) {
            // Błąd: nieznany kurs waluty lub kursy walut nie zostały jeszcze pobrane
            return 0;
        }
        return Math.round(amountInPLN / exchangeRates[targetCurrency]);
    }
    const stripePromise = loadStripe("pk_live_51NdIusFAhhAZaOHRbvWiDUnUfzU0zEhYO292mZQHuY5dxZAmFXmlcb9csi7IhXjFsjrqIH5dNxtNNX0BmxcfZjsT00MHKhSpot")
    // const stripePromise = loadStripe("pk_test_51NdIusFAhhAZaOHRxmlhTwKk7978WHmVzn9hd1Z7HY18hprm4hd4rOc7tDl8rnjIyFr30S3bJMtbISfF2HBlhfVO00ONSTex2f")
    useEffect(() => {
        setNextDisabled(true)
    })

    useEffect(() => {
        setLoading(true);
        // fetch("http://localhost:4747/api/create-payment-intent", {
            fetch("https://gainfps.com/api/create-payment-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                productPrice: orderDetails?.productPrice, productName: orderDetails?.productName, orderId: orderDetails?.orderId, serviceClient1: orderDetails?.serviceClient?.email_user1,
                serviceClient2: orderDetails?.serviceClient?.email_user2, bookedDate: orderDetails?.bookedDate?.date, bookedTime: orderDetails?.bookedDate?.time, serviceName: orderDetails?.serviceName, userEmail: currentUser?.email,
                serviceDuration: orderDetails?.serviceDuration, discountCode: orderDetails?.discountCode?.code, currency: orderDetails?.currency, userId: currentUser?.id, timezone: orderDetails?.timezone, language: i18n?.language
            }),
        })
            .then(async (res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }

                try {
                    const { clientSecret } = await res.json();
                    setClientSecret(clientSecret);
                } catch (error) {
                    // Obsługa błędu podczas parsowania JSON
                }
            })
            .catch((error) => {
                // Obsługa błędu sieciowego lub innych błędów w fetch
            })
            .finally(() => {
                setLoading(false);
            });
    }, [orderDetails?.currency]);

    const appearance = {
        theme: "night",
        variables: {
            colorPrimary: "rgb(0,224,255)",
        },
    };

    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            <Col xl={6} lg={6} sm={12} md={12}>
                <OrderStep className='payment'>
                    <h1>{t("page-purchase.order-step-three.payment-header")}</h1>
                    <h3>{t("page-purchase.order-step-three.choose-payment-method")}</h3>
                    <div className="component-product-payment">
                        <div className="component-product-payment-inner">
                            <div className="payment-form-container">
                                {loading ? (
                                    <DotWave size={98} color="rgb(0, 224, 255)" />
                                ) : (
                                    <>
                                        {stripePromise && clientSecret && (
                                            <Elements stripe={stripePromise} options={options}>
                                                <CheckoutForm
                                                    orderDetails={orderDetails}
                                                    handleStateChange={handleStateChange}
                                                />
                                            </Elements>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </OrderStep>
            </Col>
            <Col xl={6} lg={6} sm={12} md={12}>
                <OrderStep className='service-details'>
                    <h1>{t("page-purchase.order-step-three.quality-and-peace")}</h1>
                    <h3>{t("page-purchase.order-step-three.value-safety")}</h3>
                    <div className="service-details-container">
                        <div className="service-details-wrapper">
                            <div className="product-name-price-container">
                                <div className="product-name-price-wrapper">
                                    <div className="flex-wrapper">
                                        <p>{t("page-purchase.order-step-three.service")}</p>
                                        <h1>{orderDetails?.productName}</h1>
                                    </div>
                                    <div className="flex-wrapper">
                                        <p>{t("page-purchase.order-step-three.price")}</p>
                                        <h1>{orderDetails?.discountCode?.promo_price ? (orderDetails?.discountCode?.promo_price && orderDetails?.currency && convertToTargetCurrency(orderDetails?.discountCode?.promo_price, orderDetails?.currency)) : (orderDetails?.productPrice && orderDetails?.currency && convertToTargetCurrency(orderDetails?.productPrice, orderDetails?.currency))}<span>{orderDetails?.currencyDisplay}</span></h1>
                                    </div>
                                    <div className="flex-wrapper">
                                        <p>{t("page-purchase.order-step-three.mode")}</p>
                                        <h1>{t("page-purchase.order-step-three.remote")}</h1>
                                    </div>
                                    <div className="flex-wrapper">
                                        <p>{t("page-purchase.order-step-three.care")}</p>
                                        <h1>{orderDetails?.supportDays}<span>{t("page-purchase.order-step-three.days")}</span></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="sponsor-slider-wrapper">
                                <p>{t("page-purchase.order-step-three.partners")}</p>
                                <SponsorSlider />
                            </div>
                        </div>
                    </div>
                </OrderStep>
            </Col>
        </>
    )
}

export default OrderStepThree
import React, { useContext, createContext } from 'react'
import supabase from '../data/supabase';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment-timezone';
import { useDatabasePageContext } from './DatabasePageContext';

const NewOrderContext = createContext()

export function useNewOrderContext() {
    return useContext(NewOrderContext)
}

export const NewOrderContextProvider = ({ children }) => {

    const { timezone } = useDatabasePageContext()
    async function isDateAvailable(date, time) {

        try {
            let date_moment = moment.tz(`${date} ${time}`, timezone)
            date = date_moment.tz('Europe/Warsaw').format('YYYY-MM-DD')
            time = date_moment.tz('Europe/Warsaw').format('HH:mm')

            const { data, error } = await supabase
                .from('available_dates')
                .select('available_hours')
                .eq('date', date)
                .single()

            if (error) throw error;

            if (data != null) {
                if ((data?.available_hours)?.includes(`${time}`)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }

        } catch (error) {
            return false;
        }
    }

    const value = {
        isDateAvailable
    }


    return (
        <NewOrderContext.Provider value={value}>
            {children}
        </NewOrderContext.Provider>
    )


}

export default NewOrderContext






import React from 'react'
import './AdminBackButton.css'
import Button from '../../../../Button/Button'

const AdminBackButton = ({ setCurrentView }) => {

    function handleBack() {
        setCurrentView()
    }

    return (
        <Button className="try small" onClick={handleBack}>Powrót</Button>
    )
}

export default AdminBackButton
import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Text from '../../../../components/Text/Text'
import './SectionTryNow.css'
import Button from '../../../../components/Button/Button'
import WKDZIK from "../../../../assets/img/partners-logos/wkdzik-vertical.svg"
import ProBootcamps from "../../../../assets/img/partners-logos/ProBootcamps-horizontal.webp"
import { Trans, useTranslation } from 'react-i18next'

const SectionTryNow = () => {

    const { t } = useTranslation('translation');

    return (
        <>
            <section id="section-try-now" className="section-try-now">
                <Container>
                    <Row>
                        <Col xl={2} lg={1} md={1} sm={0}></Col>
                        <Col xl={8} lg={10} md={10} sm={12} className='col center'>
                            <Text
                                className="center highlight"
                                h3={<Trans i18nKey="page-homepage.section-try-now.header">
                                    POSTAW NA <span style={{ color: "rgb(0, 224, 255)" }}>WYDAJNOŚĆ.</span>
                                </Trans>}
                            />
                            <div className="try-now-buttons-wrapper">
                                <Button href="/uslugi" className='try black'>{t("services").toUpperCase()}</Button>
                                <Button href="/zakup" className='try'>{t("purchase-buttons.buy-now")}</Button>
                                <Button href="/kontakt" className='try black'>{t("contact").toUpperCase()}</Button>
                            </div>
                        </Col>
                        <Col xl={2} lg={1} md={1} sm={0}></Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} className='center'>
                            <div className="partners-text-wrapper">
                                <h4>{t("partners").toUpperCase()}:</h4>
                            </div>
                            <div className='partners-logos-container'>
                                <div className="logo-wrapper">
                                    <a href='https://wkdzik.pl/'>
                                        <Image
                                            src={WKDZIK}
                                            fluid />
                                    </a>
                                </div>
                                <div className="logo-wrapper big">
                                    <a href='https://probootcamps.com/'>
                                        <Image
                                            src={ProBootcamps}
                                            fluid />
                                    </a>
                                </div>


                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>


        </>
    )

}

export default SectionTryNow;
import React, { useState, useRef } from "react";
import { Image } from "react-bootstrap";
import "./HidingImage.css"
import { CSSTransition } from "react-transition-group";

const HidingImage = ({ src, text }) => {

    const [textVisible, setTextVisible] = useState(false);
    const textWrapperRef = useRef();

    function toggleText() {
        setTextVisible(!textVisible)
    }

    return (
        <>
            <div className="component-hiding-image"
                onMouseEnter={toggleText}
                onMouseLeave={toggleText} >
                <div className="component-hiding-image-inner">
                    <div className="image-wrapper"
                        style={{

                            filter: textVisible ? 'grayscale(1) blur(5px)' : 'grayscale(0) blur(0)',
                            opacity: textVisible ? '0.4' : '1',
                            transition: '0.8s'

                        }}>
                        <Image
                            src={src}
                            alt="hiding image"
                        />
                    </div>
                    <CSSTransition
                        //1. state (enter: in={true} / exit: in={false})
                        in={textVisible}
                        //2. duration in ms
                        timeout={4000}
                        //3. class name prefix
                        classNames="myClass">
                        <div className="text-wrapper" ref={textWrapperRef}
                            style={{
                                visibility: textVisible ? 'visible' : 'hidden',
                            }}
                        >
                            <p>{text}</p>
                        </div>
                    </CSSTransition>
                </div>
            </div>
        </>
    )
}

export default HidingImage;